var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [_vm._v("Umsätze")]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-3" },
                  [
                    _c("year-drop-down", {
                      attrs: { "drop-down-label": "Jahr" },
                      on: { "dd-year-changed": _vm.yearChanged }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.loaded
                    ? _c("bar-chart", {
                        staticStyle: { "max-height": "300px" },
                        attrs: {
                          chartData: _vm.chartData,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "60px 0" } }),
              _vm._v(" "),
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("Stylistin")]),
                      _vm._v(" "),
                      _vm._l(_vm.chartData.labels, function(month) {
                        return _c("td", [_vm._v(_vm._s(month))])
                      }),
                      _vm._v(" "),
                      _c("td", { staticClass: "font-weight-bold" }, [
                        _vm._v("Summe")
                      ])
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.salesData, function(item, index) {
                      return [
                        _c(
                          "tr",
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.getStylistName(index)))
                            ]),
                            _vm._v(" "),
                            _vm._l(item.data, function(count, countIndex) {
                              return _c(
                                "td",
                                {
                                  class:
                                    countIndex === "13"
                                      ? "font-weight-bold"
                                      : ""
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("toCurrency")(count.sales))
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer" })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }