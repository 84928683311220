<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <div class="card">
                        <div class="card-header">Provision</div>
                        <div class="card-body">

                            <div class="form-row">
                                <div v-if="is('admin')" class="col-12 col-md-3">
                                    <div class="form-group">
                                        <label>Benutzer</label>
                                        <select class="form-control" v-model="sw_user_id">
                                            <option v-for="item in users" :value="item.sw_user_id">{{ item.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-md-3">
                                    <year-drop-down :drop-down-label="'Jahr'" @dd-year-changed="yearChanged"></year-drop-down>
                                </div>

                            </div>

                            <div>
                                <bar-chart v-if="loaded" :chartData="chartData" :options="chartOptions" style="max-height: 300px"></bar-chart>
                            </div>

                            <div style="margin: 60px 0"></div>

                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Monat</th>
                                    <th>Party: Umsatz</th>
                                    <th>Party: Provision</th>
                                    <th>Online: Umsatz</th>
                                    <th>Online: Provision</th>
                                    <th>CRM: Umsatz</th>
                                    <th>CRM: Provision</th>
                                    <th>Gesamtumsatz</th>
                                    <th>Provision</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in commission">
                                    <td>{{ item.month }}</td>
                                    <td>{{ item.party_orders_sum_net|toCurrency }}</td>
                                    <td>{{ item.parties_commission|toCurrency }}</td>
                                    <td>{{ item.online_orders_sale|toCurrency }}</td>
                                    <td>{{ item.online_commission|toCurrency }}</td>
                                    <td>{{ item.crm_orders_sale|toCurrency }}</td>
                                    <td>{{ item.crm_commission|toCurrency }}</td>
                                    <td>{{ item.sales_sum|toCurrency }}</td>
                                    <td>{{ item.commission_sum|toCurrency }}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer"></div>
                    </div>

                </div>
            </div>
        </div>


    </section>
</template>

<script>
import BarCharts from "./Charts/BarCharts";

export default {
    // name : 'StatisticsCommission',
    props: {
        users                 : {
            type   : Array,
            default: []
        },
        currentBackendUserSwId: {
            type   : Number,
            default: 5

        }
    },
    data()
    {
        return {
            sw_user_id  : this.currentBackendUserSwId,
            loaded      : false,
            chartHeader : {},
            commission  : {},
            dataArray   : [],
            chartData   : {
                labels  : [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember'
                ],
                datasets: [
                    {
                        label          : 'Provision',
                        backgroundColor: '#f87979',
                        data           : this.dataArray
                    },
                ]
            },
            chartOptions: {
                responsive         : true,
                maintainAspectRatio: false,
                scales             : {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                },
                animation          : {
                    duration: 0
                }
            },
            selectedYear: null
        }
    },
    mounted()
    {
        this.getData()
    },
    methods: {
        getData(stylistId = null)
        {
            axios
                .post('statistics/getStatisticsAxios', {
                    stylistId: stylistId !== null ? stylistId : null,
                    year     : this.selectedYear
                })
                .then((response) => {
                    this.commission = response.data.commission.data;

                    let dataArray = []
                    for (let item in this.commission) {
                        dataArray.push(this.commission[item]['commission_sum'].toFixed(2))
                    }
                    this.chartData.datasets[0].data = dataArray
                    this.chartData                  = Object.assign({}, this.chartData)
                    this.loaded                     = true
                })
                .catch((error) => {
                    console.log('error' + JSON.stringify(error, null, 2))
                })
        },
        yearChanged(year)
        {
            this.selectedYear = year
        },
    },
    watch  : {
        'sw_user_id': function (nv, ov) {
            this.getData(nv)
        },
        'selectedYear': function (nv,ov) {
            this.getData(this.sw_user_id)
        }
    }

}
</script>
<style scoped lang="sass">
canvas
    max-height: 400px
</style>