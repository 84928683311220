<template>
    <div class="projects">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="searchField">Suchen</label>
                    <input class="form-control" type="text" v-model="tableData.search" placeholder="" @input="debounceSearch" id="searchField">
                    <small id="searchFieldHelp" class="form-text text-muted">&nbsp;</small>
                </div>
            </div>

            <div class="col-6 text-right">
                <div class="form-group">
                    <label class="text-right" for="searchField">{{ __('datatable.items_per_page') }}</label>
                    <div class="items_per_page ">
                        <select class="form-control" v-model="tableData.length" @change="getProjects()">
                            <option v-for="(records, index) in perPage" :key="index" :value="records">{{ records }}</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>

        <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" table-hover="true" @sort="sortBy">
            <tbody>
            <tr v-for="row in rows" :key="row.id">
                <td>
                    <input type="checkbox" :value="row.id" v-model="checked">
                </td>
                <td @click="viewButton(row.id)">{{ row.party_name }}</td>
                <td @click="viewButton(row.id)">{{ row.party_date_time|formatDateTime }}</td>
                <td @click="viewButton(row.id)">{{ row.host_firstname }} {{ row.host_lastname }}</td>
                <td @click="viewButton(row.id)">{{ row.stylist_firstname }}</td>
                <td @click="viewButton(row.id)">{{ (row.host_commission - row.host_commission_temp + row.host_buy_sum + row.host_party_sale)|toCurrency }}</td>
                <td @click="viewButton(row.id)">{{ row.host_commission_temp|toCurrency }}</td>
                <td @click="viewButton(row.id)">{{ row.host_commission|toCurrency }}</td>
<!--                <td @click="viewButton(row.id)">n/a</td>-->
<!--                <td @click="viewButton(row.id)">{{ row.stylist_commission|toCurrency }}</td>-->
                <td>
                    <a class="" :href="'/backend/party/edit/' + row.id"><i class="fas fa-pencil-alt"></i></a>
                </td>
            </tr>
            </tbody>
        </datatable>

        <laravel-vue-pagination :data="pagi" @pagination-change-page="getProjects"></laravel-vue-pagination>

    </div>
</template>

<script>
import Datatable  from "../module/Datatable";
import Pagination from "../module/Pagination";

export default {
    name      : 'customer-list',
    components: {
        datatable : Datatable,
        pagination: Pagination
    },
    props     : {
        columns: {
            require: true,
        },
    },
    created()
    {
        this.columns.forEach((column) => {
            this.sortOrders[column.name] = -1;
        });

        this.getProjects();
    },
    data()
    {
        let sortOrders = {};
        return {
            rows      : [],
            sortKey   : 'deadline',
            sortOrders: sortOrders,
            perPage   : [
                '10',
                '20',
                '30'
            ],
            tableData : {
                draw  : 0,
                length: 10,
                search: '',
                column: 0,
                dir   : 'desc',
                page  : 1,
                filter: {
                    price: {}
                }
            },
            pagination: {
                lastPage   : '',
                currentPage: '',
                total      : '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from       : '',
                to         : ''
            },
            pagi      : {},
            checked   : [],
        };
    },
    methods   : {

        getProjects(page = 1)
        {
            let url             = '/getParties';
            this.tableData.page = page;
            this.tableData.draw++;
            axios.get(url, {params: this.tableData})
                .then(response => {
                    let data = response.data;
                    if (this.tableData.draw == data.draw) {
                        this.rows = data.data.data;
                        this.configPagination(data.data);
                        this.pagi = response.data.data;
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
        configPagination(data)
        {
            this.pagination.lastPage    = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total       = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from        = data.from;
            this.pagination.to          = data.to;
        },
        sortBy(key)
        {
            this.sortKey          = key;
            this.sortOrders[key]  = this.sortOrders[key] * -1;
            this.tableData.column = this.getIndex(this.columns, 'name', key);
            this.tableData.dir    = this.sortOrders[key] === 1 ? 'asc' : 'desc';
            this.getProjects();
        },
        getIndex(array, key, value)
        {
            return array.findIndex(i => i[key] == value);
        },
        buttonTest(item)
        {

        },
        viewButton(id)
        {
            window.location.href = '/backend/party/view/' + id;
        },
        debounceSearch: _.debounce(function () {
            this.getProjects();
        }, 500)
    }

};
</script>
