<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!--                    <h1>test: {{bla(5)}}</h1>-->

                    <div class="card">
                        <div class="card-header">Neukunden</div>
                        <div class="card-body">

                            <div class="form-row">
                                <div class="col-12 col-md-3">
                                    <year-drop-down :drop-down-label="'Jahr'" @dd-year-changed="yearChanged"></year-drop-down>
                                </div>
                            </div>

                            <div>
                                <bar-chart v-if="loaded" :chartData="chartData" :options="chartOptions" style="max-height: 300px"></bar-chart>
                            </div>

                            <div style="margin: 60px 0"></div>

                            <table class="table">
                                <thead>
                                <tr>
                                    <td>Stylistin</td>
                                    <td v-for="month in chartData.labels">{{ month }}</td>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item, index) in customerData">
                                    <tr>
                                        <td>{{ getStylistName(index) }}</td>
                                        <td v-for="count in item.data">{{ count.new_users }}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer"></div>
                    </div>

                </div>
            </div>
        </div>


    </section>
</template>

<script>
import BarCharts from "./Charts/BarCharts";

export default {
    props: {
        users                 : {
            type   : Array,
            default: []
        },
        currentBackendUserSwId: {
            type   : Number,
            default: 5
        },
        // customerData          : {}
    },
    data()
    {
        return {
            customerData: {},
            sw_user_id  : this.currentBackendUserSwId,
            loaded      : false,
            chartHeader : {},
            commission  : {},
            dataArray   : [],
            chartData   : {
                labels  : [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember'
                ],
                datasets: [
                    {
                        label          : 'Neukunden',
                        backgroundColor: '#ff0000',
                        data           : this.dataArray
                    },
                ]
            },
            chartOptions: {
                responsive         : true,
                maintainAspectRatio: false,
                scales             : {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                },
                animation          : {
                    duration: 0
                }
            },
            selectedYear: null
        }
    },
    mounted()
    {
        this.getData()
        // let tempArray   = []
        // let currentUser = this.customerData[this.currentBackendUserSwId].data
        // for (let item in currentUser) {
        //     tempArray.push(currentUser[item].new_users)
        // }
        // this.chartData.datasets[0].data = tempArray
        // this.chartData                  = Object.assign({}, this.chartData)
        // this.loaded                     = true

    },
    methods: {
        getData()
        {
            let _self = this
            axios
                .post('/backend/statistics/getStatisticsNewCustomersAxios', {
                    year: this.selectedYear
                })
                .then((response) => {
                    console.log('response')
                    _self.customerData = response.data.customerData
                    let tempArray   = []
                    let currentUser = this.customerData[this.currentBackendUserSwId].data
                    for (let item in currentUser) {
                        tempArray.push(currentUser[item].new_users)
                    }
                    this.chartData.datasets[0].data = tempArray
                    this.chartData                  = Object.assign({}, this.chartData)
                    this.loaded                     = true
                })
                .catch((error) => {
                    console.log('error')
                })
        },
        getStylistName(id)
        {
            let user = this.users.filter(function (stylist) {
                return stylist.sw_user_id == id
            });
            return user[0].name
        },
        yearChanged(year)
        {
            this.selectedYear = year
        },
    },
    watch  : {
        'selectedYear': function (nv, ov) {
            this.getData()

        }
    }

}
</script>
<style scoped lang="sass">
canvas
    max-height: 400px
</style>