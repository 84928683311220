<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <div class="card">
                        <div class="card-header">Provisionsübersicht: {{ users.find((user) => user.id === userId) ? users.find((user) => user.id === userId).name : "" }} ({{ selectedYear }})</div>
                        <div class="card-body">
                            <template v-if="!loading">
                                <div class="form-row">
                                    <div v-if="is('admin')" class="col-12 col-md-3">
                                        <div class="form-group">
                                            <label>Benutzer</label>
                                            <select class="form-control" v-model="userId">
                                                <option v-for="item in users" :value="item.id">{{ item.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-3">
                                        <year-drop-down :drop-down-label="'Jahr'" @dd-year-changed="yearChanged" :year="selectedYear"></year-drop-down>
                                    </div>

                                </div>

                                <div>
                                    <bar-chart v-if="loaded" :chartData="chartData" :options="chartOptions" style="max-height: 300px"></bar-chart>
                                </div>

                                <div style="margin: 60px 0"></div>

                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Monat</th>
                                        <th>Party</th>
                                        <th>CRM</th>
                                        <th>Online</th>
                                        <th>Nachtrag</th>
                                        <th>Silber/Gold</th>
                                        <th>Umsatz Summe</th>
                                        <th>Team Umsatz</th>
                                        <th>Provisionssätze %</th>
                                        <th>Provision</th>
                                        <th>Ausgezahlt</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in commission">
                                        <td>{{ chartData.labels[new Date(item.commission_period).getMonth()] }}</td>
                                        <td>{{ item.party_turnover|toCurrency }}</td>
                                        <td>{{ item.crm_turnover|toCurrency }}</td>
                                        <td>{{ item.online_turnover|toCurrency }}</td>
                                        <td>{{ (item.revision_turnover_gold + item.revision_turnover_silver) |toCurrency }}</td>
                                        <td>{{ item.turnover_sum_silver|toCurrency }} / {{ item.turnover_sum_gold|toCurrency }}</td>
                                        <td><b>{{ item.turnover_sum|toCurrency }}</b></td>
                                        <td>{{ item.team_turnover|toCurrency }}</td>
                                        <td>{{ item.commission_value_silver }}% / {{ item.commission_value_gold }}% / {{ item.commission_value_team }}%</td>
                                        <td><b>{{ item.commission_sum|toCurrency }}</b></td>
                                        <td>{{ item.transferred_date ? item.transferred_date : "–" }}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </template>
                            <template v-if="loading">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <b>Daten werden geladen...</b>
                                    </div>
                                </div>
                            </template>

                        </div>
                        <div class="card-footer"></div>
                    </div>

                </div>
            </div>
        </div>


    </section>
</template>

<script>
import BarCharts from "./Charts/BarCharts";

export default {
    // name : 'StatisticsCommission',
    props: {
        users                 : {
            type   : Array,
            default: []
        },
        currentBackendUserSwId: {
            type   : Number,
            default: 5
        },
        pUserId: {
            type   : Number,
            default: 5
        }
    },
    data()
    {
        return {
            sw_user_id  : this.currentBackendUserSwId,
            userId  : this.pUserId,
            loaded      : false,
            loading     : true,
            chartHeader : {},
            commission  : [],
            dataArray   : [],
            chartData   : {
                labels  : [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember'
                ],
                datasets: [
                    {
                        label          : 'Provision',
                        backgroundColor: '#f87979',
                        data           : this.dataArray
                    },
                ]
            },
            chartOptions: {
                responsive         : true,
                maintainAspectRatio: false,
                scales             : {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                },
                animation          : {
                    duration: 0
                }
            },
            selectedYear: new Date().getFullYear(),
        }
    },
    mounted()
    {
        const now = new Date().getFullYear();
        this.getData(this.userId, now)
    },
    methods: {
        getData(id, year) {
            this.loading = true;
            axios
                .get(`sales/${id}/data/${year}`)
                .then((response) => {
                    this.commission = response.data;

                    let dataArray = []
                    for (const [key, value] of Object.entries(this.commission)) {
                        dataArray.push(value.commission_sum.toFixed(2))
                    }
                    this.chartData.datasets[0].data = dataArray
                    this.chartData                  = Object.assign({}, this.chartData)
                    this.loaded                     = true
                })
                .catch((error) => {
                    console.log('error' + JSON.stringify(error, null, 2))
                }).finally(() => {
                    this.loading = false
                })
        },
        yearChanged(year)
        {
            this.selectedYear = year
        },
    },
    watch  : {
        'userId': function (nv, ov) {
            this.getData(this.userId, this.selectedYear)
        },
        'selectedYear': function (nv,ov) {
            this.getData(this.userId, this.selectedYear)
        }
    }

}
</script>
<style scoped lang="sass">
canvas
    max-height: 400px
</style>
