<template>
    <section id="party-order-details">
        <div class="accordion" id="accordionExample">
            <div class="card" v-for="(partyOrderItem, index) in partyOrderDetails">
                <div class="card-header" style="cursor: pointer">
                    <div :id="'heading' + index" data-toggle="collapse" :data-target="'#collapseOne' + index" aria-expanded="true" :aria-controls="'collapseOne' + index">
                        <table class="w-100 order-details-table">
                            <tr>
                                <th>{{ __('order.ordernumber') }}</th>
                                <th>{{ __('order.ordertime') }}</th>
                                <th>{{ __('order.invoice_amount') }}</th>
                                <th>{{ __('order.name') }}</th>
                                <th>{{ __('order.status') }}</th>
<!--                                <th>{{ __('order.stylist_commission') }}</th>-->
                            </tr>
                            <tr>
                                <td>{{ partyOrderItem.ordernumber }}</td>
                                <td>{{ partyOrderItem.ordertime|formatDateTime }}</td>
                                <td>{{ partyOrderItem.invoice_amount|toCurrency }}</td>
                                <td>{{ partyOrderItem.s_user.firstname }} {{ partyOrderItem.s_user.lastname }}</td>
                                <td>{{ getState(partyOrderItem.status) }}</td>
<!--                                <td>{{ partyOrderItem.s_order_attributes.stylist_commission|toCurrency }}</td>-->
                            </tr>
                        </table>
                    </div>
                </div>

                <div :id="'collapseOne' + index" class="collapse" :aria-labelledby="'heading' + index" data-parent="#accordionExample">
                    <div class="card-body">
                        <table class="table order-sub-details-table">
                            <tr>
                                <th>{{ __('order.articleordernumber') }}</th>
                                <th>{{ __('order.name') }}</th>
                                <th>{{ __('order.price') }}</th>
                                <th>{{ __('order.quantity') }}</th>
                                <th>{{ __('order.total') }}</th>
                            </tr>
                            <tr v-for="itemDetail in partyOrderItem.s_order_details">
                                <td>{{ itemDetail.articleordernumber }}</td>
                                <td>{{ itemDetail.name }}</td>
                                <td>{{ itemDetail.price|toCurrency }}</td>
                                <td>{{ itemDetail.quantity }}</td>
                                <td>{{ itemDetail.price * itemDetail.quantity|toCurrency }}</td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name   : "PartyOrderDetails",
    props  : {
        partyOrderDetails: {},
        state            : {},
        showOrderButton  : {
            default: false
        },
    },
    data()
    {
        return {
            commission: {
                stylist: 0,
                host   : 0,
            }
        };

    },
    mounted()
    {
    },
    methods: {
        getState(state)
        {
            let status = this.state.filter(s => s.id == state);
            return status[0].description;
        }
    }
};
</script>

<style lang="scss" scoped>
#party-order-details {

    .card-header {
        padding-top: 5px;
        background-color: #fdfdfd;
    }

    .order-details-table {
        tr {
            th:nth-child(0) {
                width: 10%
            }

            th:nth-child(1) {
                width: 20%
            }

            th:nth-child(2) {
                width: 15%
            }

            th:nth-child(3) {
                width: 20%
            }

            th:nth-child(4) {
                width: 20%
            }
        }
    }

}
</style>
