<template>

    <div class="ProductsList">
        <pre v-text="$attrs"/>
        <h1>productList</h1>

        <div class="row">
            <div class="col-4">
                <input class="form-control" type="text" name="search" id="search" v-model.lazy.trim="searchField">
            </div>
        </div>

        <table class="table">

            <thead>
            <tr>
                <th></th>
                <th>SKU</th>
                <th>name</th>
                <th>Short description</th>
                <th>Action</th>
            </tr>
            </thead>

            <tbody>

            <tr>
                <td>
                </td>
                <td>
                    <input class="form-control" type="text" name="filter_sku" v-model="filter.sku">
                </td>
                <td></td>
                <td></td>
                <td></td>

            </tr>

            <tr v-for="product in rows.data">
                <td>
                    <input type="checkbox" :value="product.id" v-model="checked">
                </td>
                <td>{{product.sku}}</td>
                <td>{{product.name}}</td>
                <td>{{product.price}}</td>
                <td>
                    <button class="btn btn-dark" @click="buttonTest(product.id)">test</button>
                </td>
            </tr>
            </tbody>
        </table>

        <pagination :data="rows" @pagination-change-page="getResults"></pagination>

    </div>
</template>

<script>
    export default {
        props: {},
        data: function () {
            return {
                test: false,
                columns: [],
                rows: {},
                checked: [],
                filter: {},
                searchField: ''
            }
        },
        mounted() {
        },
        methods: {
            getData() {
                axios
                    .get('/getProducts')
                    .then(response => {
                        this.rows = response.data
                    })
            },
            buttonTest(item) {
            },
            addChecked(id) {

                this.checked.push(id)
            },
            // Our method to GET results from a Laravel endpoint
            getResults(page = 1) {
                // axios.get('/getProducts?page=' + page + '&test=test', {params: this.filter})
                axios.get('/getProducts?page=' + page + '&test=test', {params: {filter: this.filter}})
                    .then(response => {
                        this.rows = response.data;
                    });
            }
        },
        watch: {
            searchField: function (val) {
                this.getResults();
            }
        },
        created() {
            this.getData();
        },

    };
</script>
