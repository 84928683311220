var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "create-user" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(
                "\n                             \n                        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-header-text" }, [
                _vm._v("Step 1")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12 col-md-12" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.__("settings.users.create.search_host.label")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchHostValue,
                          expression: "searchHostValue"
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.validateErrors.sw_user_id },
                      attrs: { type: "text" },
                      domProps: { value: _vm.searchHostValue },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchHostValue = $event.target.value
                          },
                          _vm.debounceSearch
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.validateErrors && _vm.validateErrors.sw_user_id
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(_vm._s(_vm.validateErrors.sw_user_id[0]))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("search-table", {
                      attrs: {
                        headerFields: _vm.headerFields,
                        columnData: _vm.columnData,
                        visible: _vm.showResult
                      },
                      on: { "tr-select": _vm.selectedUser }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row mb-3" }, [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _vm.user.sw_user_id > 0
                    ? _c("span", [
                        _c("i", {
                          staticClass: "fas fa-check",
                          staticStyle: { color: "#00ff00" }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.__("settings.users.create.shopware"))
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.sw_user_id == 0
                    ? _c("span", [
                        _c("i", {
                          staticClass: "fas fa-times",
                          staticStyle: { color: "#ff0000" }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.__("settings.users.create.shopware"))
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.__("settings.users.create.name")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.name,
                        expression: "user.name"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.validateErrors.name },
                    attrs: { type: "text" },
                    domProps: { value: _vm.user.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.validateErrors && _vm.validateErrors.name
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.validateErrors.name[0]))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.__("settings.users.create.email")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.validateErrors.email },
                    attrs: { type: "text" },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.validateErrors && _vm.validateErrors.email
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.validateErrors.email[0]))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.__("settings.users.create.password")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.password,
                          expression: "user.password"
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.validateErrors.password },
                      attrs: { type: "password", id: "password" },
                      domProps: { value: _vm.user.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.user, "password", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _vm.showPassword
                        ? _c("span", { staticClass: "input-group-text" }, [
                            _c("i", {
                              staticClass: "far fa-eye",
                              on: { click: _vm.togglePass }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showPassword
                        ? _c("span", { staticClass: "input-group-text" }, [
                            _c("i", {
                              staticClass: "far fa-eye-slash",
                              on: { click: _vm.togglePass }
                            })
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.validateErrors && _vm.validateErrors.password
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.validateErrors.password[0]))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                  _c("label", [_vm._v("Roles")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.roles,
                          expression: "user.roles"
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.validateErrors.roles },
                      attrs: { multiple: "" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.user,
                            "roles",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.roles, function(role) {
                      return _c("option", { domProps: { value: role.id } }, [
                        _vm._v(_vm._s(role.name))
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.validateErrors && _vm.validateErrors.roles
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.validateErrors.roles[0]))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-header-text" }, [
                _vm._v("Shopware URL")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.__("settings.users.create.stylist_url.label"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.stylist_url,
                        expression: "user.stylist_url"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.validateErrors.stylist_url },
                    attrs: { type: "text" },
                    domProps: { value: _vm.user.stylist_url },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "stylist_url", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.validateErrors && _vm.validateErrors.stylist_url
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.validateErrors.stylist_url[0]))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _vm._v("style-boss.de/" + _vm._s(_vm.user.stylist_url))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.__("settings.users.create.stylist_name.label"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.stylist_name,
                        expression: "user.stylist_name"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.validateErrors.stylist_name },
                    attrs: { type: "text" },
                    domProps: { value: _vm.user.stylist_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "stylist_name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.validateErrors && _vm.validateErrors.stylist_name
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.validateErrors.stylist_name[0]))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12 col-lg-3" }, [
                  _c("label", [_vm._v("Prozente: Kauf")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.percent_buy,
                        expression: "user.percent_buy"
                      }
                    ],
                    staticClass: "form-control",
                    class: { is_invalid: _vm.validateErrors.percent_buy },
                    attrs: { type: "number", max: "100", min: "0" },
                    domProps: { value: _vm.user.percent_buy },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "percent_buy", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-header-text" }, [
                _vm._v("BISHER ERREICHTER MONATSUMSATZ")
              ]),
              _vm._v(" "),
              _c("label", [_vm._v("Dashboard: Balken 1 und 2")]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12 col-lg-3" }, [
                  _c("label", [_vm._v("Monatsziel 1")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.objectives_month_1,
                        expression: "user.objectives_month_1"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      is_invalid: _vm.validateErrors.objectives_month_1
                    },
                    attrs: { type: "number", max: "100", min: "0" },
                    domProps: { value: _vm.user.objectives_month_1 },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.user,
                          "objectives_month_1",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-12 col-lg-3" }, [
                  _c("label", [_vm._v("Monatsziel 2")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.objectives_month_2,
                        expression: "user.objectives_month_2"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      is_invalid: _vm.validateErrors.objectives_month_2
                    },
                    attrs: { type: "number", max: "100", min: "0" },
                    domProps: { value: _vm.user.objectives_month_2 },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.user,
                          "objectives_month_2",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-header-text" }, [
                _vm._v("BISHER ERREICHTES QUARTALSZIEL")
              ]),
              _vm._v(" "),
              _c("label", [
                _vm._v("Dashboard: Quartalsziel / 3 (Balken 1, 2, 3)")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12 col-lg-3" }, [
                  _c("label", [_vm._v("Quartalsziel")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.objectives_quarter,
                        expression: "user.objectives_quarter"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      is_invalid: _vm.validateErrors.objectives_quarter
                    },
                    attrs: { type: "number", max: "100", min: "0" },
                    domProps: { value: _vm.user.objectives_quarter },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.user,
                          "objectives_quarter",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "w-100" }),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("avatar-upload", {
                      attrs: { avatar: _vm.user.avatar },
                      on: { "image-file-selected": _vm.imageSelected }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer text-right" }, [
              _vm._v("\n                             "),
              _c(
                "button",
                { staticClass: "btn btn-light", on: { click: _vm.saveUser } },
                [_vm._v(_vm._s(_vm.__("settings.users.create.buttons.save")))]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }