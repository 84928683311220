<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">

                </div>
            </div>
        </div>
        <statistics-commission :commission="commission" :chartheader="monthHeader" :data-set-commission="myArray"></statistics-commission>
        <!--        <statistics-commission/>-->
    </section>
</template>

<script>

import StatisticsCommission from "./StatisticsCommission";

export default {
    components: {
        StatisticsCommission
    },
    // props  : {
    //     commission: {}
    // },
    data()
    {
        return {
            loaded     : false,
            commission : {},
            monthHeader: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember'
            ],
            myArray    : [],
        }
    },
    mounted()
    {
        this.getData()
    },
    methods   : {
        testArray(data)
        {
            this.myArray = null
            for (let xvalue in data) {
                this.myArray.push(data[xvalue]['commission_sum'].toFixed(2))
            }
        }
    },

}
</script>
