var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "show-tags" } },
    _vm._l(_vm.tags, function(tag) {
      return _c("div", { staticClass: "form-check form-check-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newCheckedTags,
              expression: "newCheckedTags"
            }
          ],
          staticClass: "form-check-input",
          attrs: { type: "checkbox", id: "tagBox" + tag.id },
          domProps: {
            value: tag.name.de,
            checked: Array.isArray(_vm.newCheckedTags)
              ? _vm._i(_vm.newCheckedTags, tag.name.de) > -1
              : _vm.newCheckedTags
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.newCheckedTags,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = tag.name.de,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.newCheckedTags = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.newCheckedTags = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.newCheckedTags = $$c
                }
              },
              _vm.boxChecked
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "tagBox" + tag.id }
          },
          [_vm._v(_vm._s(tag.name.de))]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }