var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container h-100" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [_vm._v(" ")]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("youtube", {
                  ref: "youtube",
                  attrs: {
                    "video-id": _vm.videoID,
                    "player-vars": _vm.playerVars
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "input-group mb-3" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.videoID,
                        expression: "videoID"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.videoID },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.videoID = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("label", [_vm._v(_vm._s(_vm.__("news.video.youtubeID")))])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer text-right" }, [
          _c(
            "button",
            { staticClass: "btn btn-light", on: { click: _vm.saveVideoUrl } },
            [_vm._v(_vm._s(_vm.__("news.buttons.save")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "span",
        { staticClass: "input-group-text", attrs: { id: "basic-addon3" } },
        [_vm._v("https://www.youtube.com/watch?v=")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }