<template>

    <section id="event">


        <!-- ######## -->
        <div :class="showModalData ? 'show' : ''" class="modal" tabindex="-1" id="show-calendar" ref="vuemodal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ __('event.event') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <!-- ######## -->

                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">

                                    <div class="form-row">
                                        <div class="form-group col-12">
                                            <label>{{ __('event.subject') }}</label>
                                            <select class="form-control" v-model="event.event_type">
                                                <option v-for="eventType in eventTypes" :value="eventType.id">{{ eventType.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-12">
                                            <label>{{ __('event.description') }}</label>
                                            <textarea :class="{ 'is-invalid': validateErrors.description}" class="form-control" v-model="event.description"></textarea>
                                            <div v-if="validateErrors && validateErrors.description" class="invalid-feedback">{{ validateErrors.description[0] }}</div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-6">
                                            <label>{{ __('event.start_date') }}</label>
                                            <input :class="{ 'is-invalid': validateErrors.start_date}" class="form-control" type="date" v-model="event.start_date">
                                            <div v-if="validateErrors && validateErrors.start_date" class="invalid-feedback">{{ validateErrors.start_date[0] }}</div>
                                        </div>
                                        <div class="form-group col-6">
                                            <label>{{ __('event.start_time') }}</label>
                                            <input :class="{ 'is-invalid': validateErrors.start_time}" class="form-control" type="time" v-model="event.start_time">
                                            <div v-if="validateErrors && validateErrors.start_time" class="invalid-feedback">{{ validateErrors.start_time[0] }}</div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" v-model="event.recall">
                                                <label class="form-check-label">{{ __('event.recall') }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row" v-if="event.recall">
                                        <div class="form-group col-6">
                                            <label>{{ __('event.recall_date') }}</label>
                                            <input :class="{ 'is-invalid': validateErrors.recall_date}" class="form-control" type="date" v-model="event.recall_date">
                                            <div v-if="validateErrors && validateErrors.recall_date" class="invalid-feedback">{{ validateErrors.recall_date[0] }}</div>
                                        </div>
                                        <div class="form-group col-6">
                                            <label>{{ __('event.recall_time') }}</label>
                                            <input :class="{ 'is-invalid': validateErrors.recall_time}" class="form-control" type="time" v-model="event.recall_time">
                                            <div v-if="validateErrors && validateErrors.recall_time" class="invalid-feedback">{{ validateErrors.recall_time[0] }}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- ######## -->
                    </div>
                    <div class="modal-footer">
                        <!--                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
                        <button @click="closeButton" type="button" class="btn btn-light">{{ __('event.buttons.ok') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- ######## -->
        <div class="modal-backdrop show" v-if="showModalData"></div>
    </section>

</template>

<script>
export default {
    name   : "EventModal",
    props  : {
        showModal : false,
        eventTypes: {}
    },
    data() {
        return {
            showModalData : false,
            event         : {
                event_type : 1,
                description: null,
                start_date : null,
                start_time : null,
                recall     : false,
                recall_date: null,
                recall_time: null
            },
            validateErrors: {},
        };
    },
    mounted() {
        $(this.$refs.vuemodal).on("hidden.bs.modal", this.closeModal);
        this.showModalData = this.$props.showModal;

    },
    methods: {
        closeModal() {
            this.showModalData = false;
            this.$emit('event-close-button', this.showModalData)

        },
        closeButton: function () {
            let msg             = 'Darf nicht leer sein';
            this.validateErrors = {};

            if (this.$emptyCheck(this.event.description)) {
                this.validateErrors.description = [msg];
            }
            if (this.$emptyCheck(this.event.start_date)) {
                this.validateErrors.start_date = [msg];
            }
            if (this.$emptyCheck(this.event.start_time)) {
                this.validateErrors.start_time = [msg];
            }
            if (this.event.recall) {
                if (this.$emptyCheck(this.event.recall_date)) {
                    this.validateErrors.recall_date = [msg];
                }
                if (this.$emptyCheck(this.event.recall_time)) {
                    this.validateErrors.recall_time = [msg];
                }
            }

            //trigger object update
            this.validateErrors = Object.assign({}, this.validateErrors);

            if (Object.keys(this.validateErrors).length === 0) {
                this.$emit('event-modal-data', this.event);
                this.$emit('event-modal-close', this.showModalData);
                this.event         = {};
                this.showModalData = false;
            }
        }
    },

    watch: {
        showModal(val) {
            this.showModalData = val;
        }
    }
};
</script>

<style scoped>

</style>
