var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProductsList" },
    [
      _c("pre", { domProps: { textContent: _vm._s(_vm.$attrs) } }),
      _vm._v(" "),
      _c("h1", [_vm._v("productList")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.lazy.trim",
                value: _vm.searchField,
                expression: "searchField",
                modifiers: { lazy: true, trim: true }
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", name: "search", id: "search" },
            domProps: { value: _vm.searchField },
            on: {
              change: function($event) {
                _vm.searchField = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _c("tr", [
              _c("td"),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.sku,
                      expression: "filter.sku"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", name: "filter_sku" },
                  domProps: { value: _vm.filter.sku },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filter, "sku", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td")
            ]),
            _vm._v(" "),
            _vm._l(_vm.rows.data, function(product) {
              return _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checked,
                        expression: "checked"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      value: product.id,
                      checked: Array.isArray(_vm.checked)
                        ? _vm._i(_vm.checked, product.id) > -1
                        : _vm.checked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.checked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = product.id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checked = $$c
                        }
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(product.sku))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(product.name))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(product.price))]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-dark",
                      on: {
                        click: function($event) {
                          return _vm.buttonTest(product.id)
                        }
                      }
                    },
                    [_vm._v("test")]
                  )
                ])
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("pagination", {
        attrs: { data: _vm.rows },
        on: { "pagination-change-page": _vm.getResults }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("SKU")]),
        _vm._v(" "),
        _c("th", [_vm._v("name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Short description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Action")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }