<template>
    <div class="form-group">
        <label>{{ dropDownLabel }}</label>
        <select class="form-control" v-model="yearSelected">
            <option v-for="year in yearData" :value="year.name" :key="year.name">{{ year.name }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name : "YearDropDown",
    props: {
        dropDownLabel: {
            default: ''
        },
        year: {
            type: Number,
            default: new Date().getFullYear(),
        }
    },
    data()
    {
        return {
            yearData    : [],
            yearSelected: this.year,

        };
    },
    mounted()
    {
        this.getYear()
    },
    methods: {
        getYear()
        {
            let tmpArray = []
            for (let i = 0; i <= 5; i++) {
                tmpArray.push({name: new Date().getFullYear() - i})
            }
            this.yearData = tmpArray
        }
    },
    watch  : {
        yearSelected: function (newVal, oldVal) {
            this.$emit('dd-year-changed', newVal);
        }
    }
};

</script>

<style scoped>

</style>
