<template>
    <section id="show-tags">
        <div class="form-check form-check-inline" v-for="tag in tags">
            <input class="form-check-input" type="checkbox" :id="'tagBox' + tag.id" :value="tag.name.de" @change="boxChecked" v-model="newCheckedTags">
            <label class="form-check-label" :for="'tagBox' + tag.id">{{ tag.name.de }}</label>
        </div>
    </section>
</template>

<script>
export default {
    name   : "ShowTags",
    props  : {
        tags: {},
        checkedTags: {}
    },
    data() {
        return {
            newCheckedTags: []
        };
    },
    mounted() {
    },
    methods: {
        boxChecked() {
            // console.log('tag checked');
            this.$emit('tag-checked', this.newCheckedTags)
        }
    },
    watch: {
        checkedTags: function (newVal, oldVal) { // watch it
            this.newCheckedTags = this.checkedTags
        }
    }
};

</script>

<style scoped>

</style>
