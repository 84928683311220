<template>
    <section>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">Übersicht</div>
                    <div class="card-body">
                        <div class="form-row">
                            <template>
                                <div class="col-12 col-md-3">
                                    <div class="form-group">
                                        <label>Stylistin</label>
                                        <select class="form-control" v-model="filter.stylist">
                                            <option v-for="item in stylists" :value="item.id" :key="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </template>

                            <div class="col-12 col-md-3">

                                <div class="form-group">
                                    <label>Datensatz</label>
                                    <select class="form-control" v-model="filter.transferred">
                                        <option v-for="item in transferred" :value="item.id" :key="item.id">{{
                                                item.name
                                            }}
                                        </option>
                                    </select>
                                </div>

                            </div>

                            <!--                            <div class="col-12 col-md-2">-->

                            <!--                                <div class="form-group">-->
                            <!--                                    <label>Jahr</label>-->
                            <!--                                    <select class="form-control" v-model="filter.year">-->
                            <!--                                        <option v-for="year in yearData" :value="year.name" :key="year.name">{{ year.name }}</option>-->
                            <!--                                    </select>-->
                            <!--                                </div>-->

                            <!--                            </div>-->

                            <!--                            <div class="col-12 col-md-2 text-right">-->
                            <!--                                <div class="form-group">-->
                            <!--                                    <div>&nbsp;</div>-->
                            <!--                                    <button class="btn btn-light">Filtern</button>-->
                            <!--                                    <button @click="getCBData" class="btn btn-light">Filtern</button>-->
                            <!--                                </div>-->

                        </div>
                    </div>

                    <table class="table">
                        <thead>
                        <tr>
                            <th>Zeitraum</th>
                            <th>Stylistin</th>
                            <th>Provision Eigenumsatz</th>
                            <th>Team Provision</th>
                            <th>Provision Nachtrag</th>
                            <th>Provision Gesamt</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in filteredData" :key="item.id">
                            <td>{{ item.commission_period|formatDate }}</td>
                            <td>{{ item.stylist.name }}</td>
                            <td>{{
                                    (item.commission_sum - item.revision_commission - item.team_commission) | toCurrency
                                }}
                            </td>
                            <td>{{ item.team_commission|toCurrency }}</td>
                            <td>{{ item.revision_commission|toCurrency }}</td>
                            <td><b>{{ item.commission_sum|toCurrency }}</b></td>
                            <td>
                                <template v-if="item.transferred_date === null">
                                    <button :disabled="!isPeriodFinished(item.commission_period)"
                                            class="btn btn-sm btn-light" @click="onPayout(item.id)">Verbuchen
                                    </button>
                                </template>
                                <template v-else>
                                    Ausbezahlt am:<br>{{ item.transferred_date|formatDate }}
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <template v-if="filteredData.length === 0">
                        <div class="row">
                            <div class="col-12">
                                <p class="text-center">Keine Ergebnisse. Bitte Filter ändern, um Daten anzuzeigen.</p>
                            </div>
                        </div>
                    </template>

                </div>
                <div class="card-footer"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'newcommission-list',
    props: {
        data: {},
        // stylists: {
        //     default() {
        //         return [{id: -1, name: "Alle"}];
        //     }
        // }
    },
    data() {
        return {
            showDropDown: true,
            filter: {
                stylist: -1,
                transferred: -1
            },
            transferred: [
                {
                    id: -1,
                    name: 'Alle'
                },
                {
                    id: 1,
                    name: 'Überwiesen'
                },
                {
                    id: 2,
                    name: 'Offen'
                },
            ],
        }
    },
    mounted() {
        console.log(this.filteredData)
        console.log(this.stylists)
    },
    methods: {
        isPeriodFinished(commission_period) {
            const rowDate = new Date(commission_period);
            return true;
            return new Date(rowDate.getFullYear(), rowDate.getMonth() + 1, 0, 23, 59, 59) < new Date(Date.now());
        },
        onPayout(id) {
            let _self = this
            this.$vToastify.setSettings({
                backdrop: 'rgba(0, 0, 0, 0.2)',
                withBackdrop: true,
                position: 'center-center',
            })
            this.$vToastify.prompt({
                body: "Provision auszahlen",
                mode: "prompt",
                answers: {
                    Ja: true,
                    Nein: false
                }
            }).then(answer => {
                if (!answer) return;
                axios.put(`/backend/payout/${id}/transfer`)
                    .then((response) => {
                        console.log(response)
                        window.location.reload(true);
                    })
                    .catch(error => {
                        console.log('error')
                        console.log(error.response)
                        _self.$vToastify.error({
                            title: 'Auszahlung',
                            body: `${ error.response.data.error }`
                        });
                    })
            })
        },
    },
    computed: {
        filteredData() {
            const stylist = this.filter.stylist;
            const transferred = this.filter.transferred;
            return this.data.filter(el => {
                let showItem = true;
                if (transferred === 2 && el.transferred_date !== null) showItem = false;
                if (transferred === 1 && el.transferred_date === null) showItem = false;
                if (stylist >= 0 && el.stylist_id !== stylist) showItem = false;
                return showItem;
            });
        },
        stylists() {
            let ids = []
            let stylists = this.data.map(el => {
                return el.stylist;
            }).filter(el => {
                const res = ids.includes(el.id)
                if (res) {
                    return false;
                }
                ids.push(el.id);
                return true;
            });
            stylists.push({id: -1, name: "Alle"})
            return stylists;
        },
    }
}
</script>
