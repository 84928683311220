var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [_vm._v(" ")]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("news-image-upload", {
                    attrs: { image: _vm.image },
                    on: { "image-file-selected": _vm.newsImageChanged }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.headerText,
                      expression: "headerText"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.headerText },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.headerText = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", [_vm._v(_vm._s(_vm.__("news.create.heading")))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shortText,
                      expression: "shortText"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { cols: "30", rows: "3" },
                  domProps: { value: _vm.shortText },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.shortText = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", [_vm._v(_vm._s(_vm.__("news.create.short_text")))])
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("vue-editor", {
                    model: {
                      value: _vm.content,
                      callback: function($$v) {
                        _vm.content = $$v
                      },
                      expression: "content"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-2 mb-2" },
                [
                  _c("file-upload", {
                    on: {
                      "start-pdf-upload": _vm.fileUpload,
                      "pdf-upload": _vm.fileUploadFinish
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.upload
                ? _c("div", { staticClass: "mt-2 mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "alert alert-success text-center" },
                      [
                        _c(
                          "a",
                          { attrs: { href: _vm.pdf_url, target: "_blank" } },
                          [_vm._v("PDF Anzeigen")]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
              !_vm.loading
                ? _c(
                    "span",
                    {
                      staticClass: "btn btn-light mr-1 mb-1",
                      on: { click: _vm.save }
                    },
                    [_vm._v(_vm._s(_vm.__("news.buttons.save")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-light mb-1",
                      attrs: { type: "button", disabled: "" }
                    },
                    [
                      _c("span", {
                        staticClass: "spinner-border spinner-border-sm",
                        attrs: { role: "status", "aria-hidden": "true" }
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.__("news.buttons.saving")) +
                          "\n                        "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }