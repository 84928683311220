var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "create-user" } }, [
    _c(
      "div",
      {
        staticClass: "container",
        attrs: { tabindex: "0" },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return (function() {
              _vm.showOwnerResult = false
              _vm.showMemberResult = false
            })($event)
          }
        }
      },
      [
        _c("div", { staticClass: "row justify-content-md-center" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(
                  "\n                             \n                        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "form-row" }),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                    _c("label", [_vm._v("Team Name")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.teamcp.teamName,
                          expression: "teamcp.teamName"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.teamcp.teamName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.teamcp, "teamName", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-12 col-md-6" },
                    [
                      _c("label", [_vm._v("Team Manager")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.teamcp.owner.name,
                            expression: "teamcp.owner.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          disabled: _vm.disableOwnerInput
                        },
                        domProps: { value: _vm.teamcp.owner.name },
                        on: {
                          focus: _vm.searchOwner,
                          blur: _vm.blurOwner,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "esc", 27, $event.key, [
                                "Esc",
                                "Escape"
                              ])
                            ) {
                              return null
                            }
                            return (function() {
                              return (_vm.showOwnerResult = false)
                            })($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.teamcp.owner,
                              "name",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.disableOwnerInput
                        ? _c(
                            "a",
                            {
                              staticStyle: {
                                position: "absolute",
                                bottom: "6px",
                                right: "15px",
                                cursor: "pointer"
                              },
                              on: { click: _vm.resetOwnerInput }
                            },
                            [_c("i", { staticClass: "fas fa-times-circle" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("search-table-user", {
                        attrs: {
                          columnData: _vm.allUsers,
                          visible: _vm.showOwnerResult,
                          searchString: _vm.teamcp.owner.name
                        },
                        on: { "tr-select": _vm.selectOwner }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("table", { staticClass: "table" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.team.member, function(member) {
                        return _c("tr", [
                          _c("td", [_vm._v(_vm._s(member.name))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(member.email))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeMember(member.id)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-times-circle",
                                  staticStyle: { color: "#000" }
                                })
                              ]
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-12" },
                    [
                      _c("label", [_vm._v("Mitglied hinzufügen")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.membername,
                            expression: "membername"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.membername },
                        on: {
                          focus: _vm.searchMember,
                          blur: _vm.blurMember,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "esc", 27, $event.key, [
                                "Esc",
                                "Escape"
                              ])
                            ) {
                              return null
                            }
                            return (function() {
                              return (_vm.showMemberResult = false)
                            })($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.membername = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("search-table-user", {
                        attrs: {
                          columnData: _vm.usersExceptTeam,
                          visible: _vm.showMemberResult,
                          searchString: _vm.membername
                        },
                        on: { "tr-select": _vm.selectMember }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-footer text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    on: { click: _vm.deleteTeam }
                  },
                  [_vm._v("Löschen")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn btn-light", on: { click: _vm.saveTeam } },
                  [_vm._v("Speichern")]
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Name")]),
        _vm._v(" "),
        _c("td", [_vm._v("E-Mail")]),
        _vm._v(" "),
        _c("td")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }