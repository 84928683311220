var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedUser,
            expression: "selectedUser"
          }
        ],
        staticClass: "form-control",
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedUser = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.changeUser
          ]
        }
      },
      _vm._l(_vm.users, function(user) {
        return _c("option", { domProps: { value: user.sw_user_id } }, [
          _vm._v(_vm._s(user.name))
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("label", [_vm._v("Kunden umhängen")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }