<template>
    <div class="table-responsive">
        <table class="table is-bordered data-table">
            <thead>
            <tr>
<!--                <th v-for="column in columns" :key="column.name" @click="$emit('sort', column.name)" :class="sortKey === column.name ? (sortOrders[column.name] > 0 ? 'sorting_asc' : 'sorting_desc') : 'sorting'" :style="'width:'+column.width+';'+'cursor:pointer;'">-->
<!--                <th v-for="column in columns" :key="column.name" @click="column.sortable ? $emit('sort', column.name) : null" :class="sortKey === column.name ? (sortOrders[column.name] > 0 ? 'sorting_asc' : 'sorting_desc') : 'sorting'" :style="'width:'+column.width+';'+'cursor:pointer;'">-->

                <th v-for="column in columns" :key="column.name" @click="column.sortable ? $emit('sort', column.name) : null" :class=" column.sortable ? (sortKey === column.name ? (sortOrders[column.name] > 0 ? 'sorting_asc' : 'sorting_desc') : 'sorting') : null" :style="'width:'+column.width+';'+'cursor:pointer;'">
                    {{column.label}}
                </th>
            </tr>
            </thead>
            <slot></slot>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['columns', 'sortKey', 'sortOrders']
    }
</script>
