<template>
    <section id="order-list">
        <div class="projects">

            <!--        <div class="tableFilters">-->


            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="searchField">{{ __('customer.search.label') }}</label>
                        <input class="form-control" type="text" v-model="tableData.search" placeholder="" @input="debounceSearch" id="searchField">
                        <small id="searchFieldHelp" class="form-text text-muted">{{ __('customer.search.small') }}</small>
                    </div>

                </div>

                <div class="col-6">
                    <div class="form-row justify-content-end">


                        <div class="form-group mr-2">
                            <div>&nbsp;</div>
                            <button class="btn btn-light" @click="syncButton">Synchronisieren</button>
                        </div>


                        <div class="form-group">
                            <label class="text-right" for="searchField">{{ __('datatable.items_per_page') }}</label>
                            <div class="items_per_page ">
                                <select class="form-control" v-model="tableData.length" @change="getProjects()">
                                    <option v-for="(records, index) in perPage" :key="index" :value="records">{{ records }}</option>
                                </select>
                            </div>
                        </div>


                    </div>
                </div>


            </div>

            <!--filter-->
            <div class="row">
                <div class="col-12">
                    <div class="form-header-text">Filter</div>
                </div>
            </div>
            <div class="row">
                <div class="col-10 col-lg-2">
                    <div class="form-group">
                        <!--                        <label>{{__('datatable.filter.customer_type')}}</label>-->
                        <label>Status</label>
                        <select class="form-control" v-model="tableData.filter.state">
                            <option :value="-99">Alle</option>
                            <option v-for="state in filterStates" :value="state.id">{{ state.description }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-2 col-lg-2">
                    <div class="form-group">
                        <div>&nbsp;</div>
                        <button class="btn btn-light" @click="getProjects">Filtern</button>
                    </div>
                </div>
            </div>


            <!---->
            <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" table-hover="true" @sort="sortBy">
                <tbody>
                <tr v-for="row in rows" :key="row.id">
                    <td>
                        <input type="checkbox" :value="row.id" v-model="checked">
                    </td>
                    <td @click="viewButton(row.id)">{{ row.ordernumber }}</td>
                    <td @click="viewButton(row.id)" v-if="row.firstname">{{ row.firstname }} {{ row.lastname }}</td>
                    <td @click="viewButton(row.id)" v-else> ---</td>
                    <td @click="viewButton(row.id)">{{ row.invoice_amount|toCurrency }}</td>
                    <td @click="viewButton(row.id)">{{ row.ordertime|formatDateTime }}</td>
                    <td @click="viewButton(row.id)">{{ row.description }}</td>
                    <!--                    <td @click="viewButton(row.id)">{{ row.stylist_commission * laravel_tax|toCurrency }}</td>-->
<!--                    <td @click="viewButton(row.id)">{{ row.stylist_commission|toCurrency }}</td>-->
                    <td @click="viewButton(row.id)">
                        <span v-if="row.is_crm_order === 1 && row.is_party_order === 1">{{ __('order.party_order') }}</span>
                        <span v-else-if="row.is_crm_order === 1 && row.is_party_order === 0">{{ __('order.crm_order') }}</span>
                        <span v-else>{{ __('order.shop') }}</span>
                    </td>
                    <td>
                        <a :href="'/backend/order/view/' + row.id"><i class="far fa-eye"></i></a>
                        <a @click="deleteOrder(row.id)" class="ml-1"><i class="far fa-trash-alt"></i></a>
                    </td>
                </tr>
                </tbody>
            </datatable>

            <!--        <div class="row">-->
            <!--            <div class="col-12" style="font-size: 9px; text-transform: uppercase">-->
            <!--                <span class="mr-4"><i class="far fa-circle is_stylist"></i> Stylistin</span>-->
            <!--                <span class="mr-4"><i class="far fa-circle is_host"></i> Gastgeberin</span>-->
            <!--                <span class="mr-4"><i class="far fa-circle is_lead"></i> Lead</span>-->
            <!--                <span class="mr-4"><i class="far fa-circle is_possible_host"></i> mögliche Gastgeberin</span>-->
            <!--            </div>-->
            <!--        </div>-->

            <laravel-vue-pagination :data="pagi" :limit="5" @pagination-change-page="getProjects"></laravel-vue-pagination>

        </div>
    </section>
</template>

<script>
import Datatable  from "../module/Datatable";
import Pagination from "../module/Pagination";

export default {
    name      : 'OrderList',
    components: {
        datatable : Datatable,
        pagination: Pagination,

    },
    props     : {
        columns     : {
            require: true,
        },
        filterStates: {
            default: -99,
            require: true,
        }
    },
    mounted()
    {
    },
    created()
    {
        this.columns.forEach((column) => {
            console.log(column);
            this.sortOrders[column.name] = -1;
        });

        this.getProjects();
    },
    data()
    {
        let sortOrders = {};
        return {
            rows: [],
            // columns: this.props.columns,
            sortKey   : 'deadline',
            sortOrders: sortOrders,
            perPage   : [
                '10',
                '20',
                '30'
            ],
            tableData : {
                draw  : 0,
                length: 10,
                search: '',
                column: 0,
                dir   : 'desc',
                page  : 1,
                filter: {
                    state: -99,
                    price: {}
                }
            },
            pagination: {
                lastPage   : '',
                currentPage: '',
                total      : '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from       : '',
                to         : ''
            },
            pagi      : {},
            checked   : [],
        };
    },
    methods: {

        // getProjects(url = '/products', page) {
        getProjects(page = 1)
        {
            let url             = '/getOrders';
            this.tableData.page = page;
            console.log('axios');
            this.tableData.draw++;
            axios.get(url, {params: this.tableData})
                .then(response => {
                    let data = response.data;
                    if (this.tableData.draw == data.draw) {
                        this.rows = data.data.data;
                        this.configPagination(data.data);
                        this.pagi = response.data.data;
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
        deleteOrder(orderId)
        {

            this.$vToastify.setSettings({
                                            backdrop    : 'rgba(0, 0, 0, 0.2)',
                                            withBackdrop: true,
                                            position    : 'center-center',
                                        })
            this.$vToastify.prompt({
                                       body   : "Bestellung löschen?",
                                       mode   : "prompt",
                                       answers: {
                                           Ja  : true,
                                           Nein: false
                                       }
                                   }).then(value => {
                if (value) {

                    console.log('/order/delete/' + orderId)
                    axios.post('/backend/order/delete', {
                        orderID: orderId
                    })
                        .then((response) => {
                            this.getProjects()
                            this.$vToastify.success({
                                                        title: 'Gelöscht',
                                                        body : ''
                                                    })
                        })
                        .catch((error) => {
                            console.log(error.response.data.message)
                            this.$vToastify.error({
                                                      title: 'Löschen',
                                                      body : 'Bestellung konnte nicht gelöscht werden.'
                                                  })
                        })
                }
            })
        },
        configPagination(data)
        {
            this.pagination.lastPage    = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total       = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from        = data.from;
            this.pagination.to          = data.to;
        },
        sortBy(key)
        {
            console.log('sort');
            this.sortKey          = key;
            this.sortOrders[key]  = this.sortOrders[key] * -1;
            this.tableData.column = this.getIndex(this.columns, 'name', key);
            this.tableData.dir    = this.sortOrders[key] === 1 ? 'asc' : 'desc';
            this.getProjects();
        },
        getIndex(array, key, value)
        {
            return array.findIndex(i => i[key] == value);
        },
        buttonTest(item)
        {
            console.log(item);
        },
        viewButton(id)
        {
            console.log(id);
            window.location.href = '/backend/order/view/' + id;
        },
        syncButton()
        {
            axios.post('/backend/order/sync')
                .then((response) => {
                    this.$vToastify.success({
                                                title: 'Synchronisieren',
                                                body : '',
                                                callback: () => {
                                                    // window.location.reload()
                                                    this.getProjects();
                                                }
                                            })
                })
                .catch((error) => {
                    console.log(error.response.data.message)
                    this.$vToastify.error({
                                              title: 'Synchronisieren',
                                              body : ''
                                          })
                })
        },

        debounceSearch: _.debounce(function () {
            console.log('search');
            this.getProjects();
        }, 500)
    }

};
</script>
<style lang="sass" scoped>
#order-list
    td
        cursor: pointer
</style>
