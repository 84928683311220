var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "large-12 medium-12 small-12 cell" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label"),
          _vm._v(" "),
          _c("input", {
            ref: "file",
            attrs: { type: "file", id: "file" },
            on: {
              change: function($event) {
                return _vm.handleFileUpload()
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              on: {
                click: function($event) {
                  return _vm.submitFile()
                }
              }
            },
            [_vm._v("Upload")]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.upload
      ? _c("div", {
          staticStyle: {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            "background-color": "#000",
            opacity: "0.5"
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }