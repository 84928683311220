<template>
    <section>
        <div id="customer_create">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">

                        <div class="card">
                            <div class="card-header">
                                &nbsp;
                            </div>
                            <div class="card-body">
                                <!--Stammdaten-->
                                <!--                            <div class="form-header-text">Kundendaten</div>-->

                                <!--                            <div class="form-row">-->
                                <!--                                <div class="form-group col-md-3">-->
                                <!--                                    <label for="customer_salutation">{{__('customer.salutation')}}</label>-->
                                <!--                                    <select class="form-control" id="customer_salutation" name="salutation" v-model="customer.salutation">-->
                                <!--                                        <option v-for="item in salutation" :value="item.value">{{item.name}}</option>-->
                                <!--                                    </select>-->
                                <!--                                </div>-->
                                <!--                            </div>-->

                                <!--                            <div class="form-row">-->
                                <!--                                <div class="form-group col-md-6">-->
                                <!--                                    <label for="firstname">{{__('customer.firstname')}}</label>-->
                                <!--                                    <input :class="{ 'is-invalid': validateErrors.firstname}" type="text" class="form-control" id="firstname" name="firstname" value="" v-model="customer.firstname">-->
                                <!--                                    <div class="invalid-feedback"></div>-->
                                <!--                                    <div v-if="validateErrors && validateErrors.firstname" class="invalid-feedback">{{ validateErrors.firstname[0] }}</div>-->
                                <!--                                </div>-->

                                <!--                                <div class="form-group col-md-6">-->
                                <!--                                    <label for="lastname">{{__('customer.lastname')}}</label>-->
                                <!--                                    <input :class="{ 'is-invalid': validateErrors.lastname}" type="text" class="form-control" id="lastname" name="lastname" value="" v-model="customer.lastname">-->
                                <!--                                    <div v-if="validateErrors && validateErrors.lastname" class="invalid-feedback">{{ validateErrors.lastname[0] }}</div>-->
                                <!--                                </div>-->

                                <!--                                <div class="form-group col-6 col-md-6">-->
                                <!--                                    <label for="email">{{__('customer.email_address')}}</label>-->
                                <!--                                    <input :class="{ 'is-invalid': validateErrors.email}" type="text" class="form-control" id="email" name="email" value="" v-model="customer.email">-->
                                <!--                                    <div v-if="validateErrors && validateErrors.email" class="invalid-feedback">{{ validateErrors.email[0] }}</div>-->
                                <!--                                </div>-->

                                <!--                                <div class="form-group col-6 col-md-6">-->
                                <!--                                    <label for="birthday">{{__('customer.birthday')}}</label>-->
                                <!--                                    <input type="date" :class="{ 'is-invalid': validateErrors.birthday}" id="birthday" class="form-control" value="" v-model="customer.birthday">-->
                                <!--                                    <div v-if="validateErrors && validateErrors.birthday" class="invalid-feedback">{{ validateErrors.birthday[0] }}</div>-->
                                <!--                                </div>-->

                                <!--                                <div class="form-group col-12">-->
                                <!--                                    <label for="internalComment">{{__('customer.internalComment')}}</label>-->
                                <!--                                    <textarea class="form-control" name="" id="internalComment" cols="30" rows="3" v-model="customer.internalComment"></textarea>-->
                                <!--                                </div>-->
                                <!--                            </div>-->
                                <!--addresses-->
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active form-header-text" id="billing-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ __('customer.billingAddress') }}</a>
                                    </li>
                                    <li class="nav-item">
                                        <!--                                    <a :class="customer.address_billing_eq_shipping ? 'disabled': ''" class="nav-link form-header-text" id="shipping-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" :aria-disabled="customer.address_billing_eq_shipping">{{ __('customer.shippingAddress') }}</a>-->
                                        <a class="nav-link form-header-text disabled" id="shipping-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" :aria-disabled="customer.address_billing_eq_shipping">{{ __('customer.shippingAddress') }}</a>
                                    </li>
                                </ul>


                                <div class="tab-content" id="myTabContent">

                                    <!--billing address-->
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="billing-tab">


                                        <!--#################################-->
                                        <div class="form-row mt-3">
                                            <div class="form-group col-md-3">
                                                <label for="customer_salutation">{{ __('customer.salutation') }}</label>
                                                <select class="form-control" id="customer_salutation" name="salutation" v-model="customer.salutation">
                                                    <option v-for="item in salutation" :value="item.value">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="firstname">{{ __('customer.firstname') }}</label>
                                                <input :class="{ 'is-invalid': validateErrors.firstname}" type="text" class="form-control" id="firstname" name="firstname" value="" v-model="customer.firstname">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors.firstname" class="invalid-feedback">{{ validateErrors.firstname[0] }}</div>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="lastname">{{ __('customer.lastname') }}</label>
                                                <input :class="{ 'is-invalid': validateErrors.lastname}" type="text" class="form-control" id="lastname" name="lastname" value="" v-model="customer.lastname">
                                                <div v-if="validateErrors && validateErrors.lastname" class="invalid-feedback">{{ validateErrors.lastname[0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-6">
                                                <label for="email">{{ __('customer.email_address') }}</label>
                                                <input :class="{ 'is-invalid': validateErrors.email}" type="text" class="form-control" id="email" name="email" value="" v-model="customer.email">
                                                <div v-if="validateErrors && validateErrors.email" class="invalid-feedback">{{ validateErrors.email[0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-6">
                                                <label for="birthday">{{ __('customer.birthday') }}</label>
                                                <input type="date" :class="{ 'is-invalid': validateErrors.birthday}" id="birthday" class="form-control" value="" v-model="customer.birthday">
                                                <div v-if="validateErrors && validateErrors.birthday" class="invalid-feedback">{{ validateErrors.birthday[0] }}</div>
                                            </div>

                                        </div>
                                        <!--#################################-->


                                        <div class="form-row">

                                            <div class="form-group col-6 col-md-5">
                                                <label for="billing_street">{{ __('customer.street') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['billing.street']}" class="form-control" id="billing_street" value="" v-model="customer.billing.street">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['billing.street']" class="invalid-feedback">{{ validateErrors['billing.street'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-2">
                                                <label for="billing_zipcode">{{ __('customer.zipcode') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['billing.zipcode']}" class="form-control" id="billing_zipcode" value="" v-model="customer.billing.zipcode">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['billing.zipcode']" class="invalid-feedback">{{ validateErrors['billing.zipcode'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-5">
                                                <label for="billing_city">{{ __('customer.city') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['billing.city']}" class="form-control" id="billing_city" value="" v-model="customer.billing.city">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['billing.city']" class="invalid-feedback">{{ validateErrors['billing.city'][0] }}</div>
                                            </div>

                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-6 col-md-6">
                                                <label for="billing_phone">{{ __('customer.phone') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['billing.phone']}" class="form-control" id="billing_phone" value="" v-model="customer.billing.phone">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['billing.phone']" class="invalid-feedback">{{ validateErrors['billing.phone'][0] }}</div>
                                            </div>
                                        </div>

                                        <div class="form-row">

                                            <div class="form-group col-12">
                                                <label for="internalComment">{{ __('customer.internalComment') }}</label>
                                                <textarea class="form-control" name="" id="internalComment" cols="30" rows="3" v-model="customer.internalComment"></textarea>
                                            </div>
                                        </div>

                                        <div class="form-row mt-4">
                                            <div class="form-group col">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="defaultCheck1" v-model="customer.address_billing_eq_shipping">
                                                    <label class="form-check-label" for="defaultCheck1">{{ __('customer.billingEqShipping') }}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- shippingAddress-->
                                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="shipping-tab">

                                        <div class="form-row mt-3">
                                            <div class="form-group col-md-3">
                                                <label for="shipping_customer_salutation">{{ __('shipping.salutation') }}</label>
                                                <select class="form-control" id="shipping_customer_salutation" name="salutation" v-model="customer.shipping.salutation">
                                                    <option v-for="item in salutation" :value="item.value">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-6 col-md-6">
                                                <label for="shipping_firstname">{{ __('customer.firstname') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['shipping.firstname']}" class="form-control" id="shipping_firstname" value="" v-model="customer.shipping.firstname">
                                            </div>

                                            <div class="form-group col-6 col-md-6">
                                                <label for="shipping_lastname">{{ __('customer.lastname') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['shipping.lastname']}" class="form-control" id="shipping_lastname" value="" v-model="customer.shipping.lastname">
                                            </div>
                                        </div>

                                        <div class="form-row">

                                            <div class="form-group col-6 col-md-5">
                                                <label for="shipping_street">{{ __('customer.street') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['shipping.street']}" class="form-control" id="shipping_street" value="" v-model="customer.shipping.street">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['shipping.street']" class="invalid-feedback">{{ validateErrors['shipping.street'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-2">
                                                <label for="shipping_zipcode">{{ __('customer.zipcode') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['shipping.zipcode']}" class="form-control" id="shipping_zipcode" value="" v-model="customer.shipping.zipcode">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['shipping.zipcode']" class="invalid-feedback">{{ validateErrors['shipping.zipcode'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-5">
                                                <label for="shipping_city">{{ __('customer.city') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['shipping.city']}" class="form-control" id="shipping_city" value="" v-model="customer.shipping.city">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['shipping.city']" class="invalid-feedback">{{ validateErrors['shipping.city'][0] }}</div>
                                            </div>

                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-6 col-md-6">
                                                <label for="shipping_phone">{{ __('customer.phone') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['shipping.phone']}" class="form-control" id="shipping_phone" value="" v-model="customer.shipping.phone">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['shipping.phone']" class="invalid-feedback">{{ validateErrors['shipping.phone'][0] }}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <!--                                <div class="form-row">-->

                                    <!--                                    <div class="form-group col-12">-->
                                    <!--                                        <label for="internalComment">{{ __('customer.internalComment') }}</label>-->
                                    <!--                                        <textarea class="form-control" name="" id="internalComment" cols="30" rows="3" v-model="customer.internalComment"></textarea>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->

                                    <!--                                <div class="form-row mt-4">-->
                                    <!--                                    <div class="form-group col">-->
                                    <!--                                        <div class="form-check">-->
                                    <!--                                            <input class="form-check-input" type="checkbox" id="defaultCheck1" v-model="customer.address_billing_eq_shipping">-->
                                    <!--                                            <label class="form-check-label" for="defaultCheck1">{{ __('customer.billingEqShipping') }}</label>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->


                                </div>
                            </div>


                        </div>
                        <div class="card-footer">

                        </div>
                    </div>
                    <!--            </div>-->


                    <div class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-header">
                                &nbsp;
                            </div>
                            <div class="card-body">
                                <div class="form-header-text">{{ __('customer.rolle') }}</div>
                                <div class="form-row mt-3">
                                    <div class="form-group col-12 col-md-12">
                                        <div v-for="type in customerTypeRadio" class="form-check form-check-inline" :key="type.value">
                                            <input type="radio" :id="'customRadio' + type.value" class="form-check-input" :value="type.value" v-model="customer.customerType">
                                            <label class="form-check-label" :for="'customRadio'+type.value">{{ type.name }}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" style="display: none">
                                    <div class="form-group col-6 col-md-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="newsletter" v-model="customer.newsletter">
                                            <label class="form-check-label" for="newsletter">{{ __('customer.newsletter') }}</label>
                                        </div>
                                    </div>
                                </div>

                                <!--                            <div class="form-row">-->
                                <!--                                <div class="form-group col-md-3">-->
                                <!--                                    <label for="customer_payment">{{ __('customer.default.payment') }}</label>-->
                                <!--                                    <select class="form-control" id="customer_payment" name="customer_payment" v-model="customer.payment">-->
                                <!--                                        <option v-for="item in payment" :value="item.id">{{ item.description }}</option>-->
                                <!--                                    </select>-->
                                <!--                                </div>-->
                                <!--                            </div>-->


                                <div class="form-row">
                                    <!--                                <div class="form-group col-12 col-md-4" v-if="is('admin')">-->
                                    <!--                                    <label for="customer_tags">{{ __('customer.tags') }}</label>-->
                                    <!--                                    &lt;!&ndash;                                    data-vv-validate-on="enter"&ndash;&gt;-->
                                    <!--                                    <input :class="{ 'is-invalid': errors.has('tag')}" type="text" class="form-control" id="customer_tags" @keyup.enter="addTag()" v-model="tag" name="tag" v-validate="'min:3|tag_unique'">-->
                                    <!--                                    <div v-if="errors.has('tag')" class="invalid-feedback">{{ errors.first('tag') }}</div>-->
                                    <!--                                </div>-->

                                    <!--                                <div class="form-group col-12 col-12">-->
                                    <!--                                    <label></label>-->
                                    <!--                                    <select class="custom-select" v-model="tags" multiple>-->
                                    <!--                                        &lt;!&ndash;                                        <option value="0"></option>&ndash;&gt;-->
                                    <!--                                        <option v-for="tagValue in tagvalues" :value="tagValue">{{ tagValue }}</option>-->
                                    <!--                                    </select>-->
                                    <!--                                </div>-->

                                    <div class="form-header-text mb-2">{{ __('customer.interests') }}</div>
                                    <show-tags :tags="tagvalues" :checked-tags="tagsChecked" @tag-checked="addTags"></show-tags>
                                </div>
                                <!--                            <span class="badge badge-dark" v-for="(tag, id) in tags" :key="tag"> <span style="padding: 5px">{{ tag }}<i @click="deleteTag(id)" class="fas fa-times pl-1"></i></span></span>-->


                            </div>
                            <div class="card-footer text-right">
                                <button @click="saveCustomer" class="btn btn-light">{{ __('customer.buttons.save') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <event-modal
            :event-types="eventTypes"
            :show-modal="showModal"
            @event-modal-data="modalEvenData"
            @event-close-button="modelCloseButtonClick"
        ></event-modal>
    </section>
</template>


<script>

export default {

    components: {},
    props     : {
        payment    : {
            type    : Array,
            required: true,
        },
        parties    : {
            type    : Array,
            required: true,
        },
        is_order   : {
            type   : Boolean,
            default: false
        },
        tagvalues  : {
            // type: Array,
        },
        tagsChecked: {},
        eventTypes : {},
    },
    name      : "CustomerCreate",
    data      : function () {
        return {
            showModal        : false,
            calendarEventData: null,
            saved            : false,
            customer         : {
                salutation     : 'ms',
                firstname      : '',
                lastname       : '',
                email          : '',
                birthday       : '',
                internalComment: '',
                newsletter     : false,
                payment        : 5,
                calendarEventData: null,

                billing : {
                    salutation: 'ms',
                    firstname : '',
                    lastname  : '',
                    street    : '',
                    zipcode   : '',
                    city      : '',
                    country   : 2,
                    phone     : '',
                },
                shipping: {
                    salutation: 'ms',
                    firstname : '',
                    lastname  : '',
                    street    : '',
                    zipcode   : '',
                    city      : '',
                    country   : 2,
                    phone     : '',
                },

                customerType: 0,

                address_billing_eq_shipping: true,

                // partyId: 0,
                partyId: [],
                tags   : {},

            },
            customerTypeRadio: [
                {
                    value: 0,
                    name : 'Kundin',
                },
                // {
                // 	value: 1,
                // 	name : 'Stylistin',
                // },
                {
                    value: 2,
                    name : 'Gastgeberin',
                },
                {
                    value: 3,
                    name : 'Lead',
                },
                {
                    value: 4,
                    name : 'potentielle Gastgeberin',
                },
                {
                    value: 5,
                    name : 'potentielle Stylistin',
                },
            ],
            salutation       : [
                {
                    value: 'ms',
                    name : 'Frau',
                },
                {
                    value: 'mr',
                    name : 'Herr',
                },
            ],

            validateErrors    : {
                billing : {},
                shipping: {},
            },
            tags              : [],
            tag               : '',
            value             : [],
            multiSelectOptions: this.parties,
        };
    },
    created()
    {
        this.$validator.extend('tag_unique', {
            getMessage: (field) => 'dieser Tag ist bereits vorhanden!',
            validate  : (value) => {
                return !this.tags.includes(value.charAt(0).toUpperCase() + this.tag.slice(1));
            }
        });
    },
    methods   : {
        addTags(tags)
        {
            this.tags = tags;
        },
        addTag()
        {
            //todo:remove
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.tags.push(this.tag.charAt(0).toUpperCase() + this.tag.slice(1));
                    this.tag = '';
                }
            });
        },

        deleteTag(id)
        {
            this.tags.splice(id, 1);
        },
        saveCustomer()
        {
            let _self                        = this;
            this.customer.billing.salutation = this.customer.salutation;
            this.customer.billing.firstname  = this.customer.firstname;
            this.customer.billing.lastname   = this.customer.lastname;
            this.customer.tags               = this.tags;
            this.customer.calendarEventData  = this.calendarEventData;


            axios.post('/backend/customer/saveCustomer', _self.customer)
                .then(function (response) {
                    console.log('response: ' + response.data);
                    _self.validateErrors = {};
                    _self.showToast('Gespeichert', 'Kundennummer: ' + response.data.data.customerNumber, 'success');
                    _self.resetToDefault();

                })
                .catch(function (error) {
                    console.log('error: ' + error);
                    if (error.response.status === 422) {
                        _self.validateErrors = error.response.data.errors || {};
                    }
                    _self.showToast('Fehler', 'Kunde konnte nicht gespeichert werden!', 'error');
                });

        },
        showToast(title, body, type)
        {

            if (type === 'success') {
                this.$vToastify.success({
                                            title: title,
                                            body : body,
                                        });
            }

            if (type === 'error') {
                this.$vToastify.error({
                                          title: title,
                                          body : body,
                                      });
            }
        },
        resetToDefault()
        {
            let customer = {
                salutation     : 'ms',
                firstname      : '',
                lastname       : '',
                email          : '',
                birthday       : '',
                internalComment: '',
                newsletter     : false,
                payment        : 5,

                billing                    : {
                    salutation: 'ms',
                    firstname : '',
                    lastname  : '',
                    street    : '',
                    zipcode   : '',
                    city      : '',
                    country   : 2,
                    phone     : '',
                },
                shipping                   : {
                    salutation: 'ms',
                    firstname : '',
                    lastname  : '',
                    street    : '',
                    zipcode   : '',
                    city      : '',
                    country   : 2,
                    phone     : '',
                },
                tags                       : [],
                customerType               : 0,
                address_billing_eq_shipping: true,
            };

            this.customer = customer;
            this.tags     = [];

        },

        customLabel({party_name})
        {
            return `${party_name}`;
            // return `${title} – ${desc}`
        },

        modalEvenData(data)
        {
            this.calendarEventData = data;
            this.showModal         = false;
        },
        modelCloseButtonClick(data)
        {
            this.showModal = data;
        },

    },
    watch     : {
        'customer.address_billing_eq_shipping': function (val, old) {
            if (val) {
                $('#billing-tab').tab('show');
                $('#shipping-tab').addClass('disabled');
            } else {
                $('#shipping-tab').removeClass('disabled');
                $('#shipping-tab').tab('show');
            }
        },
        'customer.customerType'               : function (val) {
            if (val === 4 || val === 5) {
                this.showModal = true;
            } else {
                this.showModal = false;
            }
        },
    }
};

</script>

<style scoped>

</style>
