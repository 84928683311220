<template>
    <section>
        <div class="container h-100">

            <div class="card">
                <div class="card-header">&nbsp;</div>
                <div class="card-body">

                    <div class="row">
                        <div class="col-6">
                            <youtube :video-id="videoID" :player-vars="playerVars" ref="youtube"></youtube>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <!--                                <input class="form-control" type="text" v-model="videoID">-->

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon3">https://www.youtube.com/watch?v=</span>
                                    </div>
                                    <input type="text" class="form-control" v-model="videoID">
                                </div>
                                <label>{{ __('news.video.youtubeID') }}</label>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-footer text-right">
                    <button class="btn btn-light" @click="saveVideoUrl">{{ __('news.buttons.save') }}</button>
                </div>
            </div>

        </div>
    </section>
</template>

<script>

import {getIdFromUrl} from 'vue-youtube'

export default {
    name   : "NewsVideo",
    props  : ['videoUrl'],
    data()
    {
        return {
            videoID   : null,
            playerVars: {
                autoplay      : 0,
                controls      : 0,
                start         : 2,
                modestbranding: 1,
                rel           : 0,

            }
        };
    },
    mounted()
    {
        this.videoID = this.videoUrl
    },
    methods: {
        saveVideoUrl()
        {

            if (!this.$emptyCheck(this.videoID)) {

                axios.post('/backend/news/video/save', {
                    videoID: this.videoID
                })
                    .then((response) => {
                        this.showToast('Video - News', 'Gespeichert', 'success');
                    })
                    .catch((error) => {
                        this.showToast('Video - News', 'Fehler', 'error');
                    })
            }

        },
        showToast(title, body, type)
        {

            if (type === 'success') {
                this.$vToastify.success({
                                            title: title,
                                            body : body,
                                        });
            }

            if (type === 'error') {
                this.$vToastify.error({
                                          title: title,
                                          body : body,
                                      });
            }
        },
    },


};
</script>

<style lang="sass" scoped>


</style>
