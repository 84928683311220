var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "shopware-settings" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.__("settings.shopware.card.header")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-5" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.__("settings.shopware.shopware_api_user"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settings.shopware_api_user,
                        expression: "settings.shopware_api_user"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.settings.shopware_api_user },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.settings,
                          "shopware_api_user",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row mt-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.__("settings.shopware.shopware_api_url")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settings.shopware_api_url,
                        expression: "settings.shopware_api_url"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.settings.shopware_api_url },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.settings,
                          "shopware_api_url",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row mt-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.__("settings.shopware.shopware_api_key")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settings.shopware_api_key,
                        expression: "settings.shopware_api_key"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.settings.shopware_api_key },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.settings,
                          "shopware_api_key",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light",
                  on: { click: _vm.saveSettings }
                },
                [_vm._v(_vm._s(_vm.__("settings.buttons.save")))]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }