<template>
    <section id="shopware-settings">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-6">
                    <div class="card">
                        <div class="card-header">
                            {{__('settings.shopware.card.header')}}
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-5">
                                    <label>{{__('settings.shopware.shopware_api_user')}}</label>
                                    <input class="form-control" type="text" v-model="settings.shopware_api_user">
                                </div>
                            </div>

                            <div class="form-row mt-3">
                                <div class="col-12">
                                    <label>{{__('settings.shopware.shopware_api_url')}}</label>
                                    <input class="form-control" type="text" v-model="settings.shopware_api_url">
                                </div>
                            </div>
                            <div class="form-row mt-3">
                                <div class="col-12">
                                    <label>{{__('settings.shopware.shopware_api_key')}}</label>
                                    <input class="form-control" type="text" v-model="settings.shopware_api_key">
                                </div>
                            </div>

                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-light" @click="saveSettings">{{__('settings.buttons.save')}}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
	export default {
		name   : "shopware",
		data() {
			return {

				settings_key: 'shopware_api',
				settings    : {
					shopware_api_url : '',
					shopware_api_user: '',
					shopware_api_key : '',
				}
			};
		},
		mounted() {
			this.getShopwareSettings();
		},
		methods: {

			getShopwareSettings() {
				let _self = this;
				// axios.post('/backend/settings/getSettingsAxios', _self.settings_key)
				axios.post('/backend/settings/getSettingsAxios', {
					key: _self.settings_key
				},)
					 .then(response => {
						 _self.settings = response.data;
					 })
					 .catch(error => {
						 console.log('error');
					 });
			},
			saveSettings() {
				let _self = this;
				axios.post('/backend/settings/saveSettingsAxios', {
					key     : _self.settings_key,
					settings: _self.settings
				})
					 .then(response => {
						 _self.showToast('Settings', 'Gespichert', 'success');
					 })
					 .catch(error => {
						 console.log(error);
						 _self.showToast('Settings', 'Fehler', 'success');
					 });
			},
			showToast(title, body, type) {

				if (type === 'success') {
					this.$vToastify.success({
												title: title,
												body : body,
											});
				}

				if (type === 'error') {
					this.$vToastify.error({
											  title: title,
											  body : body,
										  });
				}
			},

		},


	};
</script>
