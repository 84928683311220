<template>
    <div class="row">
        <div class="col-6">
            <div class="form-row">
                <div class="form-group col-12">
                    <input class="form-control-file" type="file" @change="selectFile">
                    <label>{{ __('news.create.news_image') }}</label>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="news">
                <div class="news--image-container">
                    <div v-if="newsImage" class="news--image" :style="{'background-image': 'url(' + newsImage + ')' }"></div>
                    <div v-else><img src="/images/no-image.png" style="width: 100px; height: auto" alt=""></div>
                </div>
                <!--                <img v-if="newsImage!== null" class="img-fluid" :src="newsImage" alt="">-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name   : "NewsImageUpload",
    props  : {
        image: {
            default: null
        }
    },

    data()
    {
        return {
            newsImage: null,
        };
    },
    mounted()
    {
        if (this.$emptyCheck(this.image)) {
        } else {
            this.newsImage = this.image
        }
    },
    methods: {
        selectFile(event)
        {
            // this.avatar = event.target.files[0];
            let newsImage = event.target.files[0];

            let reader = new FileReader();
            if (newsImage['size'] < 2111775) {
                reader.onloadend = (newsImage) => {
                    this.newsImage = reader.result;
                    this.$emit('image-file-selected', this.newsImage);
                };
                reader.readAsDataURL(newsImage);
            } else {
                alert('Das Bild darf nicht größer sein als 2MB');
            }


        }
    },
    watch: {
        image: function (n, o) {
            this.newsImage = this.image
        }
    }
};
</script>

<style lang="sass" scoped>


</style>
