var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "order-list" } }, [
    _c(
      "div",
      { staticClass: "projects" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "searchField" } }, [
                _vm._v(_vm._s(_vm.__("customer.search.label")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tableData.search,
                    expression: "tableData.search"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "", id: "searchField" },
                domProps: { value: _vm.tableData.search },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.tableData, "search", $event.target.value)
                    },
                    _vm.debounceSearch
                  ]
                }
              }),
              _vm._v(" "),
              _c(
                "small",
                {
                  staticClass: "form-text text-muted",
                  attrs: { id: "searchFieldHelp" }
                },
                [_vm._v(_vm._s(_vm.__("customer.search.small")))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "form-row justify-content-end" }, [
              _c("div", { staticClass: "form-group mr-2" }, [
                _c("div", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    on: { click: _vm.syncButton }
                  },
                  [_vm._v("Synchronisieren")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "text-right", attrs: { for: "searchField" } },
                  [_vm._v(_vm._s(_vm.__("datatable.items_per_page")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "items_per_page " }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tableData.length,
                          expression: "tableData.length"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.tableData,
                              "length",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            return _vm.getProjects()
                          }
                        ]
                      }
                    },
                    _vm._l(_vm.perPage, function(records, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: records } },
                        [_vm._v(_vm._s(records))]
                      )
                    }),
                    0
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-10 col-lg-2" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Status")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tableData.filter.state,
                      expression: "tableData.filter.state"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.tableData.filter,
                        "state",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: -99 } }, [_vm._v("Alle")]),
                  _vm._v(" "),
                  _vm._l(_vm.filterStates, function(state) {
                    return _c("option", { domProps: { value: state.id } }, [
                      _vm._v(_vm._s(state.description))
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2 col-lg-2" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("div", [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-light",
                  on: { click: _vm.getProjects }
                },
                [_vm._v("Filtern")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "datatable",
          {
            attrs: {
              columns: _vm.columns,
              sortKey: _vm.sortKey,
              sortOrders: _vm.sortOrders,
              "table-hover": "true"
            },
            on: { sort: _vm.sortBy }
          },
          [
            _c(
              "tbody",
              _vm._l(_vm.rows, function(row) {
                return _c("tr", { key: row.id }, [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checked,
                          expression: "checked"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: row.id,
                        checked: Array.isArray(_vm.checked)
                          ? _vm._i(_vm.checked, row.id) > -1
                          : _vm.checked
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = row.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checked = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checked = $$c
                          }
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      on: {
                        click: function($event) {
                          return _vm.viewButton(row.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.ordernumber))]
                  ),
                  _vm._v(" "),
                  row.firstname
                    ? _c(
                        "td",
                        {
                          on: {
                            click: function($event) {
                              return _vm.viewButton(row.id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(row.firstname) + " " + _vm._s(row.lastname)
                          )
                        ]
                      )
                    : _c(
                        "td",
                        {
                          on: {
                            click: function($event) {
                              return _vm.viewButton(row.id)
                            }
                          }
                        },
                        [_vm._v(" ---")]
                      ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      on: {
                        click: function($event) {
                          return _vm.viewButton(row.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm._f("toCurrency")(row.invoice_amount)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      on: {
                        click: function($event) {
                          return _vm.viewButton(row.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm._f("formatDateTime")(row.ordertime)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      on: {
                        click: function($event) {
                          return _vm.viewButton(row.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.description))]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      on: {
                        click: function($event) {
                          return _vm.viewButton(row.id)
                        }
                      }
                    },
                    [
                      row.is_crm_order === 1 && row.is_party_order === 1
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.__("order.party_order")))
                          ])
                        : row.is_crm_order === 1 && row.is_party_order === 0
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.__("order.crm_order")))
                          ])
                        : _c("span", [_vm._v(_vm._s(_vm.__("order.shop")))])
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      { attrs: { href: "/backend/order/view/" + row.id } },
                      [_c("i", { staticClass: "far fa-eye" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "ml-1",
                        on: {
                          click: function($event) {
                            return _vm.deleteOrder(row.id)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt" })]
                    )
                  ])
                ])
              }),
              0
            )
          ]
        ),
        _vm._v(" "),
        _c("laravel-vue-pagination", {
          attrs: { data: _vm.pagi, limit: 5 },
          on: { "pagination-change-page": _vm.getProjects }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "form-header-text" }, [_vm._v("Filter")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }