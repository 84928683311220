<template>
    <section id="customer-list">
        <div class="projects">

            <!--        <div class="tableFilters">-->


            <div class="row">

                <div class="col-6">
                    <div class="form-group">
                        <label for="searchField">{{ __('customer.search.label') }}</label>
                        <input class="form-control" type="text" v-model="tableData.search" placeholder="" @input="debounceSearch" id="searchField">
                        <small id="searchFieldHelp" class="form-text text-muted">{{ __('customer.search.small') }}</small>
                    </div>
                </div>

                <div class="col-6 text-right">
                    <div class="form-group">
                        <label class="text-right" for="searchField">{{ __('datatable.items_per_page') }}</label>
                        <div class="items_per_page ">
                            <select class="form-control" v-model="tableData.length" @change="getProjects()">
                                <option v-for="(records, index) in perPage" :key="index" :value="records">{{ records }}</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>

            <!--filter-->
            <div class="row">
                <div class="col-12">
                    <div class="form-header-text">Filter</div>
                </div>
            </div>

            <div class="row">

                <div class="col-12 col-lg-2">
                    <div class="form-group">
                        <label>{{ __('datatable.filter.customer_type') }}</label>
                        <select class="form-control" v-model="tableData.filter.customerType">
                            <option v-for="customerType in customerTypeFilterData" :value="customerType.id">{{ customerType.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <!--                        <show-tags :tags="tagValues" :checked-tags="tags" @tag-checked="addTags"></show-tags>-->
                        <label>{{ __('datatable.filter.tags') }}</label>
                        <div class="mt-2"></div>
                        <show-tags :tags="tagValues" @tag-checked="addTags"></show-tags>
                    </div>
                </div>

                <div v-if="is('admin')" class="col-12 col-lg-2">
                    <div class="form-group">
                        <label>{{ __('datatable.filter.stylist') }}</label>
                        <select class="form-control" v-model="tableData.filter.stylist_sw_id">
                            <option v-for="stylist in stylistFilter" :value="stylist.sw_user_id">{{ stylist.name }}</option>
                        </select>
                    </div>
                </div>

                <!--      <div class="col-2 col-lg-2 offset-lg-8 text-right">-->
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                        <div>&nbsp;</div>
                        <button class="btn btn-light" @click="getProjects">Filtern</button>
                        <button class="btn btn-light" @click="userExport">Export</button>
                    </div>
                </div>
            </div>

            <!--filter-->


            <!--        </div>-->

            <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" :table-hover="true" @sort="sortBy">
                <tbody>

                <tr v-for="row in rows" :key="row.id">
                    <td>
                        <input type="checkbox" :value="row.id" v-model="checked">
                    </td>
                    <td @click="viewButton(row.id)">{{ row.customernumber }}</td>
                    <td @click="viewButton(row.id)">{{ row.firstname }}</td>
                    <td @click="viewButton(row.id)">{{ row.lastname }}</td>
                    <td @click="viewButton(row.id)">{{ row.street }}</td>
                    <td @click="viewButton(row.id)">{{ row.zipcode }}</td>
                    <td @click="viewButton(row.id)">{{ row.city }}</td>
                    <!--                <td v-if="row.internalcomment" class="text-center"><i class="fas fa-comment-alt" data-toggle="tooltip" data-placement="left" :title="row.internalcomment"></i></td>-->
                    <td @click="viewButton(row.id)" v-if="row.internalcomment" class="text-center"><i class="fas fa-comment-alt"></i></td>
                    <td @click="viewButton(row.id)" v-else></td>
                    <td @click="viewButton(row.id)">
                        <span v-if="row.is_stylist"><i class="far fa-circle is_stylist"></i></span>
                        <span v-else-if="row.is_host"><i class="far fa-circle is_host"></i></span>
                        <span v-else-if="row.is_lead"><i class="far fa-circle is_lead"></i></span>
                        <span v-else-if="row.is_possible_host"><i class="far fa-circle is_possible_host"></i></span>
                        <span v-else-if="row.is_possible_stylist"><i class="far fa-circle is_possible_stylist"></i></span>
                    </td>
                    <td @click="viewButton(row.id)">{{ row.stylist_name }}</td>
                    <td class="table--action-buttons">

                        <!--                    <a class="" :href="'/backend/customer/edit/' + row.id"><i class="far fa-edit"></i></a>-->
                        <a class="" :href="'/backend/customer/view/' + row.id"><i class="far fa-eye"></i></a>
                        <a class="ml-1" @click="deleteCustomer(row.id)"><i class="far fa-trash-alt"></i></a>


                        <!--                    <div class="dropdown">-->
                        <!--                        <button class="btn btn-sm" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></button>-->
                        <!--                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">-->
                        <!--                            <a class="dropdown-item" :href="'/backend/customers/edit/' + row.id">Edit</a>-->
                        <!--&lt;!&ndash;                            <a class="dropdown-item" href="#">Another action</a>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            <a class="dropdown-item" href="#">Something else here</a>&ndash;&gt;-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                    </td>
                </tr>
                </tbody>
            </datatable>

            <div class="row">
                <div class="col-12" style="font-size: 9px; text-transform: uppercase">
                    <span class="mr-4"><i class="far fa-circle is_stylist"></i> Stylistin</span>
                    <span class="mr-4"><i class="far fa-circle is_host"></i> Gastgeberin</span>
                    <span class="mr-4"><i class="far fa-circle is_lead"></i> Lead</span>
                    <span class="mr-4"><i class="far fa-circle is_possible_host"></i> potentielle Gastgeberin</span>
                    <span class="mr-4"><i class="far fa-circle is_possible_stylist"></i> potentielle Stylistin</span>
                </div>
                <!--            <div class="col-1" style="font-size: 10px"><i class="far fa-circle is_host"></i> Gastgeberin</div>-->
                <!--            <div class="btn btn-dark col-2">Stylistin</div>-->
                <!--            <div class="btn btn-danger col-2">Gastgeberin</div>-->
            </div>

            <laravel-vue-pagination :data="pagi" :limit="5" @pagination-change-page="getProjects"></laravel-vue-pagination>

        </div>
    </section>
</template>

<script>
import Datatable  from "../module/Datatable";
import Pagination from "../module/Pagination";

export default {
    name      : 'customer-list',
    components: {
        datatable : Datatable,
        pagination: Pagination
    },
    props     : {
        columns       : {
            // type: array,
            require: true,
            //     // validator: function (value) {
            //     //
            //     // },
            //
            //
        },
        tagValues     : {},
        filterStylists: {}


    },
    created()
    {
        this.columns.forEach((column) => {
            console.log(column);
            this.sortOrders[column.name] = -1;
        });

        this.getProjects();
        // $('[data-toggle="tooltip"]').tooltip()
    },
    data()
    {
        let sortOrders = {};


        // let columns = [
        //     {width: '', label: '', name: '', sortable: false},
        //     {width: '', label: 'SKU', name: 'sku', sortable: true},
        //     {width: '', label: 'name', name: 'name', sortable: true},
        //     {width: '', label: 'Price', name: 'price', sortable: true},
        //     {width: '', label: 'Actions', name: 'actions', sortable: false},
        // ];

        // columns.forEach((column) => {
        //     sortOrders[column.name] = -1;
        // });
        return {
            rows: [],
            // columns: this.props.columns,
            sortKey               : 'deadline',
            sortOrders            : sortOrders,
            perPage               : [
                '10',
                '20',
                '30'
            ],
            tableData             : {
                draw  : 0,
                length: 10,
                search: '',
                column: 0,
                dir   : 'desc',
                page  : 1,
                filter: {
                    price        : {},
                    customerType : -1,
                    tags         : [],
                    stylist_sw_id: -1
                }
            },
            pagination            : {
                lastPage   : '',
                currentPage: '',
                total      : '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from       : '',
                to         : ''
            },
            pagi                  : {},
            checked               : [],
            customerTypeFilterData: [
                {
                    id  : -1,
                    name: 'Alle'
                },
                // {
                //   id  : 0,
                //   name: 'Shop'
                // },
                {
                    id  : 1,
                    name: 'Stylistin'
                },
                {
                    id  : 2,
                    name: 'Gastgeberin'
                },
                {
                    id  : 3,
                    name: 'Lead'
                },
                {
                    id  : 4,
                    name: 'potentielle Gastgeberin'
                },
                {
                    id  : 5,
                    name: 'potentielle Stylistin'
                },
            ],
            stylistFilter         : {},
            //temp tags
            tags: [],

        };
    },
    mounted()
    {
        let tmp = [
            {
                name      : 'Alle',
                sw_user_id: -1
            }
        ]
        // let _tmp           = Object.assign(this.filterStylists, tmp)
        // this.stylistFilter = _tmp

        this.stylistFilter = this.filterStylists
        this.stylistFilter.unshift({
                                       name      : 'Alle',
                                       sw_user_id: -1
                                   })
    },
    methods: {

        // getProjects(url = '/products', page) {
        getProjects(page = 1)
        {
            let url             = '/getCustomers';
            this.tableData.page = page;
            this.tableData.draw++;
            axios.get(url, {params: this.tableData})
                .then(response => {
                    let data = response.data;
                    if (this.tableData.draw == data.draw) {
                        this.rows = data.data.data;
                        this.configPagination(data.data);
                        this.pagi = response.data.data;
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
        configPagination(data)
        {
            this.pagination.lastPage    = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total       = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from        = data.from;
            this.pagination.to          = data.to;
        },
        sortBy(key)
        {
            console.log('sort');
            this.sortKey          = key;
            this.sortOrders[key]  = this.sortOrders[key] * -1;
            this.tableData.column = this.getIndex(this.columns, 'name', key);
            this.tableData.dir    = this.sortOrders[key] === 1 ? 'asc' : 'desc';
            this.getProjects();
        },
        getIndex(array, key, value)
        {
            return array.findIndex(i => i[key] == value);
        },
        viewButton(id)
        {
            console.log(id);
            window.location.href = '/backend/customer/view/' + id;
        },

        addTags(tags)
        {
            this.tableData.filter.tags = tags;
            this.tags                  = tags;
        },
        userExport()
        {
            console.log('export btn')

            axios.post('customers/export', {
                filter           : this.tableData.filter,
                customerExportIds: this.checked
            })
                .then((response) => {
                    this.downloadFile('user-export.xlsx')
                    this.checked = []
                })
                .catch((error) => {
                    console.log('export error')
                    this.$vToastify.error({
                                              title: 'Fehler',
                                              body : '',
                                          });
                })
        },
        downloadFile(file)
        {
            axios.get('/storage/' + file, {responseType: 'arraybuffer'}).then(res => {
                let blob      = new Blob([res.data], {type: 'application/*'})
                let link      = document.createElement('a')
                link.href     = window.URL.createObjectURL(blob)
                link.download = file
                link.click();
            })
        },
        deleteCustomer(id)
        {
            this.$vToastify.setSettings({
                                            backdrop    : 'rgba(0, 0, 0, 0.2)',
                                            withBackdrop: true,
                                            position    : 'center-center',
                                        })
            this.$vToastify.prompt({
                                       body   : "Wirklich löschen?",
                                       mode   : "prompt",
                                       answers: {
                                           Ja  : true,
                                           Nein: false
                                       }
                                   }).then(value => {
                if (value) {

                    console.log('/customer/delete/' + id)
                    axios
                        // .post('/backend/test/delete/' + id, {
                        .post('customer/delete', {
                            customerID: id
                        })
                        .then((response) => {
                            this.getProjects()

                            this.$vToastify.success({
                                                        title: 'Gelöscht',
                                                        body : ''
                                                    })
                            // document.location.href = '/backend/calendar'
                        })
                        .catch((error) => {
                            // error = JSON.parse(error)
                            console.log(error.response.data.message)
                            this.$vToastify.error({
                                                      title: 'Löschen',
                                                      body : 'Kann nicht gelöscht werden: Stylistin, Aufgaben, Partys oder/und Bestellungen liegen vor!'
                                                  })
                            // console.log('error: ' + JSON.stringify(error, null, 2))
                        })
                }
            })
        },

        debounceSearch: _.debounce(function () {
            console.log('search');
            this.getProjects();
        }, 500)
    }

};
</script>
<style lang="sass" scoped>
#customer-list
    td
        cursor: pointer
</style>
