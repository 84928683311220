<template>
    <div class="border rounded shadow" id="search_div" :class="{show: visible, hidden: !visible}" style="position: absolute; top: 100%; left: 0px; background-color: #fff; width: 100%; z-index: 999">
        <table class="table table-hover search-table-result" id="search_table">
            <tr>
                <th v-for="headerField in headerFields">{{headerField}}</th>
            </tr>
            <tr v-if="Object.keys(columnData).length > 0" v-for="row in columnData" :key="row.id" :id="row.id" @click="trSelect(row)" class="selectable-row">
                <td v-for="item in columns">
                    <span v-if="item === 'price'">{{row[item]|toCurrency}}</span>
                    <span v-else>{{row[item]}}</span>
                </td>
            </tr>
            <tr v-else>
                <td colspan="3" class="selectable-row">
                    <div class="text-center">Nichts da</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
	export default {
		name   : "ProductSearchTable",
		props  : {
			headerFields: {},
			columnData  : {},
			visible     : false,
			columns     : {},
		},
		mounted() {
		},
		methods: {
			trSelect(row) {
				// this.visible = false;
				this.$emit('tr-select', row);
			}
		}
	};
</script>

<style scoped>
    #search_div {
        max-height: 325px;
        overflow: auto;
    }
</style>
