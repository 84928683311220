var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.client
    ? _c("nav", { staticClass: "pagination" }, [
        _c("span", { staticClass: "page-stats" }, [
          _vm._v(
            _vm._s(_vm.pagination.from) +
              " - " +
              _vm._s(_vm.pagination.to) +
              " of " +
              _vm._s(_vm.pagination.total)
          )
        ]),
        _vm._v(" "),
        _vm.pagination.prevPageUrl
          ? _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-previous",
                on: {
                  click: function($event) {
                    return _vm.$emit("prev")
                  }
                }
              },
              [_vm._v("\n        Prev\n    ")]
            )
          : _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-previous",
                attrs: { disabled: true }
              },
              [_vm._v("\n       Prev\n    ")]
            ),
        _vm._v(" "),
        _vm.pagination.nextPageUrl
          ? _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-next",
                on: {
                  click: function($event) {
                    return _vm.$emit("next")
                  }
                }
              },
              [_vm._v("\n        Next\n    ")]
            )
          : _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-next",
                attrs: { disabled: true }
              },
              [_vm._v("\n        Next\n    ")]
            )
      ])
    : _c("nav", { staticClass: "pagination" }, [
        _c("span", { staticClass: "page-stats" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.pagination.from) +
              " - " +
              _vm._s(_vm.pagination.to) +
              " of " +
              _vm._s(_vm.filtered.length) +
              "\n        "
          ),
          _vm.filtered.length < _vm.pagination.total
            ? _c("span", [
                _vm._v(
                  "(filtered from " +
                    _vm._s(_vm.pagination.total) +
                    " total entries)"
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.pagination.prevPage
          ? _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-previous",
                on: {
                  click: function($event) {
                    return _vm.$emit("prev")
                  }
                }
              },
              [_vm._v("\n        Prev\n    ")]
            )
          : _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-previous",
                attrs: { disabled: true }
              },
              [_vm._v("\n       Prev\n    ")]
            ),
        _vm._v(" "),
        _vm.pagination.nextPage
          ? _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-next",
                on: {
                  click: function($event) {
                    return _vm.$emit("next")
                  }
                }
              },
              [_vm._v("\n        Next\n    ")]
            )
          : _c(
              "a",
              {
                staticClass: "btn btn-dark is-small pagination-next",
                attrs: { disabled: true }
              },
              [_vm._v("\n        Next\n    ")]
            )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }