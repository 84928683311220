<template>
    <section id="create-user">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-12 col-md-6">

                    <div class="card">
                        <div class="card-header">
                            &nbsp;
                        </div>
                        <div class="card-body">

                            <div class="form-header-text">Step 1</div>
                            <div class="form-row">
                                <div class="form-group col-12 col-md-12">
                                    <label>{{ __('settings.users.create.search_host.label') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.sw_user_id}" type="text" class="form-control" @input="debounceSearch" v-model="searchHostValue">
                                    <!--                                    <small class="form-text text-muted">{{__('settings.users.create.search_host.small')}}</small>-->
                                    <div v-if="validateErrors && validateErrors.sw_user_id" class="invalid-feedback">{{ validateErrors.sw_user_id[0] }}</div>
                                    <search-table @tr-select="selectedUser" :headerFields="headerFields" :columnData="columnData" :visible="showResult"></search-table>
                                </div>
                            </div>

                            <div class="form-row mb-3">
                                <div class="col-12 col-md-6">
                                    <span v-if="user.sw_user_id>0"><i class="fas fa-check" style="color: #00ff00"></i> {{ __('settings.users.create.shopware') }}</span>
                                    <span v-if="user.sw_user_id==0"><i class="fas fa-times" style="color: #ff0000"></i> {{ __('settings.users.create.shopware') }}</span>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label>{{ __('settings.users.create.name') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.name}" class="form-control" type="text" v-model="user.name">
                                    <div v-if="validateErrors && validateErrors.name" class="invalid-feedback">{{ validateErrors.name[0] }}</div>
                                </div>
                            </div>

                            <div class="form-row">

                                <div class="form-group col-12 col-md-6">
                                    <label>{{ __('settings.users.create.email') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.email}" class="form-control" type="text" v-model="user.email">
                                    <div v-if="validateErrors && validateErrors.email" class="invalid-feedback">{{ validateErrors.email[0] }}</div>
                                </div>

                                <div class="form-group col-12 col-md-6">
                                    <label>{{ __('settings.users.create.password') }}</label>
                                    <div class="input-group">
                                        <input :class="{ 'is-invalid': validateErrors.password}" type="password" class="form-control" v-model="user.password" id="password">
                                        <div class="input-group-append">
                                            <span v-if="showPassword" class="input-group-text"><i @click="togglePass" class="far fa-eye"></i></span>
                                            <span v-if="!showPassword" class="input-group-text"><i @click="togglePass" class="far fa-eye-slash"></i></span>
                                        </div>
                                    </div>
                                    <div v-if="validateErrors && validateErrors.password" class="invalid-feedback">{{ validateErrors.password[0] }}</div>
                                </div>

                            </div>


                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label>Roles</label>
                                    <select :class="{ 'is-invalid': validateErrors.roles}" class="form-control" v-model="user.roles" multiple>
                                        <option v-for="role in roles" :value="role.id">{{ role.name }}</option>
                                    </select>
                                    <div v-if="validateErrors && validateErrors.roles" class="invalid-feedback">{{ validateErrors.roles[0] }}</div>
                                </div>
                            </div>

                            <div class="form-header-text">Shopware URL</div>

                            <div class="form-row">

                                <div class="form-group col-12 col-md-6">
                                    <label>{{ __('settings.users.create.stylist_url.label') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.stylist_url}" type="text" class="form-control" v-model="user.stylist_url">
                                    <div v-if="validateErrors && validateErrors.stylist_url" class="invalid-feedback">{{ validateErrors.stylist_url[0] }}</div>
                                    <small class="form-text text-muted">style-boss.de/{{ user.stylist_url }}</small>

                                </div>

                                <div class="form-group col-12 col-md-6">
                                    <label>{{ __('settings.users.create.stylist_name.label') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.stylist_name}" type="text" class="form-control" v-model="user.stylist_name">
                                    <div v-if="validateErrors && validateErrors.stylist_name" class="invalid-feedback">{{ validateErrors.stylist_name[0] }}</div>
                                </div>
                            </div>


                            <div class="form-row">
                                <div class="form-group col-12 col-lg-3">
                                    <label>Prozente: Kauf</label>
                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.percent_buy}" class="form-control" v-model="user.percent_buy">
                                </div>
                            </div>


<!--                            <div class="form-header-text">Provision</div>-->
<!--                            <div class="form-row">-->

<!--                                <div class="form-group col-12 col-lg-3">-->
<!--                                    <label>Provision: Silber</label>-->
<!--                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.percent_silver}" class="form-control" v-model="user.percent_silver">-->
<!--                                </div>-->

<!--                                <div class="form-group col-12 col-lg-3">-->
<!--                                    <label>Provision: Silber ab</label>-->
<!--                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.percent_silver_over_price}" class="form-control" v-model="user.percent_silver_over_price">-->
<!--                                </div>-->

<!--                                <div class="form-group col-12 col-lg-3">-->
<!--                                    <label>Provision: Silber über</label>-->
<!--                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.percent_silver_over}" class="form-control" v-model="user.percent_silver_over">-->
<!--                                </div>-->

<!--                                <div class="form-group col-12 col-lg-3">-->
<!--                                    <label>Provision: Gold</label>-->
<!--                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.percent_gold}" class="form-control" v-model="user.percent_gold">-->
<!--                                </div>-->

<!--                            </div>-->

                            <div class="form-header-text">BISHER ERREICHTER MONATSUMSATZ</div>
                            <label>Dashboard: Balken 1 und 2</label>
                            <div class="form-row">
                                <div class="form-group col-12 col-lg-3">
                                    <label>Monatsziel 1</label>
                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.objectives_month_1}" class="form-control" v-model="user.objectives_month_1">
                                </div>

                                <div class="form-group col-12 col-lg-3">
                                    <label>Monatsziel 2</label>
                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.objectives_month_2}" class="form-control" v-model="user.objectives_month_2">
                                </div>

                            </div>

                            <div class="form-header-text">BISHER ERREICHTES QUARTALSZIEL</div>
                            <label>Dashboard: Quartalsziel / 3 (Balken 1, 2, 3)</label>
                            <div class="form-row">
                                <div class="form-group col-12 col-lg-3">
                                    <label>Quartalsziel</label>
                                    <input type="number" max="100" min="0" :class="{'is_invalid': validateErrors.objectives_quarter}" class="form-control" v-model="user.objectives_quarter">
                                </div>
                            </div>

                            <hr class="w-100">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <avatar-upload :avatar="user.avatar" @image-file-selected="imageSelected"></avatar-upload>
                                </div>
                            </div>

                        </div>
                        <div class="card-footer text-right">
                            &nbsp;<button class="btn btn-light" @click="saveUser">{{ __('settings.users.create.buttons.save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name : "CreateUser",
    props:
        ['userProps'],
    mounted()
    {
        if (typeof this.$props.userProps.edit != 'undefined') {
            this.user = this.$props.userProps;
        }
    },
    created()
    {
    },
    data()
    {
        return {
            user           : {
                userId                   : null,
                name                     : '',
                email                    : '',
                password                 : '',
                sw_user_id               : 0,
                roles                    : [],
                stylist_url              : null,
                stylist_name             : null,
                edit                     : false,
                avatar                   : null,
                percent_silver_over_price: 3500,
                percent_silver           : 25,
                percent_silver_over      : 30,
                percent_gold             : 20,
                percent_buy              : 30,
                objectives_month_1       : 3500,
                objectives_month_2       : 7500,
                objectives_quarter       : 15000
            },
            roles          : [
                {
                    id  : 'admin',
                    name: 'Admin'
                },
                {
                    id  : 'stylist',
                    name: 'Stylistin'
                }

            ],
            searchHostValue: '',
            debounce       : null,
            showResult     : false,
            showPassword   : false,
            headerFields   : [
                'Kundennummer',
                'Name',
                'Stadt',
                'E-Mail',
            ],
            columnData     : [],
            validateErrors : {},
        };
    },
    methods: {
        togglePass: function () {
            this.showPassword = !this.showPassword;
            let passInput     = document.querySelector('#password');
            (this.showPassword) ? passInput.setAttribute('type', 'text') : passInput.setAttribute('type', 'password');
        },
        searchHost()
        {
            let _self = this;
            axios
                .post('/backend/customerSearchAxios', {
                    searchKey: _self.searchHostValue,
                })
                .then((response) => {
                    _self.columnData = response.data;
                    _self.showResult = true;
                })
                .catch((error) => {
                    console.log('error');
                });
        },
        saveUser()
        {
            let _self = this;
            axios.post('saveUserAxios', _self.user)
                .then(result => {
                    _self.showToast('Gespeichert', '', 'success');
                }).catch(error => {
                if (error.response.status === 422) {
                    _self.validateErrors = error.response.data.errors || {};
                }
                _self.showToast('Fehler', 'Benutzer konnte nicht gespeichert werden!', 'error');
            });
        },
        selectedUser(row)
        {
            this.showResult        = false;
            this.searchHostValue   = '';
            this.user.name         = row.firstname + ' ' + row.lastname;
            this.user.email        = row.email;
            this.user.sw_user_id   = row.id;
            // this.user.sw_user_id   = row.userID;
            this.user.stylist_url  = row.firstname.toLowerCase();
            this.user.stylist_name = row.firstname;
        },
        showToast(title, body, type)
        {
            if (type === 'success') {
                this.$vToastify.success({
                                            title   : title,
                                            body    : body,
                                            callback: () => {
                                                if (this.edit === true) {
                                                    window.location.href = 'backend/parties';
                                                }
                                            }
                                        });
            }

            if (type === 'error') {
                this.$vToastify.error({
                                          title: title,
                                          body : body,
                                      });
            }
        },
        imageSelected(image)
        {
            this.user.avatar = image;
        },
        debounceSearch: _.debounce(function () {
            this.searchHost();
        }, 500)
    }
};
</script>

<style scoped>

</style>
