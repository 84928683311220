var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [_vm._v("Provision")]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-row" }, [
                _vm.is("admin")
                  ? _c("div", { staticClass: "col-12 col-md-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Benutzer")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sw_user_id,
                                expression: "sw_user_id"
                              }
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.sw_user_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.users, function(item) {
                            return _c(
                              "option",
                              { domProps: { value: item.sw_user_id } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-3" },
                  [
                    _c("year-drop-down", {
                      attrs: { "drop-down-label": "Jahr" },
                      on: { "dd-year-changed": _vm.yearChanged }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.loaded
                    ? _c("bar-chart", {
                        staticStyle: { "max-height": "300px" },
                        attrs: {
                          chartData: _vm.chartData,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "60px 0" } }),
              _vm._v(" "),
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.commission, function(item) {
                    return _c("tr", [
                      _c("td", [_vm._v(_vm._s(item.month))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(item.party_orders_sum_net)
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(item.parties_commission))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(item.online_orders_sale))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(item.online_commission))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(item.crm_orders_sale))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(item.crm_commission))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("toCurrency")(item.sales_sum)))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(item.commission_sum))
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer" })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Monat")]),
        _vm._v(" "),
        _c("th", [_vm._v("Party: Umsatz")]),
        _vm._v(" "),
        _c("th", [_vm._v("Party: Provision")]),
        _vm._v(" "),
        _c("th", [_vm._v("Online: Umsatz")]),
        _vm._v(" "),
        _c("th", [_vm._v("Online: Provision")]),
        _vm._v(" "),
        _c("th", [_vm._v("CRM: Umsatz")]),
        _vm._v(" "),
        _c("th", [_vm._v("CRM: Provision")]),
        _vm._v(" "),
        _c("th", [_vm._v("Gesamtumsatz")]),
        _vm._v(" "),
        _c("th", [_vm._v("Provision")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }