var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [_vm._v("Provision")]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-row" }, [
                _vm.is("admin")
                  ? _c("div", { staticClass: "col-12 col-md-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Benutzer")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sw_user_id,
                                expression: "sw_user_id"
                              }
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.sw_user_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.users, function(item) {
                            return _c(
                              "option",
                              { domProps: { value: item.sw_user_id } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-3" },
                  [
                    _c("year-drop-down", {
                      attrs: { "drop-down-label": "Jahr" },
                      on: { "dd-year-changed": _vm.yearChanged }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.loaded
                    ? _c("bar-chart", {
                        staticStyle: { "max-height": "300px" },
                        attrs: {
                          chartData: _vm.chartData,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "60px 0" } }),
              _vm._v(" "),
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.commission, function(item) {
                    return _c("tr", [
                      _c("td", [_vm._v(_vm._s(item.month))]),
                      _vm._v(" "),
                      _c("td", [
                        Object.keys(item.parties).length > 0
                          ? _c("table", { staticClass: "table" }, [
                              _vm._m(1, true),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                [
                                  _vm._l(item.parties, function(party) {
                                    return [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(" " + _vm._s(party.party_name))
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  party.party_date_time
                                                )
                                              )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(party.host.firstname) +
                                              " " +
                                              _vm._s(party.host.lastname)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    party.host_party_sale_full
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    party.host_buy_sum
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    party.host_commission_temp
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    party.host_commission
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  })
                                ],
                                2
                              )
                            ])
                          : _c("div", [_vm._v("Keine Partys")])
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer" })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Monat")]),
        _vm._v(" "),
        _c("th", [_vm._v("Party")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", { staticStyle: { width: "10%" } }, [_vm._v("Party")]),
        _vm._v(" "),
        _c("td", [_vm._v("Datum")]),
        _vm._v(" "),
        _c("td", [_vm._v("Gastgeberin")]),
        _vm._v(" "),
        _c("td", { staticClass: "text-right", staticStyle: { width: "10%" } }, [
          _vm._v("GU")
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "text-right", staticStyle: { width: "10%" } }, [
          _vm._v("Umsatz Gastgeberin")
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "text-right", staticStyle: { width: "10%" } }, [
          _vm._v("Prämie")
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "text-right", staticStyle: { width: "10%" } }, [
          _vm._v("Prämie noch")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }