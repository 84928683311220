<template>
    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6">

                    <div class="card">
                        <div class="card-header">{{__('event.event')}}</div>
                        <div class="card-body">

                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label>{{ __('event.subject') }}</label>
                                    <select class="form-control" v-model="event.event_type">
                                        <option v-for="eventType in eventTypes" :value="eventType.id">{{ eventType.name }}</option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-row" v-if="showSearchFiled">
                                <div class="form-group col-12 col-md-12">
                                    <label>{{ __('event.search.label') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.host_id}" type="text" class="form-control" @input="debounceSearch" v-model="searchHostValue">
                                    <small>{{ __('event.search.small') }}</small>

                                    <search-table @tr-select="selectedUser" :headerFields="headerFields" :columnData="columnData" :visible="showResult"></search-table>

                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label>{{ __('event.selected_customer') }}</label>
                                    <div>{{ sw_user.firstname }} {{ sw_user.lastname }} {{ sw_user.s_user_default_billing_addresses.city }}</div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label>{{ __('event.description') }}</label>
                                    <textarea :class="{ 'is-invalid': validateErrors.description}" class="form-control" v-model="event.description"></textarea>
                                    <div v-if="validateErrors && validateErrors.description" class="invalid-feedback">{{ validateErrors.description[0] }}</div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label>{{ __('event.note') }}</label>
                                    <textarea :class="{ 'is-invalid': validateErrors.note}" class="form-control" v-model="event.note"></textarea>
                                    <div v-if="validateErrors && validateErrors.note" class="invalid-feedback">{{ validateErrors.note[0] }}</div>
                                </div>
                            </div>


                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label>{{ __('event.start_date') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.start_date}" class="form-control" type="date" v-model="event.start_date">
                                    <div v-if="validateErrors && validateErrors.start_date" class="invalid-feedback">{{ validateErrors.start_date[0] }}</div>
                                </div>
                                <div class="form-group col-6">
                                    <label>{{ __('event.start_time') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.start_time}" class="form-control" type="time" v-model="event.start_time">
                                    <div v-if="validateErrors && validateErrors.start_time" class="invalid-feedback">{{ validateErrors.start_time[0] }}</div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" v-model="event.recall">
                                        <label class="form-check-label">{{ __('event.recall') }}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row" v-if="event.recall">
                                <div class="form-group col-6">
                                    <label>{{ __('event.recall_date') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.recall_date}" class="form-control" type="date" v-model="event.recall_date">
                                    <div v-if="validateErrors && validateErrors.recall_date" class="invalid-feedback">{{ validateErrors.recall_date[0] }}</div>
                                </div>
                                <div class="form-group col-6">
                                    <label>{{ __('event.recall_time') }}</label>
                                    <input :class="{ 'is-invalid': validateErrors.recall_time}" class="form-control" type="time" v-model="event.recall_time">
                                    <div v-if="validateErrors && validateErrors.recall_time" class="invalid-feedback">{{ validateErrors.recall_time[0] }}</div>
                                </div>
                            </div>

                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-light" @click="saveEvent">{{ __('event.buttons.save') }}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>


</template>

<script>
export default {
    name   : "EventCreate",
    props  : {
        eventTypes   : {
            // type   : Array,
            // require: true
        },
        customerId   : {
            type   : Number,
            default: 0,
        },
        customerProps: {},
    },
    data() {
        return {
            event          : {
                event_type : 1,
                public_type: false,
                stylist_id : null,
                s_user_id  : null,
                start_date : null,
                start_time : null,
                // start_date_time: null,
                // end_date       : null,
                // end_time       : null,
                // end_date_time  : null,
                description: '',
                recall     : false,
                recall_date: null,
                recall_time: null,
                note       : null,

            },
            validateErrors : {},
            searchHostValue: '',
            debounce       : null,
            showResult     : false,
            headerFields   : [
                'Kundennummer',
                'Name',
                'Stadt',
                'E-Mail',
            ],
            columnData     : [],
            sw_user        : {
                s_user_default_billing_addresses: {
                    city: ''
                }
            },
            showSearchFiled: true
        };
    },
    mounted() {
        if (this.$props.customerProps !== null) {
            this.event.s_user_id = this.$props.customerProps.id;
            this.sw_user         = this.$props.customerProps;
            this.showSearchFiled = false;
        } else {
        }

    },
    methods: {
        saveEvent() {
            let _self = this;
            axios
                .post('/backend/events/save', _self.event)
                .then((response) => {
                    let eventReset = {
                        event_type : 1,
                        public_type: false,
                        stylist_id : null,
                        s_user_id  : null,
                        start_date : null,
                        start_time : null,
                        description: '',
                        recall     : false,
                        recall_date: null,
                        recall_time: null,
                        note       : null,
                        sw_user    : {
                            s_user_default_billing_addresses: {
                                city: ''
                            }
                        }
                    };

                    _self.validateErrors = {};
                    _self.event          = eventReset;
                    _self.showToast('Aufgabe', 'Gespeichert', 'success');

                })
                .catch(error => {
                    console.log('error');
                    if (error.response.status === 422) {
                        _self.validateErrors = error.response.data.errors || {};
                        _self.showToast('Aufgabe', 'Fehler', 'error');
                    }
                });
        },
        showToast(title, body, type) {

            if (type === 'success') {
                this.$vToastify.success({
                                            title   : title,
                                            body    : body,
                                            callback: () => {
                                                if (this.customerProps !== null) {
                                                    window.location.href = '/backend/customer/view/' + this.customerProps.id;
                                                } else {
                                                }

                                            }
                                        });
            }

            if (type === 'error') {
                this.$vToastify.error({
                                          title: title,
                                          body : body,
                                      });
            }
        },
        searchHost() {
            let _self = this;
            axios
                .post('/backend/customerSearchAxios', {
                    searchKey: _self.searchHostValue,
                })
                .then((response) => {
                    _self.columnData = response.data;
                    _self.showResult = true;
                })
                .catch((error) => {
                    console.log('error');
                });
        },
        selectedUser(row) {
            // this.hostData        = row;
            this.searchHostValue = '';
            this.showResult      = false;
            this.event.s_user_id = row.userID;
            this.sw_user         = row;
        },
        debounceSearch: _.debounce(function () {
            this.searchHost();
        }),
    },
};
</script>

<style scoped>

</style>
