<template>
    <section id="create-order">

        <div class="container">
            <div class="row">
                <div class="col-5">
                    <!--CUSTOMER CARD-->
                    <div class="card">
                        <div class="card-header">&nbsp;</div>
                        <div class="card-body">
                            <div>
                                <div class="form-row">
                                    <div class="col-12">
                                        <!--PARTY SELECT-->
                                        <label>{{ __('order.create.input.search_party.label') }}</label>
                                        <select class="form-control" v-model="customer.partyId" @change="partyChanged">
                                            <option value=0></option>
                                            <option v-for="party in parties" :value="party.id">{{ party.party_date_time|formatDateTime }} {{ party.party_name }} {{ party.host.firstname }} {{ party.host.lastname }} Kd.Nr.: {{ party.host.customernumber }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-row mt-3">
                                    <div class="form-group col-12">
                                        <label>{{ __('order.create.input.search_customer.label') }}</label>
                                        <input :class="{ 'is-invalid': validateErrors.host_id}" type="text" class="form-control" @input="debounceSearch" v-model="searchHostValue">
                                        <small>{{ __('order.create.input.search_customer.small') }}</small>
                                        <search-table @tr-select="selectedUser" :headerFields="headerFields" :columnData="columnData" :visible="showResult"></search-table>
                                    </div>
                                </div>

                                <!--addresses-->
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active form-header-text" id="billing-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ __('order.tabs.billingAddress') }}</a>
                                    </li>
                                    <li class="nav-item">
                                        <!--                                        <a :class="customer.address_billing_eq_shipping ? 'disabled': ''" class="nav-link form-header-text" id="shipping-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" :aria-disabled="customer.address_billing_eq_shipping">{{ __('order.tabs.shippingAddress') }}</a>-->
                                        <!--                                                                                <a class="nav-link form-header-text" id="shipping-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{{ __('order.tabs.shippingAddress') }}</a>-->
                                        <a class="nav-link form-header-text disabled" id="shipping-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{{ __('order.tabs.shippingAddress') }}</a>
                                        <!--                                        <a :class="customer.address_billing_eq_shipping ? 'disabled': ''" class="nav-link form-header-text" id="shipping-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" :aria-disabled="customer.address_billing_eq_shipping">{{ __('order.tabs.shippingAddress') }}</a>-->
                                    </li>
                                </ul>

                                <div class="tab-content" id="myTabContent">

                                    <!--billing address-->
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="billing-tab">

                                        <div class="form-row mt-3">
                                            <div class="form-group col-md-3">
                                                <label for="customer_salutation">{{ __('customer.salutation') }}</label>
                                                <select class="form-control" id="customer_salutation" name="salutation" v-model="customer.salutation">
                                                    <option v-for="item in salutation" :value="item.value">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="firstname">{{ __('customer.firstname') }}</label>
                                                <input :class="{ 'is-invalid': validateErrors['customer.firstname']}" type="text" class="form-control" id="firstname" name="firstname" value="" v-model="customer.firstname">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.firstname']" class="invalid-feedback">{{ validateErrors['customer.firstname'][0] }}</div>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="lastname">{{ __('customer.lastname') }}</label>
                                                <input :class="{ 'is-invalid': validateErrors['customer.lastname']}" type="text" class="form-control" id="lastname" name="lastname" value="" v-model="customer.lastname">
                                                <div v-if="validateErrors && validateErrors['customer.lastname']" class="invalid-feedback">{{ validateErrors['customer.lastname'][0] }}</div>
                                            </div>

                                            <div class="form-group col-12">
                                                <label>{{ __('order.additional_address_line1') }}</label>
                                                <input class="form-control" type="text" v-model="customer.billing.additional_address_line1">
                                            </div>

                                            <div class="form-group col-6 col-md-6">
                                                <label for="email">{{ __('customer.email_address') }}</label>
                                                <input :class="{ 'is-invalid': validateErrors['customer.email']}" type="text" class="form-control" id="email" name="email" value="" v-model="customer.email">
                                                <div v-if="validateErrors && validateErrors['customer.email']" class="invalid-feedback">{{ validateErrors['customer.email'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-6">
                                                <label for="birthday">{{ __('customer.birthday') }}</label>
                                                <input type="date" :class="{ 'is-invalid': validateErrors.birthday}" id="birthday" class="form-control" value="" v-model="customer.birthday">
                                                <div v-if="validateErrors && validateErrors.birthday" class="invalid-feedback">{{ validateErrors.birthday[0] }}</div>
                                            </div>

                                        </div>

                                        <div class="form-row">

                                            <div class="form-group col-6 col-md-5">
                                                <label for="billing_street">{{ __('customer.street') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.billing.street']}" class="form-control" id="billing_street" value="" v-model="customer.billing.street">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.billing.street']" class="invalid-feedback">{{ validateErrors['customer.billing.street'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-2">
                                                <label for="billing_zipcode">{{ __('customer.zipcode') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.billing.zipcode']}" class="form-control" id="billing_zipcode" value="" v-model="customer.billing.zipcode">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.billing.zipcode']" class="invalid-feedback">{{ validateErrors['customer.billing.zipcode'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-5">
                                                <label for="billing_city">{{ __('customer.city') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.billing.city']}" class="form-control" id="billing_city" value="" v-model="customer.billing.city">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.billing.city']" class="invalid-feedback">{{ validateErrors['customer.billing.city'][0] }}</div>
                                            </div>

                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-6 col-md-6">
                                                <label for="billing_phone">{{ __('customer.phone') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['billing.phone']}" class="form-control" id="billing_phone" value="" v-model="customer.billing.phone">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['billing.phone']" class="invalid-feedback">{{ validateErrors['billing.phone'][0] }}</div>
                                            </div>
                                        </div>


                                        <!--                                        ###############-->
                                        <div class="form-row">

                                            <div class="form-header-text pl-1">{{ __('order.rolle') }}</div>
                                            <div class="form-group col-12 col-md-12">
                                                <div v-for="type in customerTypeRadio" class="form-check form-check-inline" :key="type.value">
                                                    <input type="radio" :id="'customRadio' + type.value" class="form-check-input" :value="type.value" v-model="customer.customerType">
                                                    <!-- <input :disabled="type === 4 && customer.partyId === '0' " type="radio" :id="'customRadio' + type.value" class="form-check-input" :value="type.value" v-model="customer.customerType">-->
                                                    <label class="form-check-label" :for="'customRadio'+type.value">{{ type.name }}</label>
                                                </div>
                                            </div>

                                        </div>

                                        <hr class="w-100">

                                        <div class="form-row mt-1">
                                            <div class="form-header-text pl-1">{{ __('order.send_to') }}</div>
                                            <div class="form-group col-12 col-md-12">
                                                <div v-for="type in sendToRadio" class="form-check form-check-inline" :key="type.value">
                                                    <input :disabled="type.value == 1 && customer.partyId == 0" type="radio" :id="'customRadio1' + type.value" class="form-check-input" :value="type.value" v-model="customer.sendTo" @change="orderSum">
                                                    <label class="form-check-label" :for="'customRadio1'+type.value">{{ type.name }}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row mt-1" v-if="customer.sendTo === 0">
                                            <div class="form-group col">
                                                <div class="form-check">
<!--                                                    <input class="form-check-input" type="checkbox" id="defaultCheck1" v-model="customer.address_billing_eq_shipping" @change="eqChanged">-->
                                                    <input class="form-check-input" type="checkbox" id="defaultCheck1" v-model="customer.address_billing_eq_shipping">
                                                    <label class="form-check-label" for="defaultCheck1">{{ __('customer.billingEqShipping') }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <!--                                        ###############-->


                                    </div>

                                    <!-- shippingAddress-->
                                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="shipping-tab">

                                        <div class="form-row mt-3">
                                            <div class="form-group col-md-3">
                                                <label for="shipping_customer_salutation">{{ __('customer.salutation') }}</label>
                                                <select class="form-control" id="shipping_customer_salutation" name="salutation" v-model="customer.shipping.salutation">
                                                    <option v-for="item in salutation" :value="item.value">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-6 col-md-6">
                                                <label for="shipping_firstname">{{ __('customer.firstname') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.shipping.firstname']}" class="form-control" id="shipping_firstname" value="" v-model="customer.shipping.firstname">
                                                <div v-if="validateErrors && validateErrors['customer.shipping.firstname']" class="invalid-feedback">{{ validateErrors['customer.shipping.firstname'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-6">
                                                <label for="shipping_lastname">{{ __('customer.lastname') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.shipping.lastname']}" class="form-control" id="shipping_lastname" value="" v-model="customer.shipping.lastname">
                                                <div v-if="validateErrors && validateErrors['customer.shipping.lastname']" class="invalid-feedback">{{ validateErrors['customer.shipping.lastname'][0] }}</div>
                                            </div>
                                        </div>

                                        <div class="form-row">

                                            <div class="form-group col-12">
                                                <label>{{ __('order.create.additional_address_line1') }}</label>
                                                <input class="form-control" type="text" v-model="customer.shipping.additional_address_line1">
                                            </div>

                                            <div class="form-group col-6 col-md-5">
                                                <label for="shipping_street">{{ __('customer.street') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.shipping.street']}" class="form-control" id="shipping_street" value="" v-model="customer.shipping.street">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.shipping.street']" class="invalid-feedback">{{ validateErrors['customer.shipping.street'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-2">
                                                <label for="shipping_zipcode">{{ __('customer.zipcode') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.shipping.zipcode']}" class="form-control" id="shipping_zipcode" value="" v-model="customer.shipping.zipcode">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.shipping.zipcode']" class="invalid-feedback">{{ validateErrors['customer.shipping.zipcode'][0] }}</div>
                                            </div>

                                            <div class="form-group col-6 col-md-5">
                                                <label for="shipping_city">{{ __('customer.city') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.shipping.city']}" class="form-control" id="shipping_city" value="" v-model="customer.shipping.city">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.shipping.city']" class="invalid-feedback">{{ validateErrors['customer.shipping.city'][0] }}</div>
                                            </div>

                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-6 col-md-6">
                                                <label for="shipping_phone">{{ __('customer.phone') }}</label>
                                                <input type="text" :class="{ 'is-invalid': validateErrors['customer.shipping.phone']}" class="form-control" id="shipping_phone" value="" v-model="customer.shipping.phone">
                                                <div class="invalid-feedback"></div>
                                                <div v-if="validateErrors && validateErrors['customer.shipping.phone']" class="invalid-feedback">{{ validateErrors['customer.shipping.phone'][0] }}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!--tabs end-->

                                <!--tags-->
                                <hr class="w-100">
                                <div class="form-header-text">{{ __('order.tags') }}</div>
                                <div class="form-row">
                                    <div class="col-12">
                                        <show-tags :tags="tags" :checked-tags="tagsChecked" @tag-checked="addTags"></show-tags>
                                    </div>
                                </div>
                                <!--tags-->

                                <hr class="w-100">

                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label for="internalComment">{{ __('customer.internalComment') }}</label>
                                        <textarea class="form-control" name="" id="internalComment" cols="30" rows="3" v-model="customer.internalComment"></textarea>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="card-footer"></div>
                    </div>
                    <!--CUSTOMER CARD-->

                </div>
                <!--ORDER CARD-->
                <div class="col-7">
                    <div class="card h-100">
                        <div class="card-header text-right">
                            <button v-if="!waitForSave" class="btn btn-light btn-sm" @click="saveOrder">{{ __('order.buttons.save') }}</button>
                            <div v-else class="btn btn-light btn-sm">Speichern...</div>
                        </div>
                        <div class="card-body">

                            <div class="form-row">

                                <div class="col-12 col-md-6">

                                    <label>{{ __('order.create.payment') }}</label>
                                    <select class="form-control" v-model="customer.payment">
                                        <option v-for="payment in allowedPayment" :value="payment.id">{{ payment.description }}</option>
                                    </select>
                                </div>

                                <div class="col-12 col-md-6">
                                    <label>Versand</label>
                                    <select class="form-control" v-model="customer.dispatchId"  @change="orderSum">
                                        <option v-for="dispatchItem in dispatch" :value="dispatchItem.id">{{ dispatchItem.name }}</option>
                                    </select>
                                </div>


                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="internalComment">{{ __('order.internalComment') }}</label>
                                    <textarea class="form-control" cols="30" rows="2" v-model="orderInternalComment"></textarea>
                                </div>
                            </div>

                            <nav>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link form-header-text active" id="nav-order-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">{{ __('order.tabs.order') }}</a>
                                    <a class="nav-item nav-link form-header-text" id="nav-order-party-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{{ __('order.tabs.order_party') }}</a>
                                </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                <!--ORDER TAB-->
                                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-order-tab">

                                    <product-search @selectedRowData="selectedArticle"></product-search>
                                    <div :class="'validateErrors.orderData ? show'" v-if="validateErrors && validateErrors.orderData" class="invalid-feedback">{{ validateErrors.orderData[0] }}</div>

                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>Art.Nr</th>
                                            <th>Name</th>
                                            <th>Variante</th>
                                            <th>Preis</th>
                                            <th>Menge</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(orderItem, keyX, indexX) in orderData">
                                            <td v-for="(item, key, index2) in  orderItem">
                                                <span v-if="key == 'price'">{{ item|toCurrency }}</span>
                                                <span v-else-if="key === 'quantity'">
                                                   <input class="form-control input-order-quantity form-control-sm" type="number" min="1" v-model="orderItem.quantity" @change="orderSum">
                                                    <!--                                                    <vue-number-input @change="orderSum" v-model="orderItem.quantity" :min="1" :max="100" size="small" center inline controls></vue-number-input>-->
                                               </span>
                                                <!--                                                <span v-else>{{ item }}</span>-->
                                                <span v-else-if="key !== 'id'">{{ item }}</span>
                                            </td>
                                            <td>
                                                <button class="btn btn-light btn-sm" :key="keyX" @click="removeOrderItem(keyX)">-</button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="table">
                                        <tbody>
                                        <tr>
                                            <td class="text-right"><b>{{ __('order.sum') }}</b></td>
                                            <td width="100px">{{ this.orderItemSum.itemSum|toCurrency }}</td>
                                        </tr>
<!--                                        <tr v-if="orderItemSum.itemSum < 70 && customer.sendTo === 0">-->
                                        <tr v-if="orderItemSum.itemSum < 70 && customer.sendTo === 0 && customer.dispatchId !== 12">
                                            <td class="text-right">{{ __('order.shipping') }}</td>
                                            <td>{{ this.orderItemSum.shipping|toCurrency }}</td>
                                        </tr>
                                        <tr v-else>
                                            <td class="text-right">{{ __('order.shipping') }}</td>
                                            <td>0,00 €</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right"><b>{{ __('order.total') }}</b></td>
                                            <td>{{ orderItemSum.total|toCurrency }}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>

                                <!--PARTY ORDER TAB-->

                                <div v-if="partyOrderDetails" class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-order-party-tab">
                                    <!--                                    <party-order-details :party-order-details="{{$partyOrderDetailsJson}}" :state="{{$scoreStateJson}}" :show-order-button="true"></party-order-details>-->

                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div class="my-2">
                                                    <!--                                                    {{ commission.party_sale|toCurrency }}<br>-->
                                                    {{ ((commission.host - commission.host_temp) + commission.host_buy_sum + commission.party_sale)|toCurrency }}<br>
                                                    <label>{{ __('party.sales') }}</label>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <div class="my-2">
                                                    {{ commission.host_temp|toCurrency }}<br>
                                                    <label>{{ __('party.host_commission_temp') }}</label>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <div class="my-2">
                                                    {{ commission.host|toCurrency }}<br>
                                                    <label>{{ __('party.host_commission') }}</label>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <div class="my-2">
                                                    <!--                                                    {{ commission.stylist * laravel_tax|toCurrency }}<br>-->
                                                    {{ commission.stylist|toCurrency }}<br>
                                                    <label>{{ __('party.stylist_commission') }}</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <party-order-details :partyOrderDetails=partyOrderDetails :state="state"></party-order-details>
                                </div>
                                <div v-else class="form-header-text">{{ __('order.details.no-items') }}</div>

                                <!--PARTY ORDER TAB-->

                            </div>

                        </div>
                        <div class="card-footer text-right">
                            <button v-if="!waitForSave" class="btn btn-light" @click="saveOrder">{{ __('order.buttons.save') }}</button>
                            <div v-else class="btn btn-light">Speichern...</div>
                        </div>
                    </div>
                </div>
                <!--ORDER CARD-->
            </div>


        </div>

        <event-modal
            :event-types="eventTypes"
            :show-modal="showModal"
            @event-modal-data="modalEvenData"
            @event-close-button="modelCloseButtonClick"
        ></event-modal>


    </section>
</template>

<script>
export default {
    name : "CreateOrder",
    props: {
        parties       : {},
        state         : {},
        tags          : {},
        allowedPayment: {},
        eventTypes    : {},
        dispatch      : {},
    },
    data()
    {
        return {
            showModal      : false,
            party_id       : 0,
            searchHostValue: '',
            debounce       : null,
            showResult     : false,
            //s_user_id: null,
            headerFields     : [
                'Kundennummer',
                'Name',
                'Stadt',
                'E-Mail',
            ],
            columnData       : [],
            customer         : {
                salutation     : 'ms',
                firstname      : '',
                lastname       : '',
                email          : '',
                birthday       : '',
                internalComment: '',
                newsletter     : false,
                payment        : 15,

                billing                    : {
                    salutation              : 'ms',
                    firstname               : '',
                    lastname                : '',
                    additional_address_line1: '',
                    street                  : '',
                    zipcode                 : '',
                    city                    : '',
                    country                 : 2,
                    phone                   : '',
                },
                shipping                   : {
                    salutation              : 'ms',
                    firstname               : '',
                    lastname                : '',
                    additional_address_line1: '',
                    street                  : '',
                    zipcode                 : '',
                    city                    : '',
                    country                 : 2,
                    phone                   : '',
                },
                s_user_id                  : null,
                customerType               : 0,
                sendTo                     : 0,
                address_billing_eq_shipping: true,
                partyId                    : 0,
                dispatchId                 : 9,
                tags                       : {},

            },
            sendToRadio      : [
                {
                    value: 1,
                    name : 'an Gastgeberin'
                },
                {
                    value: 0,
                    name : 'an Kundin'
                },

                {
                    value: 2,
                    name : 'an Stylistin'
                },

            ],
            customerTypeRadio: [
                {
                    value: 0,
                    name : 'Kundin',
                },
                // {
                // 	value: 1,
                // 	name : 'Stylistin',
                // },
                // {
                // 	value: 2,
                // 	name : 'Gastgeberin',
                // },
                // {
                // 	value: 3,
                // 	name : 'Lead',
                // },
                {
                    value: 4,
                    // /name : 'mögliche Gastgeberin',

                    name: window._translations.de.php.order.is_possible_host
                },

                {
                    value: 5,
                    // name : 'mögliche Stylistin',
                    name: window._translations.de.php.order.is_possible_stylist
                },
            ],
            salutation       : [
                {
                    value: 'ms',
                    name : 'Frau',
                },
                {
                    value: 'mr',
                    name : 'Herr',
                },
            ],

            validateErrors      : {
                customer: {
                    billing : {},
                    shipping: {},
                },

            },
            tagsChecked         : [],
            tag                 : '',
            value               : [],
            orderData           : [],
            orderInternalComment: '',
            orderItemSum        : {
                itemSum : 0,
                shipping: 5.90,
                total   : 0,
            },
            partyOrderDetails   : [],
            calendarEventData   : null,
            commission          : {
                stylist        : 0,
                host           : 0,
                host_temp      : 0,
                party_sale_full: 0,
                party_sale     : 0,
                host_buy_sum   : 0,

            },
            waitForSave         : false
        };
    },
    mounted()
    {
        document.addEventListener('click', this.documentClick);
    },
    methods: {
        addTags(tags)
        {
            this.tagsChecked = tags;
        },
        removeOrderItem(index)
        {
            this.orderData.splice(index, 1);
            this.orderSum();
        },
        documentClick()
        {
            if (this.showResult === true) {
                this.showResult = false;
            }
        },
        searchHost()
        {
            let _self = this;
            axios
                .post('/backend/customerSearchAxios', {
                    searchKey: _self.searchHostValue,
                })
                .then((response) => {
                    _self.columnData = response.data;
                    _self.showResult = true;
                })
                .catch((error) => {
                    console.log('error');
                });
        },
        selectedUser(row)
        {
            this.searchHostValue    = '';
            this.showResult         = false;
            this.customer.s_user_id = row.userID;
            // this.customer.s_user_id = row.id;
            //this.customer = row;

            this.customer.firstname       = row.firstname;
            this.customer.lastname        = row.lastname;
            this.customer.salutation      = row.salutation;
            this.customer.email           = row.email;
            // this.customer.birthday        = moment(row.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD');
            this.customer.newsletter      = row.newsletter;
            this.customer.internalComment = row.internalcomment;
            this.customer.payment         = row.paymentID;

            this.customer.billing  = row.s_user_default_billing_addresses;
            this.customer.shipping = row.s_user_default_shipping_addresses;

            if (row.birthday !== null) {
                this.customer.birthday = moment(row.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD');
            } else {
                this.customer.birthday = null;
            }

            delete this.customer.billing.id;
            delete this.customer.shipping.id;

            if (JSON.stringify(this.customer.billing) !== JSON.stringify(this.customer.shipping)) {
                this.customer.address_billing_eq_shipping = false;
            }

//TAGS
            this.tagsChecked = [];
            for (let index in row.tags) {
                this.tagsChecked.push(row.tags[index].name.de);
            }

            if (row.is_stylist) this.customer.customerType = 1;
            if (row.is_host) this.customer.customerType = 2;
            if (row.is_lead) this.customer.customerType = 3;
            if (row.is_possible_host) this.customer.customerType = 4;
            if (row.is_possible_stylist) this.customer.customerType = 5;


        },
        selectedArticle(row)
        {
            this.orderData.push({
                                    ordernumber: row.ordernumber,
                                    name       : row.name,
                                    attribute  : row.attributeDescription,
                                    price      : row.price,
                                    quantity   : 1,
                                    //id         : row.id,
                                    id: row.articleID,
                                });
            this.orderSum();
        },
        orderSum()
        {
            console.log('orderSum: dispatchId: ' + this.customer.dispatchId)
            let sum = 0;

            for (let index = 0; index < this.orderData.length; index++) {
                sum = sum + this.orderData[index].price * this.orderData[index].quantity;
            }

            this.orderItemSum.itemSum = sum;
            // if (sum < 70 && this.customer.sendTo === 0) {
            if (sum < 70 && this.customer.sendTo === 0 && this.customer.dispatchId !== 12) {
                this.orderItemSum.total = sum + this.orderItemSum.shipping;
            } else {
                this.orderItemSum.total = sum;
            }
        },
        saveOrder()
        {
            let loginURL = window.location.protocol + '//' + window.location.host + '/login'
            if (this.waitForSave) {
                return
            }

            let _self                         = this;
            this.validateErrors               = {};
            this.customer.billing.firstname   = this.customer.firstname;
            this.customer.billing.lastname    = this.customer.lastname;
            _self.customer.billing.salutation = _self.customer.salutation;
            this.customer.tags                = this.tagsChecked;
            this.waitForSave                  = true;

            axios
                .post('/backend/order/saveAxios', {
                    customer          : _self.customer,
                    orderData         : _self.orderData,
                    orderInternalOrder: _self.orderInternalComment,
                    calendarEventData : _self.calendarEventData,
                })
                .then((response) => {
                    _self.getPartyOrderDetails();
                    _self.resetData();
                    _self.showToast('Bestellung', 'Bestellung: ' + response.data.data.ordernumber + ' gespeichert', 'success');
                })
                .catch((error) => {
                    console.log('error');
                    if (error.response.status === 422) {
                        _self.showToast('Bestellung', 'Bestellung konnte nicht gespeichert werden', 'error');
                        _self.validateErrors = error.response.data.errors || {};
                    }
                    if (error.response.status === 419) {
                        let loginURL    = window.location.protocol + '//' + window.location.host + '/login'
                        // loginURL        = 'http://localhost:3000/login'
                        window.location = loginURL
                    }
                })
                .then(function () {
                    _self.waitForSave = false
                });
        },
        partyChanged()
        {
            if (this.customer.partyId == 0 && this.customer.sendTo != 0) {
                this.customer.sendTo = 0;
            }
            if (this.customer.partyId != 0) {
                this.customer.sendTo = 1;
                this.getPartyOrderDetails();
            }
            if (this.customer.partyId == 0) {
                this.getPartyOrderDetails();
            }


        },
        getPartyOrderDetails()
        {
            let _self = this;
            axios
                .post('/backend/party/orderDetails', {
                    partyId: _self.customer.partyId
                })
                .then((response) => {
                    _self.partyOrderDetails = response.data;
                })
                .catch((error) => {
                    console.log('error');
                })
            ;
        },
        debounceSearch: _.debounce(function () {
            this.searchHost();
        }),
        resetData()
        {
            // let resetCustomer = Object.assign(this.customer, customer);
            this.customer             = {
                salutation     : 'ms',
                firstname      : '',
                lastname       : '',
                email          : '',
                birthday       : '',
                internalComment: '',
                newsletter     : false,
                payment        : 5,

                billing     : {
                    salutation              : 'ms',
                    firstname               : '',
                    lastname                : '',
                    additional_address_line1: '',
                    street                  : '',
                    zipcode                 : '',
                    city                    : '',
                    country                 : 2,
                    phone                   : '',
                },
                shipping    : {
                    salutation              : 'ms',
                    firstname               : '',
                    lastname                : '',
                    additional_address_line1: '',
                    street                  : '',
                    zipcode                 : '',
                    city                    : '',
                    country                 : 2,
                    phone                   : '',
                },
                s_user_id   : null,
                customerType: 0,

                address_billing_eq_shipping: true,
                partyId                    : this.customer.partyId,
                sendTo                     : this.customer.sendTo,
                dispatchId                 : this.customer.dispatchId,
                // partyId: 0,
                tags: {},

            };
            this.orderData            = [];
            this.orderInternalComment = '';
            this.calendarEventData    = null;
            this.orderItemSum         = {
                itemSum : 0,
                shipping: 5.90,
                total   : 0,
            };
            this.tagsChecked          = [];

        },
        showToast(title, body, type)
        {

            if (type === 'success') {
                this.$vToastify.success({
                                            title: title,
                                            body : body,
                                        });
            }

            if (type === 'error') {
                this.$vToastify.error({
                                          title: title,
                                          body : body,
                                      });
            }
        },
        modalEvenData(data)
        {
            this.calendarEventData = data;
            this.showModal         = false;
        },
        modelCloseButtonClick(data)
        {
            this.showModal = data;
        },
    },
    watch  : {
        'customer.customerType'               : function (val) {
            // if (val !== 0) {
            if (val === 4 || val === 5) {
                this.showModal = true;
            } else {
                this.showModal = false;
            }
        },
        'customer.address_billing_eq_shipping': function (val, old) {
            if (val) {
                $('#billing-tab').tab('show');
                $('#shipping-tab').addClass('disabled');
            } else {
                $('#shipping-tab').removeClass('disabled');
                $('#shipping-tab').tab('show');
            }
        },
        'partyOrderDetails'                   : function ($val) {
            if (this.partyOrderDetails.length > 0) {
                this.commission.stylist         = this.partyOrderDetails[0].s_order_attributes.order_party.stylist_commission;
                this.commission.host            = this.partyOrderDetails[0].s_order_attributes.order_party.host_commission;
                this.commission.host_temp       = this.partyOrderDetails[0].s_order_attributes.order_party.host_commission_temp;
                this.commission.party_sale_full = this.partyOrderDetails[0].s_order_attributes.order_party.host_party_sale_full;
                this.commission.party_sale      = this.partyOrderDetails[0].s_order_attributes.order_party.host_party_sale;
                this.commission.host_buy_sum    = this.partyOrderDetails[0].s_order_attributes.order_party.host_buy_sum;
            } else {
                this.commission.stylist         = 0;
                this.commission.host            = 0;
                this.commission.host_temp       = 0;
                this.commission.party_sale_full = 0;
                this.commission.party_sale      = 0;
                this.commission.host_buy_sum    = 0;
            }

        }

    }
};
</script>

<style scoped>
.input-order-quantity {
    width: 80px;
}

.number-input__input {
    font-size: 11px !important;
}


</style>
