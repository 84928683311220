var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-row mt-3" }, [
      _c(
        "div",
        { staticClass: "form-group col-12" },
        [
          _c("label", [
            _vm._v(_vm._s(_vm.__("order.create.input.search_article.label")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchValue,
                expression: "searchValue"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.searchValue },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchValue = $event.target.value
                },
                _vm.debounceSearch
              ]
            }
          }),
          _vm._v(" "),
          _c("small", [
            _vm._v(_vm._s(_vm.__("order.create.input.search_article.small")))
          ]),
          _vm._v(" "),
          _c("product-search-table", {
            attrs: {
              headerFields: _vm.headerFields,
              columnData: _vm.columnData,
              columns: _vm.columns,
              visible: _vm.showResult
            },
            on: { "tr-select": _vm.selectedRowData }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }