<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">Provision</div>
                        <div class="card-body">

                            <div class="form-row">
                                <div v-if="is('admin')" class="col-12 col-md-3">
                                    <div class="form-group">
                                        <label>Benutzer</label>
                                        <select class="form-control" v-model="sw_user_id">
                                            <option v-for="item in users" :value="item.sw_user_id">{{ item.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-md-3">
                                    <year-drop-down :drop-down-label="'Jahr'" @dd-year-changed="yearChanged"></year-drop-down>
                                </div>

                            </div>

                            <div>
                                <bar-chart v-if="loaded" :chartData="chartData" :options="chartOptions" style="max-height: 300px"></bar-chart>
                            </div>

                            <div style="margin: 60px 0"></div>

                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Monat</th>
                                    <th>Party</th>
                                </tr>
                                </thead>
                                <tbody>

                                <tr v-for="item in commission">
                                    <td>{{ item.month }}</td>
                                    <td>
                                        <!--                                        {{Object.keys(item.parties).length}}-->

                                        <table class="table" v-if="Object.keys(item.parties).length > 0">
                                            <thead>
                                            <tr>
                                                <td style="width: 10%">Party</td>
                                                <td>Datum</td>
                                                <td>Gastgeberin</td>
                                                <td class="text-right" style="width: 10%">GU</td>
                                                <td class="text-right" style="width: 10%">Umsatz Gastgeberin</td>
                                                <td class="text-right" style="width: 10%">Prämie</td>
                                                <td class="text-right" style="width: 10%">Prämie noch</td>
<!--                                                <td class="text-right" style="width: 10%">Provision</td>-->
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="party in item.parties">

                                                <tr>
                                                    <td> {{ party.party_name }}</td>
                                                    <td> {{ party.party_date_time|formatDateTime }}</td>
                                                    <td> {{ party.host.firstname }} {{ party.host.lastname }}</td>
                                                    <td class="text-right"> {{ party.host_party_sale_full|toCurrency }}</td>
                                                    <td class="text-right"> {{ party.host_buy_sum|toCurrency }}</td>
                                                    <td class="text-right"> {{ party.host_commission_temp|toCurrency }}</td>
                                                    <td class="text-right"> {{ party.host_commission|toCurrency }}</td>
<!--                                                    <td class="text-right"> {{ party.stylist_commission|toCurrency }}</td>-->
                                                </tr>

                                            </template>
                                            </tbody>
                                        </table>
                                        <div v-else>Keine Partys</div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer"></div>
                    </div>

                </div>
            </div>
        </div>


    </section>
</template>

<script>
import BarCharts from "./Charts/BarCharts";

export default {
    // name : 'StatisticsCommission',
    props: {
        users                 : {
            type   : Array,
            default: []
        },
        currentBackendUserSwId: {
            type   : Number,
            default: 5

        }
    },
    data()
    {
        return {
            sw_user_id  : this.currentBackendUserSwId,
            loaded      : false,
            chartHeader : {},
            commission  : {},
            dataArray   : [],
            chartData   : {
                labels  : [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember'
                ],
                datasets: [
                    {
                        label          : 'Provision',
                        backgroundColor: '#ff0000',
                        data           : this.dataArray
                    },
                ]
            },
            chartOptions: {
                responsive         : true,
                maintainAspectRatio: false,
                scales             : {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                },
                animation          : {
                    duration: 0
                }
            },
            selectedYear: null
        }
    },
    mounted()
    {
        this.getData()
    },
    methods: {
        yearChanged(year)
        {
            this.selectedYear = year
        },
        getData(stylistId = null)
        {
            axios
                .post('/backend/statistics/getStatisticsPartiesAxios', {
                    stylistId: stylistId !== null ? stylistId : null,
                    year     : this.selectedYear
                })
                .then((response) => {
                    this.commission = response.data.commission.data;
                    this.partySalesSum()


                    // let dataArray = []
                    // for (let item in this.commission) {
                    //     dataArray.push(this.commission[item]['commission_sum'].toFixed(2))
                    // }
                    // this.chartData.datasets[0].data = dataArray
                    // this.chartData                  = Object.assign({}, this.chartData)
                    // this.loaded                     = true
                })
                .catch((error) => {
                    console.log('error' + JSON.stringify(error, null, 2))
                })
        },
        partySalesSum()
        {
            let stylistArray   = []
            let hostArray      = []
            let partySaleArray = []

            for (let item in this.commission) {
                if (this.commission[item].parties.length > 0) {
                    let stylist   = 0,
                        host      = 0,
                        partySale = 0
                    for (let party in this.commission[item].parties) {
                        stylist += this.commission[item].parties[party].stylist_commission
                        host += this.commission[item].parties[party].host_commission_temp
                        partySale += this.commission[item].parties[party].host_party_sale_full
                    }
                    stylistArray.push(stylist)
                    // hostArray.push(this.commission[item].parties.length)
                    hostArray.push(host)
                    partySaleArray.push(partySale)
                } else {
                    stylistArray.push(0)
                    hostArray.push(0)
                    partySaleArray.push(0)
                }
            }
            this.chartData.datasets[0].data = stylistArray
            this.chartData.datasets[1]      = {
                label          : 'G. Prämie',
                backgroundColor: '#0000ff',
                data           : hostArray

            }
            this.chartData.datasets[2]      = {
                label          : 'Umsatz',
                backgroundColor: '#00ff00',
                data           : partySaleArray

            }
            this.chartData                  = Object.assign({}, this.chartData)
            this.loaded                     = true
        }

    },
    watch  : {
        'sw_user_id'  : function (nv, ov) {
            this.getData(nv)
        },
        'selectedYear': function (nv, ov) {
            this.getData(this.sw_user_id)
        }
    }

}
</script>
<style scoped lang="sass">
canvas
    max-height: 400px
</style>
