<template>
    <div class="projects">
        <div class="tableFilters">
            <div class="form-row">
                <div class="form-group col-12 col-md-3">
                    <label for="price_from">Preis ab</label>
                    <input class="form-control" id="price_from" type="text" v-model="tableData.filter.price.from">
                </div>
                <div class="form-group col-12 col-md-3">
                    <label for="price_to">Preis_bis</label>
                    <input class="form-control" id="price_to" type="text" v-model="tableData.filter.price.to">
                </div>
            </div>

            <!--            <input class="form-control" v-on:input="test" type="text" v-model="tableData.search" placeholder="Search Table" @input="getProjects()">-->
            <input class="form-control" type="text" v-model="tableData.search" placeholder="Search Table" @input="debounceSearch">
            <!--######-->

            <button class="btn btn-success" @click="getProjects">click</button>
            <!--######-->
            <div class="control">
                <div class="select">
                    <select class="form-control" v-model="tableData.length" @change="getProjects()">
                        <option v-for="(records, index) in perPage" :key="index" :value="records">{{records}}</option>
                    </select>
                </div>
            </div>
        </div>

        <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
            <tbody>
            <tr v-for="project in projects" :key="project.id">
                <td>
                    <input type="checkbox" :value="project.id" v-model="checked">
                </td>
                <td>{{project.sku}}</td>
                <td>{{project.name}}</td>
                <td>{{project.price | toCurrency}}</td>
                <td>
                    <button class="btn" @click="buttonTest(project.id)"><i class="far fa-edit"></i></button>
                </td>
            </tr>
            </tbody>
        </datatable>

        <laravel-vue-pagination :data="pagi" @pagination-change-page="getProjects"></laravel-vue-pagination>

    </div>
</template>

<script>
    import Datatable from './Datatable.vue';
    import Pagination from './Pagination.vue';

    export default {
        components: {datatable: Datatable, pagination: Pagination},
        created()
        {
            this.getProjects();
        },
        data()
        {
            let sortOrders = {};

            let columns = [
                {width: '', label: '', name: '', sortable: false},
                {width: '', label: 'SKU', name: 'sku', sortable: true},
                {width: '', label: 'name', name: 'name', sortable: true},
                {width: '', label: 'Price', name: 'price', sortable: true},
                {width: '', label: 'Actions', name: 'actions', sortable: false},
            ];

            columns.forEach((column) => {
                sortOrders[column.name] = -1;
            });
            return {
                projects: [],
                columns: columns,
                sortKey: 'deadline',
                sortOrders: sortOrders,
                perPage: [
                    '10',
                    '20',
                    '30'
                ],
                tableData: {
                    draw: 0,
                    length: 10,
                    search: '',
                    column: 0,
                    dir: 'desc',
                    page: 1,
                    filter: {
                        price: {}
                    }
                },
                pagination: {
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },
                pagi: {},
                checked: [],
            }
        },
        methods: {

            // getProjects(url = '/products', page) {
            getProjects(page = 1)
            {
                let url = '/products';
                this.tableData.page = page;
                this.tableData.draw++;
                axios.get(url, {params: this.tableData})
                    .then(response => {
                        let data = response.data;
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                            this.pagi = response.data.data
                        }
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
            },
            configPagination(data)
            {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key)
            {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getProjects();
            },
            getIndex(array, key, value)
            {
                return array.findIndex(i => i[key] == value)
            },
            buttonTest(item)
            {

            },
            debounceSearch: _.debounce(function () {
                this.getProjects()
            }, 500)
        }

    };
</script>
