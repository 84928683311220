<template>
    <div class="projects">

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="searchField">{{ __('article.search.label') }}</label>
                    <input class="form-control" type="text" v-model="tableData.search" placeholder="" @input="debounceSearch" id="searchField">
                    <small id="searchFieldHelp" class="form-text text-muted">{{ __('article.search.small') }}</small>
                </div>
            </div>

            <div class="col-6 text-right">
                <div class="form-group">
                    <label class="text-right" for="searchField">{{ __('datatable.items_per_page') }}</label>
                    <div class="items_per_page ">
                        <select class="form-control" v-model="tableData.length" @change="getArticles()">
                            <option v-for="(records, index) in perPage" :key="index" :value="records">{{ records }}</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>

        <!--filter-->
        <div class="row">
            <div class="col-12">
                <div class="form-header-text">Filter</div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-2">
                <div class="form-group">
                    <label>{{ __('datatable.filter.instock_type') }}</label>
                    <select class="form-control" v-model="tableData.filter.articleInstockFilter">
                        <option v-for="articleFilterType in articlesTypeFilterData" :value="articleFilterType.id">{{ articleFilterType.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-lg-2">
                <div class="form-group">
                    <div>&nbsp;</div>
                    <button class="btn btn-light" @click="getArticles">Filtern</button>
                    <button class="btn btn-light" @click="articleExport">Export</button>
                </div>
            </div>
        </div>


        <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" :table-hover="true" @sort="sortBy">
            <tbody>
            <tr v-for="row in rows" :key="row.id">
                <td>
                    <input type="checkbox" :value="row.id" v-model="checked">
                </td>
                <td @click="viewButton(row.ordernumber)">{{ row.ordernumber }}</td>
                <td @click="viewButton(row.ordernumber)">{{ row.at_name }} {{ row.variant_desc }}</td>
                <td @click="viewButton(row.ordernumber)">{{ row.price * 1.19|toCurrency }}</td>
                <td @click="viewButton(row.ordernumber)">
                    <span v-if="row.instock <= 1"><i class="fas fa-circle" style="color: red"></i></span>
                    <span v-if="row.instock > 1 && row.instock <= 4"><i class="fas fa-circle" style="color: orange"></i></span>
                    <span v-if="row.instock >= 5"><i class="fas fa-circle" style="color: green"></i></span>
                    {{ row.instock }}
                </td>
                <td class="table--action-buttons">
                    <a class="" :href="'/backend/article/view/' + row.ordernumber"><i class="far fa-eye"></i></a>
                </td>
            </tr>
            </tbody>
        </datatable>
        <laravel-vue-pagination :limit="5" :data="pagi" @pagination-change-page="getArticles"></laravel-vue-pagination>

    </div>
</template>

<script>
import Datatable  from "../module/Datatable";
import Pagination from "../module/Pagination";

export default {
    name      : 'article-list',
    components: {
        datatable : Datatable,
        pagination: Pagination
    },
    props     : {
        columns: {
            require: true,
        },

    },
    created()
    {
        this.columns.forEach((column) => {
            this.sortOrders[column.name] = -1;
        });

        this.getArticles();
    },
    data()
    {
        let sortOrders = {};
        return {
            rows                  : [],
            sortKey               : 'deadline',
            sortOrders            : sortOrders,
            perPage               : [
                '10',
                '20',
                '30'
            ],
            tableData             : {
                draw  : 0,
                length: 10,
                search: '',
                column: 0,
                dir   : 'desc',
                page  : 1,
                filter: {
                    articleInstockFilter: -1
                }
            },
            pagination            : {
                lastPage   : '',
                currentPage: '',
                total      : '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from       : '',
                to         : ''
            },
            pagi                  : {},
            checked               : [],
            articlesTypeFilterData: [
                {
                    id  : -1,
                    name: 'Alle'
                },
                {
                    id  : 0,
                    name: '1 bis 10'
                },
                {
                    id  : 1,
                    name: '0 und weniger'
                },

            ],
        };
    },
    methods: {

        // getArticles(url = '/products', page) {
        getArticles(page = 1)
        {
            let url             = '/backend/articles';
            this.tableData.page = page;
            this.tableData.draw++;
            axios.get(url, {params: this.tableData})
                .then(response => {
                    let data = response.data;
                    if (this.tableData.draw == data.draw) {
                        this.rows = data.data.data;
                        this.configPagination(data.data);
                        this.pagi = response.data.data;
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
        articleExport()
        {
            axios.post('articles/export', {
                filter          : this.tableData.filter,
                articleExportIds: this.checked
            })
                .then((response) => {
                    this.downloadFile('artikel-export.xlsx')
                    this.checked = []
                })
                .catch((error) => {
                    console.log('export error')
                    this.$vToastify.error({
                                              title: 'Fehler',
                                              body : '',
                                          });
                })
        },
        downloadFile(file)
        {
            axios.get('/storage/' + file, {responseType: 'arraybuffer'}).then(res => {
                let blob      = new Blob([res.data], {type: 'application/*'})
                let link      = document.createElement('a')
                link.href     = window.URL.createObjectURL(blob)
                link.download = file
                link.click();
            })
        },
        configPagination(data)
        {
            this.pagination.lastPage    = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total       = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from        = data.from;
            this.pagination.to          = data.to;
        },
        sortBy(key)
        {
            this.sortKey          = key;
            this.sortOrders[key]  = this.sortOrders[key] * -1;
            this.tableData.column = this.getIndex(this.columns, 'name', key);
            this.tableData.dir    = this.sortOrders[key] === 1 ? 'asc' : 'desc';
            this.getArticles();
        },
        getIndex(array, key, value)
        {
            return array.findIndex(i => i[key] == value);
        },
        buttonTest(item)
        {

        },
        viewButton(id)
        {
            window.location.href = '/backend/article/view/' + id;
        },
        debounceSearch: _.debounce(function () {
            this.getArticles();
        }, 500)
    }

};
</script>















