<template>
    <div class="border rounded shadow" id="search_div" :class="{show: visible, hidden: !visible}" style="position: absolute; top: 100%; left: 0px; background-color: #fff; width: 100%; z-index: 999">
        <table class="table table-hover search-table-result" id="search_table">
            <tr>
                <th v-for="headerField in headerFields">{{headerField}}</th>
            </tr>
            <tr v-if="columnData" v-for="row in columnData" :key="row.id" :id="row.id" @click="trSelect(row)" class="selectable-row">
                <td>{{row.customernumber}}</td>
                <td>{{row.firstname}} {{row.lastname}}</td>
                <td>{{row.s_user_default_billing_addresses.city}}</td>
                <td>{{row.email}}</td>
            </tr>
            <tr v-else>
                <td colspan="3" class="selectable-row">
                    <div class="text-center">Benutzer nicht gefunden</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
	export default {
		name   : "SearchTable",
		props  : {
			headerFields: {},
			columnData  : {},
			visible     : false,
		},
		methods: {
			trSelect(row) {
				// this.visible = false;
                console.log(row)
				this.$emit('tr-select', row);
			}
		}
	};
</script>

<style scoped>

</style>
