var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", { attrs: { id: "customer_create" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(
                    "\n                             \n                        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "nav nav-tabs",
                      attrs: { id: "myTab", role: "tablist" }
                    },
                    [
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link active form-header-text",
                            attrs: {
                              id: "billing-tab",
                              "data-toggle": "tab",
                              href: "#home",
                              role: "tab",
                              "aria-controls": "home",
                              "aria-selected": "true"
                            }
                          },
                          [_vm._v(_vm._s(_vm.__("customer.billingAddress")))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link form-header-text disabled",
                            attrs: {
                              id: "shipping-tab",
                              "data-toggle": "tab",
                              href: "#profile",
                              role: "tab",
                              "aria-controls": "profile",
                              "aria-selected": "false",
                              "aria-disabled":
                                _vm.customer.address_billing_eq_shipping
                            }
                          },
                          [_vm._v(_vm._s(_vm.__("customer.shippingAddress")))]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      attrs: { id: "myTabContent" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade show active",
                          attrs: {
                            id: "home",
                            role: "tabpanel",
                            "aria-labelledby": "billing-tab"
                          }
                        },
                        [
                          _c("div", { staticClass: "form-row mt-3" }, [
                            _c("div", { staticClass: "form-group col-md-3" }, [
                              _c(
                                "label",
                                { attrs: { for: "customer_salutation" } },
                                [_vm._v(_vm._s(_vm.__("customer.salutation")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.salutation,
                                      expression: "customer.salutation"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "customer_salutation",
                                    name: "salutation"
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.customer,
                                        "salutation",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.salutation, function(item) {
                                  return _c(
                                    "option",
                                    { domProps: { value: item.value } },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c("label", { attrs: { for: "firstname" } }, [
                                _vm._v(_vm._s(_vm.__("customer.firstname")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customer.firstname,
                                    expression: "customer.firstname"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.validateErrors.firstname
                                },
                                attrs: {
                                  type: "text",
                                  id: "firstname",
                                  name: "firstname",
                                  value: ""
                                },
                                domProps: { value: _vm.customer.firstname },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customer,
                                      "firstname",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "invalid-feedback" }),
                              _vm._v(" "),
                              _vm.validateErrors && _vm.validateErrors.firstname
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.validateErrors.firstname[0])
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c("label", { attrs: { for: "lastname" } }, [
                                _vm._v(_vm._s(_vm.__("customer.lastname")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customer.lastname,
                                    expression: "customer.lastname"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.validateErrors.lastname
                                },
                                attrs: {
                                  type: "text",
                                  id: "lastname",
                                  name: "lastname",
                                  value: ""
                                },
                                domProps: { value: _vm.customer.lastname },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customer,
                                      "lastname",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.validateErrors && _vm.validateErrors.lastname
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.validateErrors.lastname[0])
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c("label", { attrs: { for: "email" } }, [
                                  _vm._v(
                                    _vm._s(_vm.__("customer.email_address"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.email,
                                      expression: "customer.email"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.validateErrors.email
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "email",
                                    name: "email",
                                    value: ""
                                  },
                                  domProps: { value: _vm.customer.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.validateErrors && _vm.validateErrors.email
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.validateErrors.email[0])
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c("label", { attrs: { for: "birthday" } }, [
                                  _vm._v(_vm._s(_vm.__("customer.birthday")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.birthday,
                                      expression: "customer.birthday"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.validateErrors.birthday
                                  },
                                  attrs: {
                                    type: "date",
                                    id: "birthday",
                                    value: ""
                                  },
                                  domProps: { value: _vm.customer.birthday },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer,
                                        "birthday",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors.birthday
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.validateErrors.birthday[0])
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_street" } },
                                  [_vm._v(_vm._s(_vm.__("customer.street")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.street,
                                      expression: "customer.billing.street"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["billing.street"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_street",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.street
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "street",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["billing.street"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "billing.street"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-2" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_zipcode" } },
                                  [_vm._v(_vm._s(_vm.__("customer.zipcode")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.zipcode,
                                      expression: "customer.billing.zipcode"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["billing.zipcode"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_zipcode",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.zipcode
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "zipcode",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["billing.zipcode"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "billing.zipcode"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_city" } },
                                  [_vm._v(_vm._s(_vm.__("customer.city")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.city,
                                      expression: "customer.billing.city"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["billing.city"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_city",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.city
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "city",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["billing.city"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "billing.city"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_phone" } },
                                  [_vm._v(_vm._s(_vm.__("customer.phone")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.phone,
                                      expression: "customer.billing.phone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["billing.phone"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_phone",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.phone
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["billing.phone"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "billing.phone"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-12" }, [
                              _c(
                                "label",
                                { attrs: { for: "internalComment" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.__("customer.internalComment"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customer.internalComment,
                                    expression: "customer.internalComment"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "",
                                  id: "internalComment",
                                  cols: "30",
                                  rows: "3"
                                },
                                domProps: {
                                  value: _vm.customer.internalComment
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customer,
                                      "internalComment",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row mt-4" }, [
                            _c("div", { staticClass: "form-group col" }, [
                              _c("div", { staticClass: "form-check" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.customer
                                          .address_billing_eq_shipping,
                                      expression:
                                        "customer.address_billing_eq_shipping"
                                    }
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    type: "checkbox",
                                    id: "defaultCheck1"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.customer.address_billing_eq_shipping
                                    )
                                      ? _vm._i(
                                          _vm.customer
                                            .address_billing_eq_shipping,
                                          null
                                        ) > -1
                                      : _vm.customer.address_billing_eq_shipping
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.customer
                                            .address_billing_eq_shipping,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.customer,
                                              "address_billing_eq_shipping",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.customer,
                                              "address_billing_eq_shipping",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.customer,
                                          "address_billing_eq_shipping",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: { for: "defaultCheck1" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.__("customer.billingEqShipping")
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: {
                            id: "profile",
                            role: "tabpanel",
                            "aria-labelledby": "shipping-tab"
                          }
                        },
                        [
                          _c("div", { staticClass: "form-row mt-3" }, [
                            _c("div", { staticClass: "form-group col-md-3" }, [
                              _c(
                                "label",
                                {
                                  attrs: { for: "shipping_customer_salutation" }
                                },
                                [_vm._v(_vm._s(_vm.__("shipping.salutation")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.salutation,
                                      expression: "customer.shipping.salutation"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "shipping_customer_salutation",
                                    name: "salutation"
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "salutation",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.salutation, function(item) {
                                  return _c(
                                    "option",
                                    { domProps: { value: item.value } },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_firstname" } },
                                  [_vm._v(_vm._s(_vm.__("customer.firstname")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.firstname,
                                      expression: "customer.shipping.firstname"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["shipping.firstname"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_firstname",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.firstname
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "firstname",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_lastname" } },
                                  [_vm._v(_vm._s(_vm.__("customer.lastname")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.lastname,
                                      expression: "customer.shipping.lastname"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["shipping.lastname"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_lastname",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.lastname
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "lastname",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_street" } },
                                  [_vm._v(_vm._s(_vm.__("customer.street")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.street,
                                      expression: "customer.shipping.street"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["shipping.street"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_street",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.street
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "street",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["shipping.street"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "shipping.street"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-2" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_zipcode" } },
                                  [_vm._v(_vm._s(_vm.__("customer.zipcode")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.zipcode,
                                      expression: "customer.shipping.zipcode"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["shipping.zipcode"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_zipcode",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.zipcode
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "zipcode",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["shipping.zipcode"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "shipping.zipcode"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_city" } },
                                  [_vm._v(_vm._s(_vm.__("customer.city")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.city,
                                      expression: "customer.shipping.city"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["shipping.city"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_city",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.city
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "city",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["shipping.city"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "shipping.city"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_phone" } },
                                  [_vm._v(_vm._s(_vm.__("customer.phone")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.phone,
                                      expression: "customer.shipping.phone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["shipping.phone"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_phone",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.phone
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["shipping.phone"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "shipping.phone"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-footer" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(
                    "\n                             \n                        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "form-header-text" }, [
                    _vm._v(_vm._s(_vm.__("customer.rolle")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-12 col-md-12" },
                      _vm._l(_vm.customerTypeRadio, function(type) {
                        return _c(
                          "div",
                          {
                            key: type.value,
                            staticClass: "form-check form-check-inline"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customer.customerType,
                                  expression: "customer.customerType"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "radio",
                                id: "customRadio" + type.value
                              },
                              domProps: {
                                value: type.value,
                                checked: _vm._q(
                                  _vm.customer.customerType,
                                  type.value
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.customer,
                                    "customerType",
                                    type.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "customRadio" + type.value }
                              },
                              [_vm._v(_vm._s(type.name))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form-row",
                      staticStyle: { display: "none" }
                    },
                    [
                      _c("div", { staticClass: "form-group col-6 col-md-6" }, [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customer.newsletter,
                                expression: "customer.newsletter"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", id: "newsletter" },
                            domProps: {
                              checked: Array.isArray(_vm.customer.newsletter)
                                ? _vm._i(_vm.customer.newsletter, null) > -1
                                : _vm.customer.newsletter
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customer.newsletter,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.customer,
                                        "newsletter",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.customer,
                                        "newsletter",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.customer, "newsletter", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "newsletter" }
                            },
                            [_vm._v(_vm._s(_vm.__("customer.newsletter")))]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("div", { staticClass: "form-header-text mb-2" }, [
                        _vm._v(_vm._s(_vm.__("customer.interests")))
                      ]),
                      _vm._v(" "),
                      _c("show-tags", {
                        attrs: {
                          tags: _vm.tagvalues,
                          "checked-tags": _vm.tagsChecked
                        },
                        on: { "tag-checked": _vm.addTags }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-footer text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light",
                      on: { click: _vm.saveCustomer }
                    },
                    [_vm._v(_vm._s(_vm.__("customer.buttons.save")))]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("event-modal", {
        attrs: { "event-types": _vm.eventTypes, "show-modal": _vm.showModal },
        on: {
          "event-modal-data": _vm.modalEvenData,
          "event-close-button": _vm.modelCloseButtonClick
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }