const filters = [
	{
		name   : 'toCurrency',
		execute: function (value) {
			if (typeof value !== "number") {
				return value;
			}

			// console.log('toCurrency: ' + value)
			let formatter = new Intl.NumberFormat('de-DE', {
				style                : 'currency',
				currency             : 'EUR',
				minimumFractionDigits: 2
			});
			return formatter.format(value);
		}
	},
	{
		name   : 'formatBirthday',
		execute: function (value) {
			if (value) {
				return moment(String(value)).format('DD.MM.YYYY');
			}
		}
	},
	{
		name   : 'formatDateTime',
		execute: function (value) {
			if (value) {
				return moment(String(value)).format('DD.MM.YYYY HH:mm');
			}
		}
	},{
		name   : 'formatDate',
		execute: function (value) {
			if (value) {
				return moment(String(value)).format('DD.MM.YYYY');
			}
		}
	},


	{
		// name: 'truncate',
		//  execute:
		// 	 function(text, length=100, clamp='...'){
		// 	console.log(truncate)
		// 		 // console.log('t:' + text + ' l: ' + length + ' c: ' + clamp)
		// 		 // clamp = clamp || '...';
		// 		 // var node = document.createElement('div');
		// 		 // node.innerHTML = text;
		// 		 // var content = node.textContent;
		// 		 // return content.length > length ? content.slice(0, length) + clamp : content;
		// 	 }
	}

];

export default filters;

//
// Vue.filter('toCurrency', function (value) {
//     if (typeof value !== "number") {
//         return value;
//     }
//     var formatter = new Intl.NumberFormat('de-DE', {
//         style: 'currency',
//         currency: '€',
//         minimumFractionDigits: 0
//     });
//     return formatter.format(value);
// });
