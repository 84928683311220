/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
// require('./modules/ajax');
// require('./modules/customerAjaxSave')

window.Vue = require('vue');
Vue.prototype.laravel_tax = window.Laravel.tax;


import filters from './filters';
import VueToastify from 'vue-toastify';
import VeeValidate from 'vee-validate';
import Multiselect from "vue-multiselect";
import VueNumberInput from '@chenfengyuan/vue-number-input';
import Vue2Editor from "vue2-editor";
import VueYoutube from 'vue-youtube'

Vue.prototype.$emptyCheck = (value) => {
    return (value === undefined || value == null || value.length <= 0);
};


Vue.use(VueToastify, {
    canTimeout: true,
    duration: 3000,
    position: 'top-right',
    errorDuration: 3000,
    successDuration: 3000,
    warningInfoDuration: 3000,
    theme: 'light',

});

Vue.use(VeeValidate);
Vue.use(Vue2Editor);
Vue.use(VueYoutube);

window.moment = require('moment');
// import VueSidebarMenu from 'vue-sidebar-menu'

filters.forEach(f => {
    Vue.filter(f.name, f.execute);
});

// import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
// Vue.use(VueSidebarMenu);


Vue.prototype.can = function (value) {
    return window.Laravel.jsPermissions.permissions.includes(value);
};
Vue.prototype.is = function (value) {
    return window.Laravel.jsPermissions.roles.includes(value);
};

//HELPER

Vue.mixin(require('./helper/translation.js'));


// import { SidebarMenu } from 'vue-sidebar-menu'
// export default {
//     components: {
//         SidebarMenu
//     }
// }


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('multiselect', Multiselect);
Vue.component('vue-number-input', VueNumberInput);


Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('products-list', require('./components/ProductsList/ProductsList').default);
Vue.component('laravel-vue-pagination', require('laravel-vue-pagination'));

// Vue.component('datatable', require('./components/test/Datatable').default);
// Vue.component('pagi', require('./components/test/Pagination').default);
Vue.component('projects', require('./components/test/Projects').default);

// Vue.component('my-sidebar', require('./components/Sidebar/Sidebar').default);


//customers
Vue.component('customers-list', require('./components/Customers/CustomersList').default);
Vue.component('customer-create', require('./components/Customers/CustomerCreate').default);
Vue.component('customer-edit', require('./components/Customers/CustomerEdit').default);

//order
Vue.component('order-list', require('./components/Orders/OrderList').default);
Vue.component('create-order', require('./components/Orders/CreateOrder').default);
Vue.component('change-order-owner', require('./components/Orders/ChangeOrderOwner').default);


//articles
Vue.component('article-list', require('./components/Article/ArticleList').default);
//module
Vue.component('datatable', require('./components/module/Datatable').default);
Vue.component('product-search', require('./components/module/ProductSearch').default);
Vue.component('show-tags', require('./components/module/ShowTags').default);


Vue.component('party-list', require('./components/Party/PartyList').default);
Vue.component('party-create', require('./components/Party/PartyCreate').default);

Vue.component('search-table', require('./components/search/SearchTable').default);
Vue.component('search-table-user', require('./components/search/SearchTableUser').default);
Vue.component('product-search-table', require('./components/module/ProductSearchTable').default);

//settings
Vue.component('create-user', require('./components/Settings/Users/CreateUser').default);
Vue.component('team-details', require('./components/Settings/Teams/TeamDetails').default);
Vue.component('shopware', require('./components/Settings/Shopware/shopware').default);
Vue.component('avatar-upload', require('./components/User/AvatarUpload').default);

//events
Vue.component('event-create', require('./components/Events/EventCreate').default);
Vue.component('event-edit', require('./components/Events/EventEdit').default);
Vue.component('event-modal', require('./components/module/EventModal').default);

//calender
Vue.component('calendar', require('./components/Calendar/Calendar').default);
Vue.component('calendar-group', require('./components/Calendar/CalendarGroup').default);

//party
Vue.component('party-order-details', require('./components/module/PartyOrderDetails').default);

//news
Vue.component('quill-editor', require('./components/News/QuillEditor').default)
Vue.component('news-create', require('./components/News/NewsCreate').default)
Vue.component('news-image-upload', require('./components/News/NewsImageUpload').default)
Vue.component('news-video', require('./components/News/NewsVideo').default)
Vue.component('file-upload', require('./components/module/FileUpload').default)

//STATISTICS
Vue.component('statistics-overview', require('./components/Statistics/StatisticsOverview').default)
Vue.component('statistics-commission', require('./components/Statistics/StatisticsCommission').default)
Vue.component('new-statistics-commission', require('./components/Statistics/NewStatisticsCommission').default)
Vue.component('statistics-parties', require('./components/Statistics/StatisticsParties').default)
Vue.component('statistics-customers', require('./components/Statistics/StatisticsCustomers').default)
Vue.component('statistics-sales', require('./components/Statistics/StatisticsSales').default)

Vue.component('bar-chart', require('./components/Statistics/Charts/BarCharts').default)
Vue.component('change-stylist', require('./components/module/ChangeStylist').default)

Vue.component('commission-list', require('./components/Commission/CommissionList').default)
Vue.component('newcommission-list', require('./components/Commission/NewCommissionList').default)

Vue.component('year-drop-down', require('./components/module/YearDropDpwn').default)


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

var filter = function (text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

document.addEventListener("DOMContentLoaded", () => {
    document.getElementById('download').addEventListener('click', () => {
        document.getElementById('donwload_loading').style.display = 'flex';
        axios.post('articles/export/stock', {})
            .then(() => {
                axios.get('/lager_preisliste.html', {responseType: 'arraybuffer'}).then(res => {
                    let blob = new Blob([res.data], {type: 'application/*'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    const timestamp = new Date().getFullYear() + '_' + (new Date().getMonth() + 1) + '_' + new Date().getDate();
                    link.download = '/lagerbestand_preisliste_' + timestamp + '.html'
                    link.click();
                    document.getElementById('donwload_loading').style.display = 'none';
                })
            })
    });
});
