var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-6" }, [
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("label", [_vm._v(_vm._s(_vm.__("settings.image-upload-label")))]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control-file",
            attrs: { type: "file" },
            on: { change: _vm.selectFile }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6" }, [
      _c("div", {}, [
        _vm.avatar !== null
          ? _c("img", {
              staticClass: "avatar img-fluid img-circle",
              attrs: { src: _vm.avatar, alt: "" }
            })
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }