<template>
    <div>
        <div class="form-row mt-3">
            <div class="form-group col-12">
                <label>{{__('order.create.input.search_article.label')}}</label>
                <!--                <input :class="{ 'is-invalid': validateErrors.host_id}" type="text" class="form-control" @input="debounceSearch" v-model="searchValue">-->
                <input type="text" class="form-control" @input="debounceSearch" v-model="searchValue">
                <small>{{__('order.create.input.search_article.small')}}</small>
                <product-search-table @tr-select="selectedRowData" :headerFields="headerFields" :columnData="columnData" :columns="columns" :visible="showResult"></product-search-table>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name   : "ProductSearch",
		data() {
			return {
				searchValue : '',
				showResult  : false,
				headerFields: [
					'Art.Nr',
					'Name',
                    'Variante',
                    'Preis',
					'Bestand',
				],
				columns     : [
					'ordernumber',
					'name',
                    'attributeDescription',
                    'price',
					'instock',
				],
				columnData  : [],
			};

		},
		mounted() {
			document.addEventListener('click', this.documentClick);
		},
		methods: {
			documentClick() {
				if (this.showResult === true) {
					this.searchValue = '';
					this.showResult  = false;
				}
			},
			selectedRowData(row) {
				this.$emit('selectedRowData', row);
			},
			searchArticle() {
				let _self = this;
				axios.post('/backend/articleSearchAxios', {
					searchKey: _self.searchValue
				})
					 .then((result) => {
						 _self.columnData = result.data.data.data;
						 _self.showResult = true;
					 })
					 .catch((error) => {
						 console.log('error');
					 });
			},
			debounceSearch: _.debounce(function () {
				if (this.searchValue.length > 2) {
					this.searchArticle();
				}

				// this.searchHost();
			}),
		}
	};
</script>

<style scoped>

</style>
