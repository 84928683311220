<template>
    <div id="calendar">
        <div class="container">
            <div class="row">
                <div class="col-8">

                    <div class="card">
                        <div class="card-header">
                            {{ __('calendar.root') }}
                        </div>
                        <div class="card-body" style="height: 67vh">

                            <calendar-view
                                :events="items"
                                :show-date="showDate"
                                :show-times="showTimes"
                                :enable-date-selection="true"
                                :startingDayOfWeek=1

                                @click-event="onClickItem"
                                @click-date="onClickItem"
                                class="theme-default">
                                <calendar-view-header
                                    slot="header"
                                    slot-scope="t"
                                    :header-props="t.headerProps"
                                    @input="setShowDate"/>
                            </calendar-view>


                        </div>
                        <div class="card-footer">
                            <div class="w-100">
                                <span class="mr-2"><i style="color: #f00;" class="far fa-circle mr-1"></i>Erinnerung</span>
                                <template v-for="event in eventTypes">
                                    <span class="mr-2"><i :style="'color:' + event.color" class="far fa-circle mr-1"></i>{{ event.name }}</span>
                                </template>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="col-4">
                    <div class="card">
                        <div class="card-header">&nbsp;</div>


                        <div v-if="typeof event.originalEvent != 'undefined'">
                            <!--PARTY begin-->
                            <div v-if="event.originalEvent.fullData.event.event_type == 99">
                                <div class="card-body">

                                    Schmuckparty
                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.party.party_name }}
                                        </div>
                                        <label>{{ __('calendar.party_name') }}</label>
                                    </div>

                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.event.start_datetime|formatDateTime }}
                                        </div>
                                        <label>{{ __('calendar.start_datetime') }}</label>
                                    </div>

                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.host.firstname }} {{ event.originalEvent.fullData.host.lastname }}<br>
                                            {{ event.originalEvent.fullData.billing.street }}<br>
                                            {{ event.originalEvent.fullData.billing.zipcode }} {{ event.originalEvent.fullData.billing.city }}
                                        </div>
                                        <label>{{ __('calendar.host') }}</label>
                                    </div>

                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.billing.phone }}
                                        </div>
                                        <label>{{ __('calendar.phone') }}</label>
                                    </div>

                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.host.email }}
                                        </div>
                                        <label>{{ __('calendar.email') }}</label>
                                    </div>

                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.party.party_place }}<br>
                                            {{ event.originalEvent.fullData.party.street }}<br>
                                            {{ event.originalEvent.fullData.party.zipcode }} {{ event.originalEvent.fullData.party.city }}
                                        </div>
                                        <label>{{ __('calendar.party_place') }}</label>
                                    </div>
                                </div>
                                <div class="card-footer text-right">
                                    <a @click="deleteEvent" class="btn btn-light"><i class="far fa-trash-alt"></i>{{ __('calendar.buttons.delete') }}</a>
                                    <a class="btn btn-light" :href="'/backend/party/edit/' + event.originalEvent.fullData.party.id"><i class="far fa-edit"></i>{{ __('calendar.buttons.edit') }}</a>
                                </div>
                            </div>

                            <!--PARTY end-->

                            <!--event begin-->
                            <div v-if="event.originalEvent.fullData.event.event_type != 99">
                                <div class="card-body">

                                    <div>
                                        <div>
                                            {{ getEventTypeDetails(event.originalEvent.fullData.event.event_type).name }}
                                        </div>
                                        <label>{{ __('calendar.event_type') }}</label>
                                    </div>


                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.event.start_datetime|formatDateTime }}
                                        </div>
                                        <label>{{ __('calendar.start_datetime') }}</label>
                                    </div>

                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.event.description }}
                                        </div>
                                        <label>{{ __('calendar.description') }}</label>
                                    </div>


                                    <div class="mt-3">
                                        <div>
                                            {{ event.originalEvent.fullData.event.note }}
                                        </div>
                                        <label>{{ __('calendar.note') }}</label>
                                    </div>

                                    <div v-if="event.originalEvent.fullData.event.s_user_id != null">
                                        <div class="mt-3">
                                            <div>
                                                {{ event.originalEvent.fullData.billing.firstname }} {{ event.originalEvent.fullData.billing.lastname }}<br>
                                                {{ event.originalEvent.fullData.billing.street }}<br>
                                                {{ event.originalEvent.fullData.billing.zipcode }} {{ event.originalEvent.fullData.billing.city }}
                                            </div>
                                            <label>{{ __('calendar.user_data') }}</label>
                                        </div>

                                        <div class="mt-3">
                                            <div>
                                                {{ event.originalEvent.fullData.billing.phone }}
                                            </div>
                                            <label>{{ __('calendar.phone') }}</label>
                                        </div>
                                    </div>


                                </div>
                                <div class="card-footer text-right">
                                    <a @click="deleteEvent" class="btn btn-light"><i class="far fa-trash-alt"></i>{{ __('calendar.buttons.delete') }}</a>
                                    <a class="btn btn-light" :href="'/backend/events/edit/' + event.originalEvent.id"><i class="far fa-edit"></i>{{ __('calendar.buttons.edit') }}</a>
                                </div>
                            </div>

                            <!-- event end-->

                        </div>
                        <div v-else>
                            <div class="card-body">
                                {{ __('calendar.no_events') }}
                            </div>
                            <div class="card-footer">

                            </div>
                        </div>


                    </div>
                </div>

            </div>


        </div>
    </div>
</template>
<script>
import {CalendarView, CalendarViewHeader} from "vue-simple-calendar";
// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css");
// require("vue-simple-calendar/static/css/holidays-us.css")

export default {
    name      : 'calendar',
    props     : {
        itemsProps: {},
        eventTypes: {},
    },
    data      : function () {
        return {
            showDate : new Date(),
            showTimes: true,
            items    : [],
            event    : {},
        };
    },
    components: {
        CalendarView,
        CalendarViewHeader,
    },
    mounted()
    {
        this.items = this.$props.itemsProps;

        //test
        // this.getEventType(99)
        console.log(this.getEventTypeDetails(99).color);
        // alert(typeof this.event.originalEvent)
    },
    methods   : {
        setShowDate(d)
        {
            this.showDate = d;
        },
        onClickItem(item)
        {
            console.log('clicks');
            console.log(JSON.stringify(item, null, 2));
            this.event = item;
        },
        getEventTypeDetails(id)
        {
            for (let key in this.eventTypes) {
                if (this.eventTypes[key].id == id) {
                    return this.eventTypes[key];
                }
            }
        },
        deleteEvent()
        {
            console.log('delete event')

            this.$vToastify.setSettings({
                                            backdrop    : 'rgba(0, 0, 0, 0.2)',
                                            withBackdrop: true,
                                            position    : 'center-center',
                                        })
            this.$vToastify.prompt({
                                       body   : "Wirklich löschen?",
                                       mode   : "prompt",
                                       answers: {
                                           Ja  : true,
                                           Nein: false
                                       }
                                   }).then(value => {
                if (value) {
                    axios
                        .post('/backend/event/delete/' + this.event.originalEvent.id, {
                            eventID: this.event.originalEvent.id
                        })
                        .then((response) => {
                            document.location.href = '/backend/calendar'
                        })
                        .catch((error) => {
                            this.$vToastify.error({
                                                      title: 'Löschen',
                                                      body : 'Kann nicht gelöscht werden'
                                                  })
                            console.log('error: ' + JSON.stringify(error, null, 2))
                        })
                }
            })
        }
    }
};
</script>
<style>
.cv-event {
    cursor: pointer;
}

#calendar {
    /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
    /*color: #2c3e50;*/
    height: 67vh;
    /*width: 90vw;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
}
</style>
