<template>
    <section id="party_create">
        <!--        <div class="container">-->
        <!--            <div class="row">-->
        <!--                <div class="col-12 col-md-6">-->
        <div class="card">
            <div class="card-header">
                <div class="form-row">
                    <div class="form-group col-12 col-md-12">
                        <label>{{ __('party.input.search_host.label') }}</label>
                        <input :class="{ 'is-invalid': validateErrors.host_id}" type="text" class="form-control" @input="debounceSearch" v-model="searchHostValue">
                        <small>{{ __('party.input.search_host.small') }}</small>

                        <search-table @tr-select="selectedUser" :headerFields="headerFields" :columnData="columnData" :visible="showResult"></search-table>

                    </div>
                </div>
            </div>
            <div class="card-body">

                <div class="form-header-text">Gastgeberin:</div>

                <div>
                    <span>Kd.Nr.:{{ hostData.customernumber }}</span>
                </div>

                <div>
                    <span>{{ hostData.firstname }}</span> <span>{{ hostData.lastname }}</span>
                </div>

                <div>
                    <span>{{ hostData.s_user_default_billing_addresses.street }}</span>
                </div>
                <div>
                    <span>{{ hostData.s_user_default_billing_addresses.zipcode }}</span> <span>{{ hostData.s_user_default_billing_addresses.city }}</span>
                </div>
                <div>
                    <span>{{ hostData.email }}</span>
                </div>

                <!--                <div class="form-header-text">lorem</div>-->
                <!--                <div class="form-row">-->
                <!--                    <div class="form-group col-12 col-md-12">-->
                <!--                        <label>{{ __('party.input.party_name.label') }}</label>-->
                <!--                        <input :class="{ 'is-invalid': validateErrors.party_name}" type="text" class="form-control" v-model="party.party_name">-->
                <!--                    </div>-->
                <!--                </div>-->

                <div class="form-row">
                    <div class="form-group col-12">
                        <label>{{ __('party.input.party_name.label') }}</label>
                        <select :class="{ 'is-invalid': validateErrors.party_name}" class="form-control" v-model="party.party_name">
                            <option v-for="(subject, index) in partySubject" :selected="index === 0">{{ subject }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-header-text">Wann</div>

                <div class="form-row">
                    <div class="form-group col-12 col-md-4">
                        <label>{{ __('party.input.date') }}</label>
                        <input :class="{ 'is-invalid': validateErrors.date}" type="date" class="form-control" v-model="party.date">
                    </div>
                    <div class="form-group col-12 col-md-4">
                        <label>{{ __('party.input.time') }}</label>
                        <input :class="{ 'is-invalid': validateErrors.time}" type="time" class="form-control" v-model="party.time">
                    </div>
                </div>

                <div class="form-header-text">Wo</div>

                <div class="form-row">

                    <div class="form-group col-12 col-md-6">
                        <label>{{ __('party.input.party_place.label') }}</label>
                        <input type="text" class="form-control" v-model="party.party_place">
                    </div>
                    <!--                                <div class="form-group col-12 col-md-6">-->
                    <!--                                    <label>{{__('party.input.party_name.lastname')}}</label>-->
                    <!--                                    <input type="text" class="form-control" v-model="party.lastname">-->
                    <!--                                </div>-->

                </div>

                <div class="form-row">

                    <div class="form-group col-12 col-md-6">
                        <label>{{ __('party.input.street') }}</label>
                        <!--                                    <input type="text" class="form-control" v-model="party.s_user_default_billing_addresses.street">-->
                        <input type="text" class="form-control" v-model="party.street">
                    </div>

                </div>

                <div class="form-row">

                    <div class="form-group col-12 col-md-3">
                        <label>{{ __('party.input.zipcode') }}</label>
                        <!--                                    <input type="text" class="form-control" v-model="party.s_user_default_billing_addresses.zipcode">-->
                        <input type="text" class="form-control" v-model="party.zipcode">
                    </div>

                    <div class="form-group col-12 col-md-5">
                        <label>{{ __('party.input.city') }}</label>
                        <!--                                    <input type="text" class="form-control" v-model="party.s_user_default_billing_addresses.city">-->
                        <input type="text" class="form-control" v-model="party.city">
                    </div>

                </div>

            </div>
            <div class="card-footer">
                <button class="btn btn-light hidden" v-on:click="clear">{{ __('party.buttons.clear') }}</button>
                <button class="btn btn-light" v-on:click="saveParty">{{ __('party.buttons.save') }}</button>
            </div>

        </div>
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </section>
</template>

<script>
export default {
    name : "PartyCreate",
    props: ['partyprops'],
    // props: {
    // 	partyprops: {
    // 		type   : Object,
    // 		default: function () {
    // 			return {
    // 				edit       : false,
    // 				party_name : '',
    // 				date       : '',
    // 				time       : '',
    // 				party_place: '',
    // 				street     : '',
    // 				zipcode    : '',
    // 				city       : '',
    // 				host_id    : 0,
    // 			};
    // 		}
    // 	},
    //
    // },
    mounted()
    {
        if (this.$props.partyprops !== null) {
            this.party                                     = this.$props.partyprops;
            this.hostData.s_user_default_billing_addresses = this.$props.partyprops.hostData.s_user_default_billing_addresses;
            this.hostData.firstname                        = this.$props.partyprops.hostData.s_user_default_billing_addresses.firstname;
            this.hostData.lastname                         = this.$props.partyprops.hostData.s_user_default_billing_addresses.lastname;
            this.hostData.email                            = this.$props.partyprops.host.email;
            this.hostData.customernumber                   = this.$props.partyprops.host.customernumber;
        }

    },

    data()
    {
        return {
            saved          : false,
            party          : {
                edit       : false,
                party_name : '',
                date       : '',
                time       : '',
                party_place: '',
                street     : '',
                zipcode    : '',
                city       : '',
                host_id    : 0,
            },
            searchHostValue: '',
            debounce       : null,
            showResult     : false,
            headerFields   : [
                'Kundennummer',
                'Name',
                'Stadt',
                'E-Mail',
            ],
            columnData     : [],
            hostData       : {
                s_user_default_billing_addresses: {}
            },
            partySubject   : [
                'Schmuckparty',
                'Kooperationsveranstaltung'
            ],
            validateErrors : {}
        };
    },
    methods: {
        searchHost()
        {
            let _self = this;
            axios
                .post('/backend/customerSearchAxios', {
                    searchKey: _self.searchHostValue,
                })
                .then((response) => {
                    _self.columnData = response.data;
                    _self.showResult = true;
                })
                .catch((error) => {
                    console.log('error');
                });
        },
        clear()
        {
            let partyClear = {
                party_name : '',
                date       : '',
                time       : '',
                party_place: '',
                street     : '',
                zipcode    : '',
                city       : '',
                host_id    : 0,
                edit       : false,
            };
            this.party     = partyClear;
        },
        selectedUser(row)
        {
            this.hostData        = row;
            this.searchHostValue = '';
            this.showResult      = false;
            //host data
            // this.party.host_id   = row.id;
            // this.party.host_id = row.id;
            this.party.host_id = row.userID;
            // this.party.party_place  = row.;
            this.party.street  = row.s_user_default_billing_addresses.street;
            this.party.zipcode = row.s_user_default_billing_addresses.zipcode;
            this.party.city    = row.s_user_default_billing_addresses.city;
        },
        debounceSearch: _.debounce(function () {
            this.searchHost();
        }),
        saveParty()
        {
            let _self = this;
            axios.post('/backend/savePartyAxios', _self.party
            ).then(response => {
                _self.showToast('Gespeichert', '', 'success');
                _self.clear();
            }).catch(error => {
                console.log('error ' + error);
                if (error.response.status === 422) {
                    _self.validateErrors = error.response.data.errors || {};
                }
                _self.showToast('Fehler', 'Party konnte nicht gespeichert werden!', 'error');

            });
        },
        showToast(title, body, type)
        {

            if (type === 'success') {
                this.$vToastify.success({
                                            title   : title,
                                            body    : body,
                                            callback: () => {
                                                window.location.href = '/backend/party/create';
                                            }
                                        });
            }

            if (type === 'error') {
                this.$vToastify.error({
                                          title: title,
                                          body : body,
                                      });
            }
        },
    }
};
</script>

<style scoped>

</style>
