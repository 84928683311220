<template>
    <div class="form-group">
        <select class="form-control" v-model="sw_user_id" @change="selectChanged">
            <option value="-1">keine Zuordnung</option>
            <option v-for="user in backendUsers" :value="user.sw_user_id">{{ user.name }}</option>
        </select>
        <label>Bestellung UMHÄNGEN</label>
    </div>
</template>

<script>
export default {
    name : "ChangeOrderOwner",
    props: {
        backendUsers: {
            type    : Array,
            required: true
        },
        orderId     : {
            type    : Number,
            required: true
        }
    },
    data()
    {
        return {
            sw_user_id: null
        }
    },
    methods: {
        selectChanged()
        {

            let _self = this
            axios
                .post('/backend/order/changeOwner', {
                    sw_user_id: _self.sw_user_id,
                    orderId   : _self.orderId
                })
                .then((response) => {

                    _self.showToast('Gespeichert', '', 'success')
                })
                .catch((error) => {
                    if (error.response.data.error) {
                        _self.showToast('Fehler', `${error.response.data.error}`, 'error')
                    } else {
                        _self.showToast('Fehler', 'Konnte nicht gespeichert werden', 'error')
                    }
                })
        },
        showToast(title, body, type)
        {

            if (type === 'success') {
                this.$vToastify.success({
                                            title   : title,
                                            body    : body,
                                            callback: () => {
                                                window.location.reload()
                                            }
                                        });
            }

            if (type === 'error') {
                this.$vToastify.error({
                                          title: title,
                                          body : body,
                                      });
            }
        },
    },
    watch  : {}
}
</script>

<style scoped>

</style>
