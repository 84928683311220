<template>
    <section>
        <!--        <div class="form-group">-->
        <select class="form-control" @change="changeUser" v-model="selectedUser">
            <option v-for="user in users" :value="user.sw_user_id">{{ user.name }}</option>
        </select>
        <label>Kunden umhängen</label>
    </section>
    <!--    </div>-->
</template>

<script>
export default {
    name   : 'ChangeStylist',
    props  : {
        users           : {
            required: true
        },
        currentStylistId: {
            default : -1,
            required: true
        },
        userId          : {
            require: true
        }
    },
    data()
    {
        return {
            selectedUser: -1,
        }
    },
    mounted()
    {
        this.selectedUser = this.currentStylistId
    },
    methods: {
        changeUser()
        {
            axios.post('/backend/changeCustomerStylist', {
                newStylistId: this.selectedUser,
                userId      : this.userId
            })
                .then((response) => {
                    location.reload();
                }).catch((error) => {
                console.log('error')
            })

        }
    }


}
</script>
