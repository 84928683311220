var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "create-order" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-5" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.__("order.create.input.search_party.label")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.customer.partyId,
                              expression: "customer.partyId"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.customer,
                                  "partyId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.partyChanged
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "0" } }),
                          _vm._v(" "),
                          _vm._l(_vm.parties, function(party) {
                            return _c(
                              "option",
                              { domProps: { value: party.id } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      party.party_date_time
                                    )
                                  ) +
                                    " " +
                                    _vm._s(party.party_name) +
                                    " " +
                                    _vm._s(party.host.firstname) +
                                    " " +
                                    _vm._s(party.host.lastname) +
                                    " Kd.Nr.: " +
                                    _vm._s(party.host.customernumber)
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-12" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm.__("order.create.input.search_customer.label")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchHostValue,
                              expression: "searchHostValue"
                            }
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.validateErrors.host_id },
                          attrs: { type: "text" },
                          domProps: { value: _vm.searchHostValue },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.searchHostValue = $event.target.value
                              },
                              _vm.debounceSearch
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            _vm._s(
                              _vm.__("order.create.input.search_customer.small")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("search-table", {
                          attrs: {
                            headerFields: _vm.headerFields,
                            columnData: _vm.columnData,
                            visible: _vm.showResult
                          },
                          on: { "tr-select": _vm.selectedUser }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "nav nav-tabs",
                      attrs: { id: "myTab", role: "tablist" }
                    },
                    [
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link active form-header-text",
                            attrs: {
                              id: "billing-tab",
                              "data-toggle": "tab",
                              href: "#home",
                              role: "tab",
                              "aria-controls": "home",
                              "aria-selected": "true"
                            }
                          },
                          [_vm._v(_vm._s(_vm.__("order.tabs.billingAddress")))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link form-header-text disabled",
                            attrs: {
                              id: "shipping-tab",
                              "data-toggle": "tab",
                              href: "#profile",
                              role: "tab",
                              "aria-controls": "profile",
                              "aria-selected": "false"
                            }
                          },
                          [_vm._v(_vm._s(_vm.__("order.tabs.shippingAddress")))]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      attrs: { id: "myTabContent" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade show active",
                          attrs: {
                            id: "home",
                            role: "tabpanel",
                            "aria-labelledby": "billing-tab"
                          }
                        },
                        [
                          _c("div", { staticClass: "form-row mt-3" }, [
                            _c("div", { staticClass: "form-group col-md-3" }, [
                              _c(
                                "label",
                                { attrs: { for: "customer_salutation" } },
                                [_vm._v(_vm._s(_vm.__("customer.salutation")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.salutation,
                                      expression: "customer.salutation"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "customer_salutation",
                                    name: "salutation"
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.customer,
                                        "salutation",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.salutation, function(item) {
                                  return _c(
                                    "option",
                                    { domProps: { value: item.value } },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c("label", { attrs: { for: "firstname" } }, [
                                _vm._v(_vm._s(_vm.__("customer.firstname")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customer.firstname,
                                    expression: "customer.firstname"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.validateErrors["customer.firstname"]
                                },
                                attrs: {
                                  type: "text",
                                  id: "firstname",
                                  name: "firstname",
                                  value: ""
                                },
                                domProps: { value: _vm.customer.firstname },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customer,
                                      "firstname",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "invalid-feedback" }),
                              _vm._v(" "),
                              _vm.validateErrors &&
                              _vm.validateErrors["customer.firstname"]
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.validateErrors[
                                            "customer.firstname"
                                          ][0]
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c("label", { attrs: { for: "lastname" } }, [
                                _vm._v(_vm._s(_vm.__("customer.lastname")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customer.lastname,
                                    expression: "customer.lastname"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.validateErrors["customer.lastname"]
                                },
                                attrs: {
                                  type: "text",
                                  id: "lastname",
                                  name: "lastname",
                                  value: ""
                                },
                                domProps: { value: _vm.customer.lastname },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customer,
                                      "lastname",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.validateErrors &&
                              _vm.validateErrors["customer.lastname"]
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.validateErrors[
                                            "customer.lastname"
                                          ][0]
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group col-12" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.__("order.additional_address_line1")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.customer.billing
                                        .additional_address_line1,
                                    expression:
                                      "customer.billing.additional_address_line1"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value:
                                    _vm.customer.billing
                                      .additional_address_line1
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customer.billing,
                                      "additional_address_line1",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c("label", { attrs: { for: "email" } }, [
                                  _vm._v(
                                    _vm._s(_vm.__("customer.email_address"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.email,
                                      expression: "customer.email"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["customer.email"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "email",
                                    name: "email",
                                    value: ""
                                  },
                                  domProps: { value: _vm.customer.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.email"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.email"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c("label", { attrs: { for: "birthday" } }, [
                                  _vm._v(_vm._s(_vm.__("customer.birthday")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.birthday,
                                      expression: "customer.birthday"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.validateErrors.birthday
                                  },
                                  attrs: {
                                    type: "date",
                                    id: "birthday",
                                    value: ""
                                  },
                                  domProps: { value: _vm.customer.birthday },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer,
                                        "birthday",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors.birthday
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.validateErrors.birthday[0])
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_street" } },
                                  [_vm._v(_vm._s(_vm.__("customer.street")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.street,
                                      expression: "customer.billing.street"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.billing.street"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_street",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.street
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "street",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.billing.street"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.billing.street"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-2" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_zipcode" } },
                                  [_vm._v(_vm._s(_vm.__("customer.zipcode")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.zipcode,
                                      expression: "customer.billing.zipcode"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.billing.zipcode"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_zipcode",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.zipcode
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "zipcode",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.billing.zipcode"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.billing.zipcode"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_city" } },
                                  [_vm._v(_vm._s(_vm.__("customer.city")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.city,
                                      expression: "customer.billing.city"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.billing.city"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_city",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.city
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "city",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.billing.city"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.billing.city"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "billing_phone" } },
                                  [_vm._v(_vm._s(_vm.__("customer.phone")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.billing.phone,
                                      expression: "customer.billing.phone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors["billing.phone"]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "billing_phone",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.billing.phone
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.billing,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["billing.phone"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "billing.phone"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-header-text pl-1" },
                              [_vm._v(_vm._s(_vm.__("order.rolle")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-12 col-md-12" },
                              _vm._l(_vm.customerTypeRadio, function(type) {
                                return _c(
                                  "div",
                                  {
                                    key: type.value,
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.customer.customerType,
                                          expression: "customer.customerType"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "customRadio" + type.value
                                      },
                                      domProps: {
                                        value: type.value,
                                        checked: _vm._q(
                                          _vm.customer.customerType,
                                          type.value
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.customer,
                                            "customerType",
                                            type.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: {
                                          for: "customRadio" + type.value
                                        }
                                      },
                                      [_vm._v(_vm._s(type.name))]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _c("hr", { staticClass: "w-100" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row mt-1" }, [
                            _c(
                              "div",
                              { staticClass: "form-header-text pl-1" },
                              [_vm._v(_vm._s(_vm.__("order.send_to")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-12 col-md-12" },
                              _vm._l(_vm.sendToRadio, function(type) {
                                return _c(
                                  "div",
                                  {
                                    key: type.value,
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.customer.sendTo,
                                          expression: "customer.sendTo"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        disabled:
                                          type.value == 1 &&
                                          _vm.customer.partyId == 0,
                                        type: "radio",
                                        id: "customRadio1" + type.value
                                      },
                                      domProps: {
                                        value: type.value,
                                        checked: _vm._q(
                                          _vm.customer.sendTo,
                                          type.value
                                        )
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            return _vm.$set(
                                              _vm.customer,
                                              "sendTo",
                                              type.value
                                            )
                                          },
                                          _vm.orderSum
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: {
                                          for: "customRadio1" + type.value
                                        }
                                      },
                                      [_vm._v(_vm._s(type.name))]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _vm.customer.sendTo === 0
                            ? _c("div", { staticClass: "form-row mt-1" }, [
                                _c("div", { staticClass: "form-group col" }, [
                                  _c("div", { staticClass: "form-check" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.customer
                                              .address_billing_eq_shipping,
                                          expression:
                                            "customer.address_billing_eq_shipping"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "defaultCheck1"
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.customer
                                            .address_billing_eq_shipping
                                        )
                                          ? _vm._i(
                                              _vm.customer
                                                .address_billing_eq_shipping,
                                              null
                                            ) > -1
                                          : _vm.customer
                                              .address_billing_eq_shipping
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.customer
                                                .address_billing_eq_shipping,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.customer,
                                                  "address_billing_eq_shipping",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.customer,
                                                  "address_billing_eq_shipping",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.customer,
                                              "address_billing_eq_shipping",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "defaultCheck1" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.__("customer.billingEqShipping")
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: {
                            id: "profile",
                            role: "tabpanel",
                            "aria-labelledby": "shipping-tab"
                          }
                        },
                        [
                          _c("div", { staticClass: "form-row mt-3" }, [
                            _c("div", { staticClass: "form-group col-md-3" }, [
                              _c(
                                "label",
                                {
                                  attrs: { for: "shipping_customer_salutation" }
                                },
                                [_vm._v(_vm._s(_vm.__("customer.salutation")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.salutation,
                                      expression: "customer.shipping.salutation"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "shipping_customer_salutation",
                                    name: "salutation"
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "salutation",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.salutation, function(item) {
                                  return _c(
                                    "option",
                                    { domProps: { value: item.value } },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_firstname" } },
                                  [_vm._v(_vm._s(_vm.__("customer.firstname")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.firstname,
                                      expression: "customer.shipping.firstname"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.shipping.firstname"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_firstname",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.firstname
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "firstname",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors[
                                  "customer.shipping.firstname"
                                ]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.shipping.firstname"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_lastname" } },
                                  [_vm._v(_vm._s(_vm.__("customer.lastname")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.lastname,
                                      expression: "customer.shipping.lastname"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.shipping.lastname"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_lastname",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.lastname
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "lastname",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.shipping.lastname"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.shipping.lastname"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-12" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.__(
                                      "order.create.additional_address_line1"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.customer.shipping
                                        .additional_address_line1,
                                    expression:
                                      "customer.shipping.additional_address_line1"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value:
                                    _vm.customer.shipping
                                      .additional_address_line1
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customer.shipping,
                                      "additional_address_line1",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_street" } },
                                  [_vm._v(_vm._s(_vm.__("customer.street")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.street,
                                      expression: "customer.shipping.street"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.shipping.street"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_street",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.street
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "street",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.shipping.street"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.shipping.street"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-2" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_zipcode" } },
                                  [_vm._v(_vm._s(_vm.__("customer.zipcode")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.zipcode,
                                      expression: "customer.shipping.zipcode"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.shipping.zipcode"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_zipcode",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.zipcode
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "zipcode",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.shipping.zipcode"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.shipping.zipcode"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_city" } },
                                  [_vm._v(_vm._s(_vm.__("customer.city")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.city,
                                      expression: "customer.shipping.city"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.shipping.city"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_city",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.city
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "city",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.shipping.city"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.shipping.city"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-6 col-md-6" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "shipping_phone" } },
                                  [_vm._v(_vm._s(_vm.__("customer.phone")))]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customer.shipping.phone,
                                      expression: "customer.shipping.phone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.validateErrors[
                                        "customer.shipping.phone"
                                      ]
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "shipping_phone",
                                    value: ""
                                  },
                                  domProps: {
                                    value: _vm.customer.shipping.phone
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.customer.shipping,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-feedback" }),
                                _vm._v(" "),
                                _vm.validateErrors &&
                                _vm.validateErrors["customer.shipping.phone"]
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.validateErrors[
                                              "customer.shipping.phone"
                                            ][0]
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("hr", { staticClass: "w-100" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-header-text" }, [
                    _vm._v(_vm._s(_vm.__("order.tags")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("show-tags", {
                          attrs: {
                            tags: _vm.tags,
                            "checked-tags": _vm.tagsChecked
                          },
                          on: { "tag-checked": _vm.addTags }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "w-100" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-12" }, [
                      _c("label", { attrs: { for: "internalComment" } }, [
                        _vm._v(_vm._s(_vm.__("customer.internalComment")))
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer.internalComment,
                            expression: "customer.internalComment"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          name: "",
                          id: "internalComment",
                          cols: "30",
                          rows: "3"
                        },
                        domProps: { value: _vm.customer.internalComment },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.customer,
                              "internalComment",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-footer" })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-7" }, [
            _c("div", { staticClass: "card h-100" }, [
              _c("div", { staticClass: "card-header text-right" }, [
                !_vm.waitForSave
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-light btn-sm",
                        on: { click: _vm.saveOrder }
                      },
                      [_vm._v(_vm._s(_vm.__("order.buttons.save")))]
                    )
                  : _c("div", { staticClass: "btn btn-light btn-sm" }, [
                      _vm._v("Speichern...")
                    ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.__("order.create.payment")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer.payment,
                            expression: "customer.payment"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.customer,
                              "payment",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.allowedPayment, function(payment) {
                        return _c(
                          "option",
                          { domProps: { value: payment.id } },
                          [_vm._v(_vm._s(payment.description))]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("label", [_vm._v("Versand")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer.dispatchId,
                            expression: "customer.dispatchId"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.customer,
                                "dispatchId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.orderSum
                          ]
                        }
                      },
                      _vm._l(_vm.dispatch, function(dispatchItem) {
                        return _c(
                          "option",
                          { domProps: { value: dispatchItem.id } },
                          [_vm._v(_vm._s(dispatchItem.name))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-12" }, [
                    _c("label", { attrs: { for: "internalComment" } }, [
                      _vm._v(_vm._s(_vm.__("order.internalComment")))
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.orderInternalComment,
                          expression: "orderInternalComment"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { cols: "30", rows: "2" },
                      domProps: { value: _vm.orderInternalComment },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.orderInternalComment = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("nav", [
                  _c(
                    "div",
                    {
                      staticClass: "nav nav-tabs",
                      attrs: { id: "nav-tab", role: "tablist" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "nav-item nav-link form-header-text active",
                          attrs: {
                            id: "nav-order-tab",
                            "data-toggle": "tab",
                            href: "#nav-home",
                            role: "tab",
                            "aria-controls": "nav-home",
                            "aria-selected": "true"
                          }
                        },
                        [_vm._v(_vm._s(_vm.__("order.tabs.order")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "nav-item nav-link form-header-text",
                          attrs: {
                            id: "nav-order-party-tab",
                            "data-toggle": "tab",
                            href: "#nav-profile",
                            role: "tab",
                            "aria-controls": "nav-profile",
                            "aria-selected": "false"
                          }
                        },
                        [_vm._v(_vm._s(_vm.__("order.tabs.order_party")))]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-content",
                    attrs: { id: "nav-tabContent" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade show active",
                        attrs: {
                          id: "nav-home",
                          role: "tabpanel",
                          "aria-labelledby": "nav-order-tab"
                        }
                      },
                      [
                        _c("product-search", {
                          on: { selectedRowData: _vm.selectedArticle }
                        }),
                        _vm._v(" "),
                        _vm.validateErrors && _vm.validateErrors.orderData
                          ? _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                class: "validateErrors.orderData ? show"
                              },
                              [_vm._v(_vm._s(_vm.validateErrors.orderData[0]))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("table", { staticClass: "table" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.orderData, function(
                              orderItem,
                              keyX,
                              indexX
                            ) {
                              return _c(
                                "tr",
                                [
                                  _vm._l(orderItem, function(
                                    item,
                                    key,
                                    index2
                                  ) {
                                    return _c("td", [
                                      key == "price"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm._f("toCurrency")(item))
                                            )
                                          ])
                                        : key === "quantity"
                                        ? _c("span", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: orderItem.quantity,
                                                  expression:
                                                    "orderItem.quantity"
                                                }
                                              ],
                                              staticClass:
                                                "form-control input-order-quantity form-control-sm",
                                              attrs: {
                                                type: "number",
                                                min: "1"
                                              },
                                              domProps: {
                                                value: orderItem.quantity
                                              },
                                              on: {
                                                change: _vm.orderSum,
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    orderItem,
                                                    "quantity",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ])
                                        : key !== "id"
                                        ? _c("span", [_vm._v(_vm._s(item))])
                                        : _vm._e()
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        key: keyX,
                                        staticClass: "btn btn-light btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeOrderItem(keyX)
                                          }
                                        }
                                      },
                                      [_vm._v("-")]
                                    )
                                  ])
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]),
                        _vm._v(" "),
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticClass: "text-right" }, [
                                _c("b", [_vm._v(_vm._s(_vm.__("order.sum")))])
                              ]),
                              _vm._v(" "),
                              _c("td", { attrs: { width: "100px" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      this.orderItemSum.itemSum
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.orderItemSum.itemSum < 70 &&
                            _vm.customer.sendTo === 0 &&
                            _vm.customer.dispatchId !== 12
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(_vm.__("order.shipping")))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          this.orderItemSum.shipping
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _c("tr", [
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(_vm.__("order.shipping")))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v("0,00 €")])
                                ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "text-right" }, [
                                _c("b", [_vm._v(_vm._s(_vm.__("order.total")))])
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(_vm.orderItemSum.total)
                                  )
                                )
                              ])
                            ])
                          ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.partyOrderDetails
                      ? _c(
                          "div",
                          {
                            staticClass: "tab-pane fade",
                            attrs: {
                              id: "nav-profile",
                              role: "tabpanel",
                              "aria-labelledby": "nav-order-party-tab"
                            }
                          },
                          [
                            _c("div", { staticClass: "container" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "my-2" }, [
                                    _vm._v(
                                      "\n                                                    " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.commission.host -
                                              _vm.commission.host_temp +
                                              _vm.commission.host_buy_sum +
                                              _vm.commission.party_sale
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.__("party.sales")))
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "my-2" }, [
                                    _vm._v(
                                      "\n                                                    " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.commission.host_temp
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.__("party.host_commission_temp")
                                        )
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "my-2" }, [
                                    _vm._v(
                                      "\n                                                    " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.commission.host
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.__("party.host_commission"))
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "my-2" }, [
                                    _vm._v(
                                      "\n                                                    " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.commission.stylist
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.__("party.stylist_commission")
                                        )
                                      )
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("party-order-details", {
                              attrs: {
                                partyOrderDetails: _vm.partyOrderDetails,
                                state: _vm.state
                              }
                            })
                          ],
                          1
                        )
                      : _c("div", { staticClass: "form-header-text" }, [
                          _vm._v(_vm._s(_vm.__("order.details.no-items")))
                        ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-footer text-right" }, [
                !_vm.waitForSave
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-light",
                        on: { click: _vm.saveOrder }
                      },
                      [_vm._v(_vm._s(_vm.__("order.buttons.save")))]
                    )
                  : _c("div", { staticClass: "btn btn-light" }, [
                      _vm._v("Speichern...")
                    ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("event-modal", {
        attrs: { "event-types": _vm.eventTypes, "show-modal": _vm.showModal },
        on: {
          "event-modal-data": _vm.modalEvenData,
          "event-close-button": _vm.modelCloseButtonClick
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Art.Nr")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Variante")]),
        _vm._v(" "),
        _c("th", [_vm._v("Preis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Menge")]),
        _vm._v(" "),
        _c("th"),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }