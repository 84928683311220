var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("statistics-commission", {
        attrs: {
          commission: _vm.commission,
          chartheader: _vm.monthHeader,
          "data-set-commission": _vm.myArray
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [_c("div", { staticClass: "col-12" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }