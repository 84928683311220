<template>
    <section>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">Übersicht</div>
                    <div class="card-body">

                        <div class="form-row">
                            <template v-if="showDropDown">
                                <div class="col-12 col-md-3">
                                    <div class="form-group">
                                        <label>Stylistin</label>
                                        <select class="form-control" v-model="filter.stylist">
                                            <option v-for="item in stylist" :value="item.sw_user_id" :key="item.id">{{ item.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </template>

                            <div class="col-12 col-md-3">

                                <div class="form-group">
                                    <label>Datensatz</label>
                                    <select class="form-control" v-model="filter.transferred">
                                        <option v-for="item in transferred" :value="item.id" :key="item.id">{{ item.name }}</option>
                                    </select>
                                </div>

                            </div>

                            <div class="col-12 col-md-2">

                                <div class="form-group">
                                    <label>Jahr</label>
                                    <select class="form-control" v-model="filter.year">
                                        <option v-for="year in yearData" :value="year.name" :key="year.name">{{ year.name }}</option>
                                    </select>
                                </div>

                            </div>

                            <div class="col-12 col-md-2 text-right">
                                <div class="form-group">
                                    <div>&nbsp;</div>
                                    <button @click="getCBData" class="btn btn-light">Filtern</button>
                                </div>

                            </div>
                        </div>

                        <table class="table">
                            <thead>
                            <tr>
                                <th>Zeitraum</th>
                                <th>Stylistin</th>
                                <th>Provision Vormonat</th>
                                <th>Provision aktuell</th>
                                <th>Differenz</th>
                                <th>Auszahlung</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in commissionData" :key="item.id">
                                <td>{{ item.start_date|formatDate }} - {{ item.end_date|formatDate }}</td>
                                <td>{{ item.s_users.firstname }} {{ item.s_users.lastname }}</td>
                                <td>{{ item.last_month|toCurrency }}</td>
                                <td>{{ item.commission_sum|toCurrency }}</td>
                                <td>{{ item.diff|toCurrency }}</td>
                                <td>{{ item.pay_out|toCurrency }}
                                </td>
                                <td>
                                    <template v-if="showDropDown">
                                        <button v-if="!item.transferred" class="btn btn-sm btn-light" @click="transferBtn(item.id)">Verbuchen</button>
                                    </template>
                                    <template v-if="item.transferred">
                                        Ausbezahlt am:<br>{{ item.transferred_date|formatDate }}
                                    </template>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="card-footer"></div>
                </div>
            </div>
        </div>


    </section>
</template>

<script>
export default {
    name : 'commission-list',
    props: {
        // commissionData: {},
        stylistData: {},
    },
    mounted()
    {
        this.stylist = this.stylistData

        if (this.stylist.length !== 1) {
            this.stylist.unshift({
                                     sw_user_id: -1,
                                     name      : 'Alle'
                                 })
        } else {
            this.filter.stylist = this.stylist[0].sw_user_id
            this.showDropDown   = false

        }
        this.getYear()
        this.getCBData()

    },
    data()
    {
        return {
            commissionData: {},
            showDropDown  : true,
            filter        : {
                stylist    : -1,
                year       : new Date().getFullYear(),
                transferred: -1
            },
            stylist       : {},
            transferred   : [
                {
                    id  : -1,
                    name: 'Alle'
                },
                {
                    id  : 1,
                    name: 'Überwiesen'
                },
                {
                    id  : 2,
                    name: 'Offen'
                },
            ],
            yearData      : []
        }
    },
    methods: {
        getCBData()
        {
            let _self = this
            axios
                .post('/backend/commissionBilling/getData', this.filter)
                .then((response) => {
                    _self.commissionData = response.data.data
                })
                .catch((error) => {
                    console.log('error')
                })
        },
        transferBtn(id)
        {
            this.$vToastify.setSettings({
                                            backdrop    : 'rgba(0, 0, 0, 0.2)',
                                            withBackdrop: true,
                                            position    : 'center-center',
                                        })
            this.$vToastify.prompt({
                                       body   : "Provision auszahlen",
                                       mode   : "prompt",
                                       answers: {
                                           Ja  : true,
                                           Nein: false
                                       }
                                   }).then(value => {
                if (value) {
                    axios
                        .post('/backend/commissionBilling/transfer', {
                            id: id
                        })
                        .then((response) => {
                            this.getCBData()
                        })
                        .catch((error) => {
                            console.log('error')
                        })
                }
            })
        },
        getYear()
        {
            let tmpArray = []
            for (let i = 0; i <= 5; i++) {
                tmpArray.push({name: new Date().getFullYear() - i})
            }
            this.yearData = tmpArray

        }
    }
}
</script>