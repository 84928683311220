<template>
    <div class="border rounded shadow" id="search_div" :class="{show: visible, hidden: !visible}" style="position: absolute; top: 100%; left: 0px; background-color: #fff; width: 100%; z-index: 999">
        <table class="table table-hover search-table-result" id="search_table">
            <tr>
                <th>UserID</th>
                <th>Name</th>
                <th>E-Mail</th>
            </tr>
            <tr v-if="columnData" v-for="row in filteredUser" :key="row.id" :id="row.id" @click="trSelect(row)" class="selectable-row">
                <td>{{row.id}}</td>
                <td>{{row.name}}</td>
                <td>{{row.email}}</td>
            </tr>
            <tr v-else>
                <td colspan="3" class="selectable-row">
                    <div class="text-center">Benutzer nicht gefunden</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
	export default {
		name   : "SearchTableUser",
		props  : {
			columnData: {},
			visible: false,
			searchString: "",
		},
		methods: {
			trSelect(row) {
                console.log("emit", row)
                const res = Object.assign({}, row)
                // this.searchString = "";
				this.$emit('tr-select', res);
			},
            randomEvent(evt) {
                console.log("test", evt)
            }
		},
        computed: {
            filteredUser() {
                const str = this.searchString;
                if (!this.columnData) return [];
                return this.columnData.filter((element) => element.name.toLowerCase().includes(str.toLowerCase()))
            }
        }
	};
</script>

<style scoped>

</style>
