import { render, staticRenderFns } from "./NewsCreate.vue?vue&type=template&id=19016d70&"
import script from "./NewsCreate.vue?vue&type=script&lang=js&"
export * from "./NewsCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jens/Documents/development/style_boss/laravel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19016d70')) {
      api.createRecord('19016d70', component.options)
    } else {
      api.reload('19016d70', component.options)
    }
    module.hot.accept("./NewsCreate.vue?vue&type=template&id=19016d70&", function () {
      api.rerender('19016d70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/News/NewsCreate.vue"
export default component.exports