<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!--                    <h1>test: {{bla(5)}}</h1>-->

                    <div class="card">
                        <div class="card-header">Umsätze</div>
                        <div class="card-body">

                            <div class="form-row">
                                <div class="col-12 col-md-3">
                                    <year-drop-down :drop-down-label="'Jahr'" @dd-year-changed="yearChanged"></year-drop-down>
                                </div>
                            </div>

                            <div>
                                <bar-chart v-if="loaded" :chartData="chartData" :options="chartOptions" style="max-height: 300px"></bar-chart>
                            </div>

                            <div style="margin: 60px 0"></div>

                            <table class="table">
                                <thead>
                                <tr>
                                    <td>Stylistin</td>
                                    <td v-for="month in chartData.labels">{{ month }}</td>
                                    <td class="font-weight-bold">Summe</td>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item, index) in salesData">
                                    <tr>
                                        <td>{{ getStylistName(index) }}</td>
                                        <td v-for="(count, countIndex) in item.data" :class="(countIndex === '13' )? 'font-weight-bold': ''">{{ count.sales|toCurrency }}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer"></div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BarCharts from "./Charts/BarCharts";

export default {
    props: {
        users                 : {
            type   : Array,
            default: []
        },
        currentBackendUserSwId: {
            type   : Number,
            default: 5
        },
        // salesData             : {}
    },
    data()
    {
        return {
            sw_user_id  : this.currentBackendUserSwId,
            loaded      : false,
            chartHeader : {},
            commission  : {},
            dataArray   : [],
            chartData   : {
                labels  : [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember',
                ],
                datasets: [
                    {
                        label          : 'Umsätze',
                        backgroundColor: '#ff0000',
                        data           : this.dataArray
                    },
                ]
            },
            chartOptions: {
                responsive         : true,
                maintainAspectRatio: false,
                scales             : {

                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                },
                animation          : {
                    duration: 0
                }
            },
            salesData   : {},
            selectedYear: null
        }
    },
    mounted()
    {
        this.getData()
        // let tempArray    = []
        // let currentSales = this.salesData[this.currentBackendUserSwId].data
        // for (let item in currentSales) {
        //     tempArray.push(currentSales[item].sales.toFixed(2))
        // }
        // this.chartData.datasets[0].data = tempArray
        // this.chartData                  = Object.assign({}, this.chartData)
        // this.loaded                     = true
    },
    methods: {
        getStylistName(id)
        {
            let user = this.users.filter(function (stylist) {
                return stylist.sw_user_id == id
            });
            return user[0].name
        },
        getData()
        {
            let _self = this
            axios
                .post('/backend/statistics/getStatisticsSalesAxios', {
                    year: this.selectedYear
                })
                .then((response) => {
                    console.log('response')
                    _self.salesData = response.data.customerData
                    let tempArray    = []
                    let currentSales = this.salesData[this.currentBackendUserSwId].data
                    for (let item in currentSales) {
                        tempArray.push(currentSales[item].sales.toFixed(2))
                    }
                    this.chartData.datasets[0].data = tempArray
                    this.chartData                  = Object.assign({}, this.chartData)
                    this.loaded                     = true
                })
                .catch((error) => {
                    console.log('error')
                })
        },
        yearChanged(year)
        {
            this.selectedYear = year
        },
    },
    watch  : {
        'selectedYear': function (nv, ov) {
            this.getData()
        }
    }

}
</script>
<style scoped lang="sass">
canvas
    max-height: 400px
</style>