<template>
    <section id="create-user">
        <div class="container" tabindex="0" @keydown.esc="() => {
    showOwnerResult = false
    showMemberResult = false
}">
            <div class="row justify-content-md-center">
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="card-header">
                            &nbsp;
                        </div>
                        <div class="card-body">
<!--                            <div class="form-header-text">Step 1</div>-->
                            <div class="form-row">
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label>Team Name</label>
                                    <input class="form-control" type="text" v-model="teamcp.teamName">
<!--                                    <div v-if="validateErrors && validateErrors.name" class="invalid-feedback">{{ validateErrors.name[0] }}</div>-->
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label>Team Manager</label>
                                    <input type="text" class="form-control" :disabled="disableOwnerInput" v-model="teamcp.owner.name" @focus="searchOwner" @blur="blurOwner" @keydown.esc="() => showOwnerResult = false">
                                    <a v-if="disableOwnerInput" @click="resetOwnerInput" style="position: absolute; bottom: 6px; right: 15px; cursor: pointer"><i class="fas fa-times-circle"></i></a>
<!--                                    <input type="text" class="form-control" v-model="teamcp.owner.name" @focus="searchOwner">-->
                                    <!--                                    <small class="form-text text-muted">{{__('settings.users.create.search_host.small')}}</small>-->
                                    <!--                                    <div v-if="validateErrors && validateErrors.sw_user_id" class="invalid-feedback">{{ validateErrors.sw_user_id[0] }}</div>-->
                                    <search-table-user @tr-select="selectOwner" :columnData="allUsers" :visible="showOwnerResult" :searchString="teamcp.owner.name"></search-table-user>
                                </div>

                            </div>
                            <div class="form-row">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>E-Mail</td>
                                        <td></td>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr v-for="member in team.member">
                                        <td>{{member.name}}</td>
                                        <td>{{member.email}}</td>
                                        <td>
                                            <a @click="removeMember(member.id)" style="cursor: pointer"><i class="fas fa-times-circle" style="color: #000"></i></a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="form-group col-12">
                                    <label>Mitglied hinzufügen</label>
                                    <input type="text" class="form-control" v-model="membername" @focus="searchMember" @blur="blurMember" @keydown.esc="() => showMemberResult = false">
                                    <!--                                    <input type="text" class="form-control" v-model="teamcp.owner.name" @focus="searchOwner">-->
                                    <!--                                    <small class="form-text text-muted">{{__('settings.users.create.search_host.small')}}</small>-->
                                    <!--                                    <div v-if="validateErrors && validateErrors.sw_user_id" class="invalid-feedback">{{ validateErrors.sw_user_id[0] }}</div>-->
                                    <search-table-user @tr-select="selectMember" :columnData="usersExceptTeam" :visible="showMemberResult" :searchString="membername"></search-table-user>
                                </div>

                            </div>



                        </div>
                        <div class="card-footer text-right">
<!--                            <button class="btn btn-light"><i class="fas fa-user-plus"></i>Mitglied hinzufügen</button>-->
                            <button class="btn btn-light" @click="deleteTeam">Löschen</button>
                            <button class="btn btn-light" @click="saveTeam">Speichern</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name : "TeamDetails",
    // props: ['team'],
    props: {
        team: {
            default: () => {
                return {
                    teamName: "",
                    member: [],
                    owner: {
                        id: null,
                        name: "",
                        email: "",
                    },
                }
            },
            type: Object,
        },
        users: {
            type: Array
        },
    },
    mounted() {
        console.log("this.team");
        console.log(this.team);
        // console.log(this.owner);
        this.teamcp = this.team
        // this.teamcp.owner = this.owner
        if (this.teamcp.owner.name !== "" && this.teamcp.owner.id) {
            this.disableOwnerInput = true
        }
    },
    created() {
        this.allUser()

    },
    data() {
        let teamcopy = this.team;
        // teamcopy.owner = this.owner;
        return {
            teamcp: teamcopy,
            allUsers: this.users,
            showOwnerResult: false,
            disableOwnerInput: false,
            showMemberResult: false,
            disableMemberInput: false,
            membername: "",
        };
    },
    methods: {
        allUser() {
            // let _self = this;
            // axios.get('/backend/userAllAxios')
            //     .then((response) => {
            //         _self.allUsers = response.data;
            //     })
            //     .catch((error) => {
            //         console.log('error: ', error);
            //     });
        },
        searchOwner() {
            this.showOwnerResult = true;
        },
        blurOwner() {
            // setTimeout(() => this.showOwnerResult = false, 100)
        },
        resetOwnerInput() {
            this.teamcp.owner.name = "";
            this.teamcp.owner.email = "";
            this.teamcp.owner.id = null;
            console.log("reset")
            this.disableOwnerInput = false;
        },
        searchMember() {
            this.showMemberResult = true;
        },
        blurMember() {
            // setTimeout(() => this.showMemberResult = false, 100)
        },
        selectOwner(user)
        {
            this.showOwnerResult = false;
            this.disableOwnerInput = true;
            this.teamcp.owner = user;
        },
        selectMember(user)
        {
            this.showMemberResult = false;
            this.teamcp.member.push(user);
            this.membername = "";
        },
        removeMember(id) {
            this.teamcp.member = this.teamcp.member.filter(user => {
                return user.id !== id
            })
        },
        saveTeam() {
            let _self = this;
            console.log('before save')
            axios.post('/backend/teamSaveAxios', _self.teamcp)
                .then(result => {
                    console.log(result)
                    _self.showToast('Gespeichert', '', 'success');
                })
                .catch(({ response }) => {
                    console.log("ERROR")
                    console.log(response.data)
                    _self.showToast('Fehler', `${ response.data.message ? response.data.message : response.data }`, 'error');
            });
        },
        deleteTeam() {
            let _self = this;
            console.log('before delete')
            axios.delete('/backend/teamDisableAxios/'+_self.teamcp.id)
                .then(result => {
                    console.log(result)
                    _self.showToast('Team deaktiviert', '', 'success');
                })
                .catch(({ response }) => {
                    console.log("ERROR")
                    console.log(response.data)
                    _self.showToast('Fehler', `${ response.data.message ? response.data.message : response.data }`, 'error');
                });
        },
        showToast(title, body, type)
        {
            if (type === 'success') {
                this.$vToastify.success({
                    title   : title,
                    body    : body,
                    callback: () => {
                        window.location.href = '/backend/teams';
                    }
                });
            }

            if (type === 'error') {
                this.$vToastify.error({
                    title: title,
                    body : body,
                    callback: () => {
                        if (window.location.href === '/backend/teams/new') return;
                        window.location.reload(true);
                    }
                });
            }
        },

    },
    computed: {
        usersExceptTeam() {
            let _self = this;
            if (!this.allUsers) return []
            return this.allUsers.filter(user => {
                if (user.id === _self.teamcp.owner.id) return false;
                for (const member of _self.teamcp.member) {
                    if (member.id === user.id) return false;
                }
                return true;
            })
        }
    }
};
</script>

<style scoped>

</style>
