var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "calendar" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.__("calendar.root")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body", staticStyle: { height: "67vh" } },
              [
                _c("calendar-view", {
                  staticClass: "theme-default",
                  attrs: {
                    events: _vm.items,
                    "show-date": _vm.showDate,
                    "show-times": _vm.showTimes,
                    "enable-date-selection": true,
                    startingDayOfWeek: 1
                  },
                  on: {
                    "click-event": _vm.onClickItem,
                    "click-date": _vm.onClickItem
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function(t) {
                        return _c("calendar-view-header", {
                          attrs: { "header-props": t.headerProps },
                          on: { input: _vm.setShowDate }
                        })
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer" }, [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.eventTypes, function(event) {
                    return [
                      _c("span", { staticClass: "mr-2" }, [
                        _c("i", {
                          staticClass: "far fa-circle mr-1",
                          style: "color:" + event.color
                        }),
                        _vm._v(_vm._s(event.name))
                      ])
                    ]
                  })
                ],
                2
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [_vm._v(" ")]),
            _vm._v(" "),
            typeof _vm.event.originalEvent != "undefined"
              ? _c("div", [
                  _vm.event.originalEvent.fullData.event.event_type == 99
                    ? _c("div", [
                        _c("div", { staticClass: "card-body" }, [
                          _vm._v(
                            "\n\n                                Schmuckparty\n                                "
                          ),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.party
                                      .party_name
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.party_name")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      _vm.event.originalEvent.fullData.event
                                        .start_datetime
                                    )
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.start_datetime")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.host
                                      .firstname
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.host
                                      .lastname
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.billing
                                      .street
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.billing
                                      .zipcode
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.billing
                                      .city
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.host")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.billing
                                      .phone
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.phone")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.host.email
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.email")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.party
                                      .party_place
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.party
                                      .street
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.party
                                      .zipcode
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.party.city
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.party_place")))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-footer text-right" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-light",
                              on: { click: _vm.deleteEvent }
                            },
                            [
                              _c("i", { staticClass: "far fa-trash-alt" }),
                              _vm._v(_vm._s(_vm.__("calendar.buttons.delete")))
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-light",
                              attrs: {
                                href:
                                  "/backend/party/edit/" +
                                  _vm.event.originalEvent.fullData.party.id
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-edit" }),
                              _vm._v(_vm._s(_vm.__("calendar.buttons.edit")))
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.event.originalEvent.fullData.event.event_type != 99
                    ? _c("div", [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.getEventTypeDetails(
                                      _vm.event.originalEvent.fullData.event
                                        .event_type
                                    ).name
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.event_type")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      _vm.event.originalEvent.fullData.event
                                        .start_datetime
                                    )
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.start_datetime")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.event
                                      .description
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.description")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.event.originalEvent.fullData.event.note
                                  ) +
                                  "\n                                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.__("calendar.note")))
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.event.originalEvent.fullData.event.s_user_id !=
                          null
                            ? _c("div", [
                                _c("div", { staticClass: "mt-3" }, [
                                  _c("div", [
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(
                                          _vm.event.originalEvent.fullData
                                            .billing.firstname
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.event.originalEvent.fullData
                                            .billing.lastname
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(
                                          _vm.event.originalEvent.fullData
                                            .billing.street
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(
                                          _vm.event.originalEvent.fullData
                                            .billing.zipcode
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.event.originalEvent.fullData
                                            .billing.city
                                        ) +
                                        "\n                                        "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.__("calendar.user_data")))
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "mt-3" }, [
                                  _c("div", [
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(
                                          _vm.event.originalEvent.fullData
                                            .billing.phone
                                        ) +
                                        "\n                                        "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.__("calendar.phone")))
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-footer text-right" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-light",
                              on: { click: _vm.deleteEvent }
                            },
                            [
                              _c("i", { staticClass: "far fa-trash-alt" }),
                              _vm._v(_vm._s(_vm.__("calendar.buttons.delete")))
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-light",
                              attrs: {
                                href:
                                  "/backend/events/edit/" +
                                  _vm.event.originalEvent.id
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-edit" }),
                              _vm._v(_vm._s(_vm.__("calendar.buttons.edit")))
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              : _c("div", [
                  _c("div", { staticClass: "card-body" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.__("calendar.no_events")) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-footer" })
                ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mr-2" }, [
      _c("i", {
        staticClass: "far fa-circle mr-1",
        staticStyle: { color: "#f00" }
      }),
      _vm._v("Erinnerung")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }