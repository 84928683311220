<template>
    <section>
        <div class="container">
            <div class="large-12 medium-12 small-12 cell">
                <div class="form-group">
                    <label></label>
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                    <button class="btn btn-light" v-on:click="submitFile()">Upload</button>
                </div>

            </div>
        </div>
        <div v-if="upload" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-color: #000; opacity: 0.5"></div>
    </section>
</template>

<script>
export default {

    data()
    {
        return {
            file  : '',
            upload: false,
        }
    },
    methods: {
        submitFile()
        {

            this.$emit('start-pdf-upload')
            let _self    = this
            let formData = new FormData();
            formData.append('file', this.file);

            this.upload = true
            axios.post('/backend/news/pdf-upload',
                       formData,
                       {
                           headers: {
                               'Content-Type': 'multipart/form-data'
                           }
                       }
            )
                .then((response) => {

                    let resObject = {
                        status: 200,
                        pdf_url:response.data.fileUrl
                    }
                    console.log('response: ' + response.data.fileUrl)
                    _self.$emit('pdf-upload', resObject)
                })
                .catch((error) => {
                    console.log('FAILURE!!')
                    _self.$emit('pdf-upload', error.data.message)
                })
                .then(() => {
                    _self.upload = false
                })
        },
        handleFileUpload()
        {
            this.file = this.$refs.file.files[0];
        }
    }
}
</script>