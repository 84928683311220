import { render, staticRenderFns } from "./EventCreate.vue?vue&type=template&id=31d5f46a&scoped=true&"
import script from "./EventCreate.vue?vue&type=script&lang=js&"
export * from "./EventCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d5f46a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jens/Documents/development/style_boss/laravel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31d5f46a')) {
      api.createRecord('31d5f46a', component.options)
    } else {
      api.reload('31d5f46a', component.options)
    }
    module.hot.accept("./EventCreate.vue?vue&type=template&id=31d5f46a&scoped=true&", function () {
      api.rerender('31d5f46a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Events/EventCreate.vue"
export default component.exports