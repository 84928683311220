var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "border rounded shadow",
      class: { show: _vm.visible, hidden: !_vm.visible },
      staticStyle: {
        position: "absolute",
        top: "100%",
        left: "0px",
        "background-color": "#fff",
        width: "100%",
        "z-index": "999"
      },
      attrs: { id: "search_div" }
    },
    [
      _c(
        "table",
        {
          staticClass: "table table-hover search-table-result",
          attrs: { id: "search_table" }
        },
        [
          _c(
            "tr",
            _vm._l(_vm.headerFields, function(headerField) {
              return _c("th", [_vm._v(_vm._s(headerField))])
            }),
            0
          ),
          _vm._v(" "),
          _vm._l(_vm.columnData, function(row) {
            return _vm.columnData
              ? _c(
                  "tr",
                  {
                    key: row.id,
                    staticClass: "selectable-row",
                    attrs: { id: row.id },
                    on: {
                      click: function($event) {
                        return _vm.trSelect(row)
                      }
                    }
                  },
                  [
                    _c("td", [_vm._v(_vm._s(row.customernumber))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(row.firstname) + " " + _vm._s(row.lastname))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(row.s_user_default_billing_addresses.city))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(row.email))])
                  ]
                )
              : _c("tr", [_vm._m(0)])
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      { staticClass: "selectable-row", attrs: { colspan: "3" } },
      [
        _c("div", { staticClass: "text-center" }, [
          _vm._v("Benutzer nicht gefunden")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }