<template>
    <div class="row">
        <div class="col-6">
            <div class="form-row">
                <div class="form-group col-12">
                    <label>{{ __('settings.image-upload-label') }}</label>
                    <input class="form-control-file" type="file" @change="selectFile">
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="">
                <img v-if="avatar!== null" class="avatar img-fluid img-circle" :src="avatar" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name   : "ImageUpload",
    props  : ['avatar'],
    data()
    {
        return {
            avatar: null,
        };
    },
    methods: {
        selectFile(event)
        {
            // this.avatar = event.target.files[0];
            let avatar = event.target.files[0];

            let reader = new FileReader();
            if (avatar['size'] < 2111775) {
                reader.onloadend = (avatar) => {
                    this.avatar = reader.result;
                    this.$emit('image-file-selected', this.avatar);
                };
                reader.readAsDataURL(avatar);
            } else {
                alert('Das Bild darf nicht größer sein als 2MB');
            }


        }
    }
};
</script>

<style lang="sass" scoped>


</style>
