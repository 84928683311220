var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(
                "Provisionsübersicht: " +
                  _vm._s(
                    _vm.users.find(function(user) {
                      return user.id === _vm.userId
                    })
                      ? _vm.users.find(function(user) {
                          return user.id === _vm.userId
                        }).name
                      : ""
                  ) +
                  " (" +
                  _vm._s(_vm.selectedYear) +
                  ")"
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                !_vm.loading
                  ? [
                      _c("div", { staticClass: "form-row" }, [
                        _vm.is("admin")
                          ? _c("div", { staticClass: "col-12 col-md-3" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Benutzer")]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.userId,
                                        expression: "userId"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.userId = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  _vm._l(_vm.users, function(item) {
                                    return _c(
                                      "option",
                                      { domProps: { value: item.id } },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-3" },
                          [
                            _c("year-drop-down", {
                              attrs: {
                                "drop-down-label": "Jahr",
                                year: _vm.selectedYear
                              },
                              on: { "dd-year-changed": _vm.yearChanged }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.loaded
                            ? _c("bar-chart", {
                                staticStyle: { "max-height": "300px" },
                                attrs: {
                                  chartData: _vm.chartData,
                                  options: _vm.chartOptions
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { margin: "60px 0" } }),
                      _vm._v(" "),
                      _c("table", { staticClass: "table" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.commission, function(item) {
                            return _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.chartData.labels[
                                      new Date(
                                        item.commission_period
                                      ).getMonth()
                                    ]
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(item.party_turnover)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(item.crm_turnover)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(item.online_turnover)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      item.revision_turnover_gold +
                                        item.revision_turnover_silver
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      item.turnover_sum_silver
                                    )
                                  ) +
                                    " / " +
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        item.turnover_sum_gold
                                      )
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(item.turnover_sum)
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(item.team_turnover)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(item.commission_value_silver) +
                                    "% / " +
                                    _vm._s(item.commission_value_gold) +
                                    "% / " +
                                    _vm._s(item.commission_value_team) +
                                    "%"
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(item.commission_sum)
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.transferred_date
                                      ? item.transferred_date
                                      : "–"
                                  )
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.loading ? [_vm._m(1)] : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer" })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Monat")]),
        _vm._v(" "),
        _c("th", [_vm._v("Party")]),
        _vm._v(" "),
        _c("th", [_vm._v("CRM")]),
        _vm._v(" "),
        _c("th", [_vm._v("Online")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nachtrag")]),
        _vm._v(" "),
        _c("th", [_vm._v("Silber/Gold")]),
        _vm._v(" "),
        _c("th", [_vm._v("Umsatz Summe")]),
        _vm._v(" "),
        _c("th", [_vm._v("Team Umsatz")]),
        _vm._v(" "),
        _c("th", [_vm._v("Provisionssätze %")]),
        _vm._v(" "),
        _c("th", [_vm._v("Provision")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ausgezahlt")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c("b", [_vm._v("Daten werden geladen...")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }