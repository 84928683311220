var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projects" },
    [
      _c("div", { staticClass: "tableFilters" }, [
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "form-group col-12 col-md-3" }, [
            _c("label", { attrs: { for: "price_from" } }, [_vm._v("Preis ab")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.tableData.filter.price.from,
                  expression: "tableData.filter.price.from"
                }
              ],
              staticClass: "form-control",
              attrs: { id: "price_from", type: "text" },
              domProps: { value: _vm.tableData.filter.price.from },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.tableData.filter.price,
                    "from",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group col-12 col-md-3" }, [
            _c("label", { attrs: { for: "price_to" } }, [_vm._v("Preis_bis")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.tableData.filter.price.to,
                  expression: "tableData.filter.price.to"
                }
              ],
              staticClass: "form-control",
              attrs: { id: "price_to", type: "text" },
              domProps: { value: _vm.tableData.filter.price.to },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.tableData.filter.price,
                    "to",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.tableData.search,
              expression: "tableData.search"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Search Table" },
          domProps: { value: _vm.tableData.search },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.tableData, "search", $event.target.value)
              },
              _vm.debounceSearch
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-success", on: { click: _vm.getProjects } },
          [_vm._v("click")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "control" }, [
          _c("div", { staticClass: "select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tableData.length,
                    expression: "tableData.length"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.tableData,
                        "length",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function($event) {
                      return _vm.getProjects()
                    }
                  ]
                }
              },
              _vm._l(_vm.perPage, function(records, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: records } },
                  [_vm._v(_vm._s(records))]
                )
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "datatable",
        {
          attrs: {
            columns: _vm.columns,
            sortKey: _vm.sortKey,
            sortOrders: _vm.sortOrders
          },
          on: { sort: _vm.sortBy }
        },
        [
          _c(
            "tbody",
            _vm._l(_vm.projects, function(project) {
              return _c("tr", { key: project.id }, [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checked,
                        expression: "checked"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      value: project.id,
                      checked: Array.isArray(_vm.checked)
                        ? _vm._i(_vm.checked, project.id) > -1
                        : _vm.checked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.checked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = project.id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checked = $$c
                        }
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(project.sku))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(project.name))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm._f("toCurrency")(project.price)))]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function($event) {
                          return _vm.buttonTest(project.id)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-edit" })]
                  )
                ])
              ])
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c("laravel-vue-pagination", {
        attrs: { data: _vm.pagi },
        on: { "pagination-change-page": _vm.getProjects }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }