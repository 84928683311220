import { render, staticRenderFns } from "./PartyOrderDetails.vue?vue&type=template&id=63fb583c&scoped=true&"
import script from "./PartyOrderDetails.vue?vue&type=script&lang=js&"
export * from "./PartyOrderDetails.vue?vue&type=script&lang=js&"
import style0 from "./PartyOrderDetails.vue?vue&type=style&index=0&id=63fb583c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fb583c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jens/Documents/development/style_boss/laravel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63fb583c')) {
      api.createRecord('63fb583c', component.options)
    } else {
      api.reload('63fb583c', component.options)
    }
    module.hot.accept("./PartyOrderDetails.vue?vue&type=template&id=63fb583c&scoped=true&", function () {
      api.rerender('63fb583c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/module/PartyOrderDetails.vue"
export default component.exports