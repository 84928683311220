<template>

    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-6">

                    <div class="card">
                        <div class="card-header">&nbsp;</div>

                        <div class="card-body">

                            <div class="mb-3">
                                <news-image-upload :image="image" @image-file-selected="newsImageChanged"></news-image-upload>
                            </div>

                            <div class="form-group">
                                <input class="form-control" type="text" v-model="headerText">
                                <label>{{ __('news.create.heading') }}</label>
                            </div>

                            <div class="form-group">
                                <textarea class="form-control" cols="30" rows="3" v-model="shortText"></textarea>
                                <label>{{ __('news.create.short_text') }}</label>
                            </div>

                            <div>
                                <vue-editor v-model="content"/>
                            </div>

                            <div class="mt-2 mb-2">
                                <file-upload @start-pdf-upload="fileUpload" @pdf-upload="fileUploadFinish"></file-upload>
                            </div>

                            <div v-if="upload" class="mt-2 mb-2">
                                <div class="alert alert-success text-center">
                                    <a :href="pdf_url" target="_blank">PDF Anzeigen</a>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 d-flex justify-content-end">
                            <span @click="save" class="btn btn-light mr-1 mb-1" v-if="!loading">{{ __('news.buttons.save') }}</span>
                            <button class="btn btn-light mb-1" type="button" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {{ __('news.buttons.saving') }}
                            </button>
                        </div>

                        <!--                        <div class="card-footer text-right">-->
                        <!--                            <button class="btn btn-light">Speichern</button>-->
                        <!--                        </div>-->

                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>


export default {

    // name: 'news-create',
    props  : {
        newsData: {}
    },
    data()
    {
        return {
            image     : null,
            headerText: '',
            shortText : '',
            content   : '',
            loading   : false,
            id        : null,
            pdf_url   : null,
            upload    : false
        }
    },
    mounted()
    {
        let propsEmpty = this.$emptyCheck(this.newsData)
        if (!propsEmpty) {

            this.toDataURL(this.newsData.image_url, (baseImage) => {
                this.image = baseImage
            })

            this.headerText = this.newsData.heading_text
            this.shortText  = this.newsData.short_news
            this.content    = this.newsData.content
            this.id         = this.newsData.id

            if (this.newsData.pdf_url !== null) {
                this.pdf_url = this.newsData.pdf_url
                this.upload  = true
            }
        }

    },
    methods: {
        newsImageChanged(data)
        {
            this.image = data
        },
        toDataURL(url, callback)
        {
            let xhr    = new XMLHttpRequest();
            xhr.onload = function () {
                let reader       = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        },
        save()
        {
            this.loading = true
            let newsData = {
                image       : this.image,
                heading_text: this.headerText,
                short_news  : this.shortText,
                content     : this.content,
                id          : this.id,
                pdf_url     : this.pdf_url
            }

            axios.post('/backend/news/saveAxios', {
                newsData
            })
                .then((response) => {
                    document.location.href = '/backend/news/index'
                })
                .catch((error) => {
                })
                .then(() => {
                    this.loading = false
                })
        },
        fileUpload(data)
        {
            this.upload = false
            console.log('upload...' + data)
        },
        fileUploadFinish(data)
        {

            if (data.status === 200) {
                this.pdf_url = data.pdf_url
                this.upload  = true
                console.log('upload finish...' + data)
            } else {
                this.pdf_url = null
                this.upload  = false
            }

        }

        // save()
        // {
        //     this.loading = true;
        //     axios.post('/api/threads', {
        //         content: this.content
        //     })
        //         .then(response => {
        //             this.loading = false;
        //             console.log('success');
        //         })
        //         .catch(error => {
        //             this.loading = false;
        //             console.log('error');
        //         })
        // }
    }
}
</script>