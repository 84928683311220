var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-6" }, [
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("input", {
            staticClass: "form-control-file",
            attrs: { type: "file" },
            on: { change: _vm.selectFile }
          }),
          _vm._v(" "),
          _c("label", [_vm._v(_vm._s(_vm.__("news.create.news_image")))])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6" }, [
      _c("div", { staticClass: "news" }, [
        _c("div", { staticClass: "news--image-container" }, [
          _vm.newsImage
            ? _c("div", {
                staticClass: "news--image",
                style: { "background-image": "url(" + _vm.newsImage + ")" }
              })
            : _c("div", [
                _c("img", {
                  staticStyle: { width: "100px", height: "auto" },
                  attrs: { src: "/images/no-image.png", alt: "" }
                })
              ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }