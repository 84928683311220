var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c(
      "table",
      {
        staticClass: "table data-table",
        class: _vm.tableHover ? "table-hover" : ""
      },
      [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.columns, function(column) {
              return _c(
                "th",
                {
                  key: column.name,
                  class: column.sortable
                    ? _vm.sortKey === column.name
                      ? _vm.sortOrders[column.name] > 0
                        ? "sorting_asc"
                        : "sorting_desc"
                      : "sorting"
                    : null,
                  style: "width:" + column.width + ";" + "cursor:pointer;",
                  on: {
                    click: function($event) {
                      column.sortable ? _vm.$emit("sort", column.name) : null
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(column.label) +
                      "\n            "
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }